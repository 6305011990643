import { JSV } from "third_party_libraries/jsv/jsv";

const opCodes = {
  Equal: "eq",
  GreaterThanOrEqual: "eg",
  LessThanOrEqual: "el",
  GreaterThan: "gt",
  LessThan: "lt",
  BetweenInclusive: "bt",
  HasValue: "xs",
  HasNoValue: "nx"
};

const defaultNames = {
  eq: v => v,
  eg: v => `${v} or more`,
  el: v => `${v} or less`,
  gt: v => `more than ${v}`,
  lt: v => `less than ${v}`,
  bt: (v, o) => `between ${v} and ${o}`,
  xs: v => "yes",
  nx: v => "no"
};

export function baseMapper(range, transform, names = {}) {
  if (!range) return;

  if (range.startsWith("{")) {
    let { operator, minValue, maxValue } = JSV.parse(range);
    return baseMapperTransform(opCodes[operator], minValue, maxValue, transform, names);
  } else {
    let [op, min, max] = range.split("|");
    return baseMapperTransform(op, min, max, transform, names);
  }
}

function baseMapperTransform(op, min, max, transform, names) {
  names = { ...defaultNames, ...names };

  if (!names[op]) return;

  if (transform) {
    min = transform(min);
    max = transform(max);
  }

  return names[op](min, max);
}

export const dateMapper = range =>
  baseMapper(range, v => CrFormatDate(v), {
    eq: v => v,
    eg: v => `${v} or later`,
    el: v => `${v} or before`,
    gt: v => `later than ${v}`,
    lt: v => `before ${v}`
  });

export const rangeMapper = (range, transform) => baseMapper(range, transform);

export const basicRangeMapper = range => baseMapper(range, x => x);

export const momentMapper = (range, divisor, units) =>
  baseMapper(
    range,
    v => ([[v < 0, `${-v / divisor} ${units} ago`], [v == 0, `now`], [v > 0, `${v / divisor} ${units} from now`]].find(([c]) => c) || [])[1],
    {
      eq: v => v,
      eg: v => `${v} or later`,
      el: v => `${v} or earlier`,
      gt: v => `later than ${v}`,
      lt: v => `earlier than ${v}`
    }
  );

export const lengthMapper = units => range => rangeMapper(range, v => `${v} ${units}`);

export default {
  text: {
    vm: "search",
    ui: "Text Search",
    display: "search",
    displayClasses: () => "far fa-fw fa-search",
    option: ({ name }) => `<i class="far fa-fw fa-search"></i> Run text search of: ${name}`
  },
  textActive: {
    vm: "search",
    ui: "Text Search",
    display: "search",
    displayClasses: () => "far fa-fw fa-search",
    option: ({ name }) => `<i class="far fa-fw fa-search"></i> Run text search of: ${name} <span>Running <i class="fa fa-spin fa-spinner"></i></span>`
  },
  resource: {
    vm: "resourceId",
    ui: "Resource",
    display: "resourceName",
    displayClasses: () => "far fa-fw fa-file",
    option: ({ name }) => `<i class="far fa-fw fa-file"></i> Resource: ${name}`
  },
  bundlelabel: {
    vm: "bundleLabelId",
    ui: "Bundle Label",
    display: "bundleLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Bundle label: ${name}`
  },
  claimLabel: {
    vm: "claimLabelId",
    ui: "Claim Label",
    display: "claimLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Claim label: ${name}`
  },
  billingLabel: {
    vm: "billingLabelId",
    ui: "Billing Label",
    display: "billingLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Billing label: ${name}`
  },
  paymentLabel: {
    vm: "paymentLabelId",
    ui: "Payment Label",
    display: "paymentLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Payment label: ${name}`
  },
  contactlabel: {
    vm: "contactLabelId",
    ui: "Contact Label",
    display: "contactLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Contact label: ${name}`
  },
  timesheetLabel: {
    vm: "timesheetLabelId",
    ui: "Timesheet Label",
    display: "timesheetLabelName",
    displayClasses: () => "fa fa-fw fa-tag",
    option: ({ name }) => `<i class="fa fa-fw fa-tag"></i> Timesheet label: ${name}`
  },
  codelabel: {
    vm: "codeLabelId",
    ui: "Code Label",
    display: "codeLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Code label: ${name}`
  },
  resourcelabel: {
    vm: "resourceLabelId",
    ui: "Resource Label",
    display: "resourceLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Resource label: ${name}`
  },
  messagelabel: {
    vm: "labelId",
    ui: "Message Label",
    display: "messageLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Message label: ${name}`
  },
  tasklabel: {
    vm: "taskLabelId",
    ui: "Task Label",
    display: "taskLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Task label: ${name}`
  },
  noteslabel: {
    vm: "noteLabelId",
    ui: "Note Label",
    display: "noteLabelName",
    displayClasses: () => "far fa-fw fa-tag",
    option: ({ name }) => `<i class="far fa-fw fa-tag"></i> Note label: ${name}`
  },
  invoice: {
    vm: "invoiceId",
    ui: "Invoice",
    display: "invoiceName",
    displayClasses: () => "fa fa-fw fa-dollar-sign",
    option: ({ id, name }) => `<i class="fa fa-fw fa-dollar-sign"></i> Invoice #${id} (Date: ${name})`
  },
  group: {
    vm: "groupId",
    ui: "Group",
    display: "groupName",
    displayClasses: () => "fas fa-fw fa-users",
    option: ({ name }) => `<i class="fas fa-fw fa-users"></i> Group #${name}`
  },
  paymentreference: {
    vm: "paymentReference",
    ui: "Payment Reference",
    display: "paymentReferenceName",
    displayClasses: () => "fa fa-fw fa-link",
    option: ({ name }) => `<i class="fa fa-fw fa-link"></i> Payment reference: ${name}`,
    localMapper: v => v
  },
  principal: {
    vm: "principalId",
    ui: "Principal",
    display: "principalName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Principal: ${name}`,
    mapperClass: "contacts"
  },
  manager: {
    vm: "managerId",
    ui: "Manager",
    display: "managerName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Manager: ${name}`,
    mapperClass: "contacts"
  },
  implementer: {
    vm: "implementerId",
    ui: "Implementer",
    display: "implementerName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Implementer: ${name}`,
    mapperClass: "contacts"
  },
  provider: {
    vm: "providerId",
    ui: "Provider",
    display: "providerName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Provider: ${name}`,
    mapperClass: "contacts"
  },
  client: {
    vm: "clientId",
    ui: "Client",
    display: "clientName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Client: ${name}`,
    mapperClass: "contacts"
  },
  contact: {
    vm: "contactId",
    ui: "Contact",
    display: "contactName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Contact: ${name}`,
    mapperClass: "contacts"
  },
  createdbycontact: {
    vm: "createdByContactId",
    ui: "Created by",
    display: "createdByContactName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Creator: ${name}`,
    mapperClass: "contacts"
  },
  billingentry: {
    vm: "billingEntryId",
    ui: "Billing Entry",
    display: "billingEntryName",
    displayClasses: () => "far fa-fw fa-dollar-sign",
    option: ({ name, id }) => `<i class="far fa-fw fa-dollar-sign"></i> Entry ID: ${id} from ${name}`,
    localMapper: v => v
  },
  feeschedule: {
    vm: "feeScheduleId",
    ui: "Fee Schedule",
    display: "feeScheduleName",
    displayClasses: () => "far fa-fw fa-dollar-sign",
    option: ({ name }) => `<i class="far fa-fw fa-dollar-sign"></i> Fee schedule: ${name}`
  },
  insurance: {
    vm: "insuranceId",
    ui: "Insurance",
    display: "insuranceName",
    displayClasses: () => "fas fa-fw fa-medkit",
    option: ({ name }) => `<i class="fas fa-fw fa-medkit"></i> Insurance: ${name}`,
    mapperClass: "insurances"
  },
  insuranceCompany: {
    vm: "insuranceCompanyId",
    ui: "Insurance Company",
    display: "insuranceCompanyName",
    displayClasses: () => "fas fa-fw fa-medkit",
    option: ({ name }) => `<i class="fas fa-fw fa-medkit"></i> Insurance company: ${name}`,
    mapperClass: "insurances"
  },
  location: {
    vm: "locationId",
    ui: "Location",
    display: "locationName",
    displayClasses: () => "fas fa-fw fa-map-marker-alt",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker-alt"></i> Location: ${name}`
    // mapperClass: "locations"
  },
  sharedPayor: {
    vm: "sharedPayorId",
    ui: "Shared Payor",
    display: "sharedPayorName",
    displayClasses: () => "fas fa-fw fa-medkit",
    option: ({ name }) => `<i class="fas fa-fw fa-medkit"></i> Shared payor: ${name}`,
    mapperClass: "payors"
  },
  creditCard: {
    vm: "creditCardId",
    ui: "Credit Card",
    display: "creditCardName",
    displayClasses: () => "fas fa-fw fa-medkit",
    option: ({ name }) => `<i class="fas fa-fw fa-medkit"></i> Credit card payor: ${name}`,
    mapperClass: "payors"
  },
  serviceCode: {
    vm: "serviceCodeId",
    ui: "Service Code",
    display: "serviceCodesName",
    displayClasses: () => "fas fa-fw fa-wrench",
    option: ({ name }) => `<i class="fas fa-fw fa-wrench"></i> Service code: ${name}`,
    mapperClass: "serviceCodes"
  },
  invoiceType: {
    vm: "invoiceTypeLabel",
    ui: "Invoice",
    display: "invoiceTypeName",
    displayClasses: () => "fas fa-fw fa-filter",
    option: ({ name }) => `<i class="fas fa-fw fa-filter"></i> Invoice type: ${name}`
  },
  claim: {
    vm: "claimId",
    ui: "Claim",
    display: "claimName",
    displayClasses: () => "fas fa-fw fa-medkit",
    option: ({ name, id }) => `<i class="fas fa-fw fa-medkit"></i> Claim ID: ${id} from ${name}`
  },
  authorization: {
    vm: "authorizationId",
    ui: "Auth Code",
    display: "authorizationName",
    displayClasses: () => "",
    option: ({ name }) => `Auth code: ${name}`,
    localMapper: value => value
  },
  authorized: { vm: "authorized", ui: "Authorized", display: "authorized", displayClasses: () => "", option: ({ name }) => `Authorized: ${name}` },
  authorizationSettings: {
    vm: "authorizationSettingsId",
    ui: "Auth Group",
    display: "authorizationSettingsName",
    displayClasses: () => "",
    option: ({ name }) => `Auth group: ${name}`,
    localMapper: value => value
  },
  authorizationResource: {
    vm: "authorizationResourceId",
    ui: "Auth Doc",
    display: "authorizationResourceName",
    displayClasses: () => "",
    option: ({ name }) => `Auth doc: ${name}`,
    localMapper: value => value
  },
  module: {
    vm: "module",
    ui: "Module",
    display: "moduleName",
    displayClasses: () => "fas fa-fw fa-medkit",
    option: ({ name }) => `<i class="fas fa-fw fa-medkit"></i> Module: ${name}`
  },
  shared: {
    vm: "shared",
    ui: "Shared",
    display: "shared",
    displayClasses: () => "fas fa-fw fa-filter",
    option: ({ name }) => `<i class="fas fa-fw fa-filter"></i> Shared: ${name}`
  },

  channel: {
    vm: "channel",
    ui: "Channel",
    display: "channel",
    displayClasses: () => "fas fa-fw fa-filter",
    option: ({ name }) => `<i class="fas fa-fw fa-filter"></i> Channel: ${name}`,
    localMapper: _ => _
  },
  action: {
    vm: "action",
    ui: "Action",
    display: "action",
    displayClasses: () => "fas fa-fw fa-filter",
    option: ({ name }) => `<i class="fas fa-fw fa-filter"></i> Action: ${name}`,
    localMapper: _ => _
  },

  inactiveEmployees: {
    vm: "inactiveEmployees",
    ui: "Include Inactive Employees",
    display: "inactiveEmployees",
    displayClasses: () => "fas fa-fw fa-asterisk"
  },
  excludeInactiveClients: {
    vm: "excludeInactiveClients",
    ui: "Exclude Inactive Clients",
    display: "excludeInactiveClients",
    displayClasses: () => "fas fa-fw fa-asterisk"
  },

  author: {
    vm: "authorId",
    ui: "Author",
    display: "authorName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Author: ${name}`,
    mapperClass: "contacts"
  },

  state: {
    vm: "state",
    ui: "State",
    display: "state",
    displayClasses: () => "fas fa-fw fa-map-marker",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker"></i> State: ${name}`
  },
  city: {
    vm: "city",
    ui: "City",
    display: "city",
    displayClasses: () => "fas fa-fw fa-map-marker",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker"></i> City: ${name}`
  },
  zip: {
    vm: "zip",
    ui: "Zip",
    display: "zipName",
    displayClasses: () => "fas fa-fw fa-map-marker",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker"></i> Zip: ${name}`
  },

  paymentTypeId: {
    vm: "paymentTypeId",
    ui: "Payment Type",
    display: "paymentTypeName",
    displayClasses: () => "fa fa-fw fa-money-bill-alt",
    option: ({ name }) => `<i title="Filter by this payor name" class="fas fa-fw fa-user"></i> Payment type: ${name}`
  },

  signed: {
    vm: "signed",
    ui: "Signed",
    display: "signed",
    displayClasses: () => "fa fa-fw fa-leaf",
    option: () => '<i class="fa fa-fw fa-leaf"></i> Signed timesheets'
  },

  signedProvider: {
    vm: "signedProvider",
    ui: "Signed Provider",
    display: "signedProviderName",
    displayClasses: () => "fa fa-fw fa-leaf",
    option: ({ name }) => `<i class="fa fa-fw fa-leaf"></i> Filter by: ${name}`
  },
  signedClient: {
    vm: "signedClient",
    ui: "Signed Client",
    display: "signedClientName",
    displayClasses: () => "fa fa-fw fa-leaf",
    option: ({ name }) => `<i class="fa fa-fw fa-leaf"></i> Filter by: ${name}`
  },

  assignedTo: {
    vm: "assignedTo",
    ui: "Assigned to",
    display: "assignedToName",
    notFoundDisplay: contactId => `(Contact ID: ${contactId})`,
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Assigned to: ${name}`,
    mapperClass: "contacts"
  },
  deletedBy: {
    vm: "deletedBy",
    ui: "Deleted by",
    display: "deletedByName",
    notFoundDisplay: contactId => `(Contact ID: ${contactId})`,
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Deleted by: ${name}`,
    mapperClass: "contacts"
  },
  createdBy: {
    vm: "createdBy",
    ui: "Created by",
    display: "createdByName",
    notFoundDisplay: contactId => `(Contact ID: ${contactId})`,
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Created by: ${name}`,
    mapperClass: "contacts"
  },
  completedBy: {
    vm: "completedBy",
    ui: "Completed by",
    display: "completedByName",
    notFoundDisplay: contactId => `(Contact ID: ${contactId})`,
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Completed by: ${name}`,
    mapperClass: "contacts"
  },
  onBehalfOf: {
    vm: "onBehalfOf",
    ui: "On Behalf of",
    display: "onBehalfOfName",
    notSetValue: "0",
    notSetDisplay: "None",
    notFoundDisplay: contactId => `(Client ID: ${contactId})`,
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> On Behalf of: ${name}`,
    mapperClass: "contacts"
  },
  appliedBy: {
    vm: "appliedBy",
    ui: "Applied by",
    display: "appliedByName",
    notFoundDisplay: contactId => `(Contact ID: ${contactId})`,
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Applied by: ${name}`,
    mapperClass: "contacts"
  },

  task: {
    vm: "taskId",
    ui: "Task ID",
    display: "taskId",
    displayClasses: () => "fa fa-fw fa-tasks",
    option: ({ name }) => `<i class="fa fa-fw fa-tasks"></i> ${name}`
  },
  tasksFilter: {
    vm: "tasksFilter",
    ui: "Task Filter",
    display: "tasksFilter",
    displayClasses: () => "fa fa-fw fa-tasks",
    option: ({ name }) => `<i class="fa fa-fw fa-tasks"></i> Tasks: ${name}`
  },
  ticketsFromOrg: {
    vm: "ticketsFromOrg",
    ui: "Tickets From",
    display: "ticketsfromorgName",
    displayClasses: () => "fa fa-fw fa-tasks",
    option: ({ name }) => `<i class="fa fa-fw fa-tasks"></i> Tickets From Org: ${name}`,
    mapperClass: "contacts"
  },
  templateIdLocal: {
    vm: "templateIdLocal",
    ui: "Task Template",
    display: "templateName",
    displayClasses: () => "fa fa-fw fa-copy",
    option: ({ name }) => `<i class="fa fa-fw fa-copy"></i> Template: ${name}`
  },
  internalCaseId: {
    vm: "internalCaseId",
    ui: "Internal Case Id",
    display: "internalCaseId",
    displayClasses: () => "fa fa-fw fa-tasks",
    option: ({ name }) => `<i class="fa fa-fw fa-tasks"></i> ${name}`
  },
  billStatus: {
    vm: "billStatus",
    ui: "Billing Status",
    display: "billStatusName",
    displayClasses: () => "fa fa-fw fa-plus-square",
    option: ({ name }) => `<i class="fa fa-fw fa-plus-square"></i> Claim status:${name}`
  },
  voidStatus: {
    vm: "voidStatus",
    ui: "Void Status",
    display: "voidStatusName",
    displayClasses: () => "fa fa-fw fa-plus-square",
    option: ({ name }) => `<i class="fa fa-fw fa-plus-square"></i> Void status:${name}`
  },
  lockStatus: {
    vm: "lockStatus",
    ui: "Lock Status",
    display: "lockStatusName",
    displayClasses: () => "fa fa-fw fa-lock",
    option: ({ name }) => `<i class="fa fa-fw fa-lock"></i> Lock status:${name}`
  },
  copayStatus: {
    vm: "copayStatus",
    ui: "Patient Responsibility Status",
    display: "copayStatusName",
    displayClasses: () => "fas fa-fw fa-user-md",
    option: ({ name }) => `<i class="fas fa-fw fa-user-md"></i> Filter by:${name}`
  },
  isCopay: {
    vm: "isCopay",
    ui: "Patient Responsibility Status",
    display: "isCopay",
    displayClasses: () => "fas fa-fw fa-user-md",
    option: ({ name }) => `<i class="fas fa-fw fa-user-md"></i> Filter by:${name}`
  },
  paymentType: { vm: "paymentType", ui: "Payment Type", display: "paymentTypeDisplay", displayNotMapped: true, displayClasses: () => "" },

  course: {
    vm: "course",
    ui: "Appointment",
    display: "courseName",
    displayClasses: () => "fa fa-fw fa-calendar",
    option: ({ name }) => `<i class="fa fa-fw fa-calendar"></i> Filter by:${name}`
  },
  courseDate: {
    vm: "courseDate",
    ui: "Date",
    display: "courseDateName",
    displayClasses: () => "fa fa-fw fa-calendar",
    option: ({ name }) => `<i class="fa fa-fw fa-calendar"></i> Filter by:${name}`
  },
  courseProvider: {
    vm: "courseProvider",
    ui: "Provider",
    display: "courseProviderName",
    displayClasses: () => "fa fa-fw fa-calendar",
    option: ({ name }) => `<i class="fa fa-fw fa-calendar"></i> Filter by:${name}`,
    mapperClass: "contacts"
  },

  lastBilledDate: { vm: "lastBilled", ui: "Last Billed", display: "lastBilledName", displayClasses: () => "far fa-fw fa-calendar-alt" },
  lastPaidDate: { vm: "lastPaid", ui: "Last Paid", display: "lastPaidName", displayClasses: () => "far fa-fw fa-calendar-alt" },

  resourceCount: { vm: "resourceCount", ui: "Files Attached", display: "resourceCountName", displayClasses: () => "fa fa-fw fa-file" },
  email: {
    vm: "email",
    ui: "Email",
    display: "email",
    displayClasses: () => "fa fa-fw fa-envelope-o",
    option: ({ name }) => `<i class="fa fa-fw fa-envelope-o"></i> ${name}`
  },
  filter: { vm: "filter", ui: "Filter", display: "filterName", displayClasses: () => "fas fa-fw fa-filter" },
  fileType: { vm: "fileType", ui: "File Type", display: "fileTypeName", displayClasses: () => "far fa-fw fa-copy" },

  sentBy: {
    vm: "sentBy",
    ui: "Sent by",
    display: "sentByName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Sent by: ${name}`
  },
  sentTo: {
    vm: "sentTo",
    ui: "Sent to",
    display: "sentToName",
    displayClasses: () => "fas fa-fw fa-user",
    option: ({ name }) => `<i class="fas fa-fw fa-user"></i> Sent to: ${name}`
  },

  age: {
    vm: "age",
    ui: "Age",
    display: "ageName",
    displayClasses: () => "fa fa-fw fa-child",
    option: ({ name }) => `<i class="fa fa-fw fa-child"></i> Age: ${name}`
  },

  gender: {
    vm: "gender",
    ui: "Gender",
    display: "genderName",
    displayClasses: () => "fa fa-fw fa-heart",
    option: ({ name }) => `<i class="fa fa-fw fa-heart"></i> Gender: ${name}`
  },

  language: {
    vm: "languageId",
    ui: "Language",
    display: "languageName",
    displayClasses: () => "fa fa-fw fa-language",
    option: ({ name }) => `<i class="fa fa-fw fa-language"></i> Speaks: ${name}`
  },
  radius: {
    vm: "radius",
    ui: "Radius",
    display: "radius",
    displayClasses: () => "fa fa-fw fa-arrows-h",
    option: ({ name }) => `<i class="fa fa-fw fa-arrows-h"></i> Radius: ${name}`
  },
  scope: {
    vm: "scopeId",
    ui: "Scope",
    display: "scopeName",
    displayClasses: () => "fa fa-fw fa-spinner",
    option: ({ name }) => `<i class="fa fa-fw fa-spinner"></i> Scope: ${name}`
  },

  includeUnavailable: { vm: "includeUnavailable", ui: "Include Unavailable", display: "includeUnavailable", displayClasses: () => "fa fa-fw fa-ban" },
  frequency: { vm: "frequency", ui: "Frequency", display: "frequency", displayClasses: () => "fas fa-fw fa-filter" },
  typeOpen: { vm: "typeOpen", ui: "Open Auths", display: "typeOpen", displayClasses: () => "fas fa-fw fa-filter" },
  typeClosed: { vm: "typeClosed", ui: "Closed Auths", display: "typeClosed", displayClasses: () => "fas fa-fw fa-filter" },
  typeNone: { vm: "typeNone", ui: "No Auths", display: "typeNone", displayClasses: () => "fas fa-fw fa-filter" },

  expires: { vm: "expires", ui: "Expires", display: "expiresDisplay", displayClasses: () => "fas fa-fw fa-filter" },
  effective: { vm: "effective", ui: "Effective", display: "effectiveDisplay", displayNotMapped: true, displayClasses: () => "fas fa-fw fa-filter" },
  effectiveExpiresPeriod: { vm: "period", display: "period", displayNotMapped: true, displayClasses: () => "" },

  form: {
    vm: "formId",
    ui: "Form",
    display: "formName",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },

  sort: { vm: "sort", ui: "Sort", display: "sortName", displayClasses: () => "", option: ({ name }) => name },

  goal: {
    vm: "id",
    ui: "Goal",
    display: "id",
    displayClasses: () => "far fa-fw fa-bullseye",
    option: ({ name }) => `<i class="far fa-fw fa-bullseye"></i> ${name}`
  },
  goalType: {
    vm: "typeId",
    ui: "Goal Type",
    display: "type",
    displayClasses: () => "fas fa-fw fa-graduation-cap",
    option: ({ name }) => `<i class="fas fa-fw fa-graduation-cap"></i> ${name}`
  },
  goalDomain: {
    vm: "domainId",
    ui: "Domain",
    display: "domain",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  goalRecord: {
    vm: "recordId",
    ui: "Record",
    display: "record",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  goalDiscipline: {
    vm: "disciplineId",
    ui: "Discipline",
    display: "discipline",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  goalStatus: {
    vm: "goalStatus",
    ui: "Goal Status",
    display: "goalStatusName",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  goalResponsibility: {
    vm: "responsibilityId",
    ui: "Responsibility",
    display: "responsibility",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  goalAssessment: {
    vm: "assessmentId",
    ui: "Assessment",
    display: "assessment",
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  checknumber: {
    vm: "checknumber",
    ui: "Check Number",
    display: "checknumber",
    displayClasses: () => "fa fa-fw fa-money-bill-alt",
    option: ({ name }) => `<i class="fa fa-fw fa-money-bill-alt"></i> Check: ${name}`
  },
  payment: {
    vm: "paymentId",
    ui: "Payment",
    display: "paymentName",
    displayClasses: () => "fa fa-fw fa-money-bill-alt",
    option: ({ name }) => `<i class="fa fa-fw fa-money-bill-alt"></i> Payment: ${name}`
  },
  dateType: {
    vm: "dateType",
    ui: "Date Type",
    display: "dateTypeDisplay",
    displayNotMapped: true,
    displayClasses: () => "far fa-fw fa-clipboard",
    option: ({ name }) => `<i class="far fa-fw fa-clipboard"></i> ${name}`
  },
  isNew: {
    vm: "isNew",
    ui: "New",
    display: "isNewName",
    displayClasses: () => "",
    option: ({ name }) => name,
    localMapper: value => ({ 1: "Yes" }[value])
  },
  forceDateRange: { vm: "forceDateRange", ui: "Range", display: "forceDateRangeName", displayClasses: () => "", option: ({ name }) => name },

  state: {
    vm: "state",
    ui: "State",
    display: "state",
    displayClasses: () => "fas fa-fw fa-map-marker",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker"></i> State: ${name}`
  },
  city: {
    vm: "city",
    ui: "City",
    display: "city",
    displayClasses: () => "fas fa-fw fa-map-marker",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker"></i> City: ${name}`
  },
  zip: {
    vm: "zip",
    ui: "Zip",
    display: "zipName",
    displayClasses: () => "fas fa-fw fa-map-marker",
    option: ({ name }) => `<i class="fas fa-fw fa-map-marker"></i> Zip: ${name}`
  },

  // these two are in schedule as well
  providerStatus: {
    vm: "providerStatus",
    ui: "Employee Status",
    display: "providerStatusName",
    displayClasses: () => "",
    option: ({ name }) => name,
    localMapper: value => ["Inactive", "Active"][value]
  },
  clientStatus: { vm: "clientStatus", ui: "Client Status", display: "clientStatusName", displayClasses: () => "", option: ({ name }) => name },

  originFrom: { vm: "originFrom", ui: "Origin", display: "originFromName", displayClasses: () => "", option: ({ name }) => name },
  originBy: { vm: "originBy", ui: "Created by", display: "originByName", displayClasses: () => "", option: ({ name }) => name },
  isDuplicate: { vm: "isDuplicate", ui: "Duplicate", display: "isDuplicateName", displayClasses: () => "", option: ({ name }) => name },
  isOverlapping: {
    vm: "isOverlapping",
    ui: "Overlapping",
    display: "isOverlappingName",
    displayClasses: () => "",
    option: ({ name }) => name,
    localMapper: value => ({ 1: "By Provider", 2: "By Appt. with" }[value])
  },
  mismatchScheduleCode: {
    vm: "mismatchScheduleCode",
    ui: "Schedule Code Mismatch",
    display: "mismatchScheduleCodeName",
    displayClasses: () => "",
    option: ({ name }) => name
  },
  mismatchScheduleAuth: {
    vm: "mismatchScheduleAuth",
    ui: "Schedule Auth Mismatch",
    display: "mismatchScheduleAuthName",
    displayClasses: () => "",
    option: ({ name }) => name
  },
  mismatchScheduleDuration: {
    vm: "mismatchScheduleDuration",
    ui: "Schedule Duration Mismatch",
    display: "mismatchScheduleDurationName",
    displayClasses: () => "",
    option: ({ name }) => name
  },
  mismatchScheduleStartTime: {
    vm: "mismatchScheduleStartTime",
    ui: "Schedule Start Time Mismatch",
    display: "mismatchScheduleStartTimeName",
    displayClasses: () => "",
    option: ({ name }) => name
  },

  // these are local for the new resource & resource template module
  signedByAll: { vm: "signedByAll", ui: "Signed by All", display: "signedByAllName", displayClasses: () => "", option: ({ name }) => name },
  unsigned: { vm: "unsigned", ui: "Un-signed", display: "unsigned", displayClasses: () => "", option: ({ name }) => name },
  signedByContact: {
    vm: "signedByContactId",
    ui: "Signed by",
    display: "signedByContactName",
    displayClasses: () => "",
    option: ({ name }) => `Signed by ${name}`,
    mapperClass: "contacts"
  },
  unsignedByContact: {
    vm: "unsignedByContactId",
    ui: "Not Yet Signed by",
    display: "unsignedByContactName",
    displayClasses: () => "",
    option: ({ name }) => `Not yet signed by by ${name}`,
    mapperClass: "contacts"
  },
  messageId: { vm: "messageId", ui: "Message", display: "messageName", displayClasses: () => "", option: ({ name }) => name },
  templateId: {
    vm: "templateId",
    ui: "Template",
    display: "templateName",
    displayClasses: () => "",
    option: ({ name }) => name,
    mapperClass: "resourceTemplates"
  },
  templateGroupId: {
    vm: "templateGroupId",
    ui: "Folder",
    display: "templateGroupName",
    displayClasses: () => "",
    option: ({ name }) => name,
    mapperClass: "resourceTemplateGroups"
  },
  templateTypeId: {
    vm: "templateTypeId",
    ui: "Type",
    display: "templateTypeName",
    displayClasses: () => "",
    option: ({ name }) => name,
    mapperClass: "resourceTemplateTypes"
  },
  fileStatus: { vm: "fileStatus", ui: "Status", display: "fileStatusName", displayClasses: () => "", option: ({ name }) => name },
  contentLength: { vm: "contentLength", ui: "Size", display: "contentLengthName", displayClasses: () => "", option: ({ name }) => name },
  versions: { vm: "versions", ui: "Versions", display: "versionsName", displayClasses: () => "", option: ({ name }) => name },
  creationDate: {
    vm: "creationDate",
    ui: "Added",
    display: "creationDateName",
    displayClasses: () => "",
    option: ({ name }) => name,
    localMapper: dateMapper
  },
  dueDate: {
    vm: "dueDate",
    ui: "Due",
    display: "dueDateName",
    displayClasses: () => "",
    option: ({ name }) => name,
    localMapper: dateMapper
  },
  completedDate: {
    vm: "completedDate",
    ui: "Completed",
    display: "completedDateName",
    displayClasses: () => "",
    option: ({ name }) => name,
    localMapper: dateMapper
  },
  lastUpdateDate: {
    vm: "lastUpdateDate",
    ui: "Last Update",
    display: "lastUpdateDateName",
    option: item => item.name,
    localMapper: dateMapper
  },
  effectiveDate: { vm: "effectiveDate", ui: "Effective", display: "effectiveDateName", displayClasses: () => "", option: ({ name }) => name },
  expirationDate: { vm: "expirationDate", ui: "Expires", display: "expirationDateName", displayClasses: () => "", option: ({ name }) => name },
  originModule: { vm: "originModule", ui: "Added via", display: "originModuleName", displayClasses: () => "", option: ({ name }) => name },
  originAction: { vm: "originAction", ui: "Type", display: "originActionName", displayClasses: () => "", option: ({ name }) => name },
  authStartDate: { vm: "authStartDate", ui: "Auth Start", display: "authStartDateName", displayClasses: () => "", option: ({ name }) => name },
  authEndDate: { vm: "authEndDate", ui: "Auth End", display: "authEndDateName", displayClasses: () => "", option: ({ name }) => name },
  isEarlyConversion: { vm: "isEarlyConversion", ui: "Early Conversion", display: "isEarlyConversionName", displayClasses: () => "", option: ({ name }) => name }
};
// For a text-only search use this
export const defaultTextOnlySearchMap = {
  text: {
    vm: "search",
    ui: "Text Search",
    display: "search",
    displayClasses: () => "far fa-fw fa-search",
    option: ({ name }) => `<i class="far fa-fw fa-search"></i> Run text search of: ${name}`
  }
};
