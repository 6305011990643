import { Icon } from "@cr/react-basics";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Chip, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";

const useStyles = makeStyles(theme => ({
  chipContainer: {
    marginRight: "5px"
  },
  textChip: {
    display: "flex",
    alignItems: "center",
    gridGap: "4px",
    whiteSpace: "normal"
  },
  arrowIcon: {
    fontSize: "12px"
  },
  muiChip: {
    height: "32px",
    background: "#eee",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0B1317",
    marginBottom: theme.spacing(1)
  }
}));

interface ItemProps {
  arrayNamePrefix: string;
  id: number;
  name: string;
  isIncluded: boolean;
  isExclude: boolean;
  displayValue: string;
}
interface ExcludableChipProps {
  item: ItemProps;
  isExclude: boolean;
  displayOverride?: string;
  searchVm: any;
  isMaterialUi?: boolean;
}

export const ExcludableChip = observer((props: ExcludableChipProps) => {
  const { item, isExclude, displayOverride, searchVm, isMaterialUi } = props;
  const classes = useStyles({});

  const [anchorIsExcludeMenu, setAnchorIsExcludeMenu] = React.useState<null | HTMLElement>(null);

  const openIsExludeMenu = event => {
    setAnchorIsExcludeMenu(event.currentTarget);
  };

  const closeIsExcludeMenu = () => {
    setAnchorIsExcludeMenu(null);
  };

  const switchExclude = (item: any) => {
    item.isIncluded ? searchVm.excludeArrayValue(item) : searchVm.includeArrayValue(item);
    closeIsExcludeMenu();
  };

  return (
    <div className={classes.chipContainer}>
      <Chip
        size={isMaterialUi ? "medium" : "small"}
        variant={isMaterialUi ? "default" : "outlined"}
        className={isMaterialUi ? classes.muiChip : ""}
        label={
          <Typography className={classes.textChip}>
            {displayOverride || item.displayValue}
            {isExclude && <Icon icon={Boolean(anchorIsExcludeMenu) ? faChevronUp : faChevronDown} className={classes.arrowIcon} />}
          </Typography>
        }
        {...isExclude && { onClick: openIsExludeMenu }}
        onDelete={() => searchVm.removeArrayValue(item)}
      />
      <Menu
        id="is-exclude-menu"
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorIsExcludeMenu}
        open={Boolean(anchorIsExcludeMenu)}
        onClose={closeIsExcludeMenu}
      >
        <MenuItem onClick={() => switchExclude(item)}>{item.isIncluded ? "Switch to exclude" : "Switch to include"}</MenuItem>
      </Menu>
    </div>
  );
});
