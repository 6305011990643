import VideoPlayer from "./videoPlayer";
import transport from "app/util/transport";

export default class ResourceVideoPlayer extends VideoPlayer {
  resource = null;
  lastTimeSaved = null;
  saveEvery = 15;

  videoProgressed(videoEl) {
    if (videoEl.currentTime - this.lastTimeSaved > this.saveEvery) {
      this.lastTimeSaved = videoEl.currentTime;
      cr.transport.api.post(`resources/${this.resource.id}/latest`, null, { latestTime: ~~videoEl.currentTime });
    }
  }
  setResource(resource, options = {}) {
    this.resource = resource;
    this.lastTimeSaved = resource.latestTimeViewed;
    this.saveEvery = options.saveEvery || 15;
  }
}
