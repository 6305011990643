import React, { Component } from "react";
import { observer } from "mobx-react";
import DropdownButton from "bootstrap-dropdown-button";
import { Chip, makeStyles } from "@material-ui/core";
import { ExcludableChip } from "./excludableChip";
import { SimpleChip } from "./simpleChip";

@observer
class SingleValueTemplateRound extends Component {
  //this style is deprectated. For the newer MUI design use SingleValueTemplateMui by adding isMaterialUi to the searchVm
  render() {
    let {
      searchVm,
      packet: { displayClasses = "", vm, display, ui }
    } = this.props;

    //if they're the same, then there's no separate display property to read anywhere - show the vm property
    let displayValue = vm == display ? searchVm.vmProperties[vm] : searchVm.displayProperties[display];

    return (
      <div className="inline-block">
        <span className="flex no-padding margin-xs-right margin-xs-bottom relative">
          <Chip label={ui + ": " + (displayValue || "loading...")} onDelete={() => searchVm.clearFilterItem(vm)} variant="outlined" size="small" />
        </span>
      </div>
    );
  }
}
@observer
class SingleValueTemplateMui extends Component {
  render() {
    let {
      searchVm,
      packet: { displayClasses = "", vm, display, ui }
    } = this.props;

    //if they're the same, then there's no separate display property to read anywhere - show the vm property
    let displayValue = vm == display ? searchVm.vmProperties[vm] : searchVm.displayProperties[display];

    return (
      <div className="inline-block">
        <span className="flex no-padding margin-xs-right margin-xs-bottom relative">
          <SimpleChip ui={ui} displayValue={displayValue} onDelete={searchVm.clearFilterItem} vm={vm} />
        </span>
      </div>
    );
  }
}

@observer
class SingleValueTemplate extends Component {
  render() {
    let {
      searchVm,
      packet: { displayClasses = "", vm, display, ui, customRender }
    } = this.props;

    //if they're the same, then there's no separate display property to read anywhere - show the vm property
    let displayValue = vm == display ? searchVm.vmProperties[vm] : searchVm.displayProperties[display];

    return (
      <div className="inline-block">
        <span className="label flex no-padding margin-xs-right relative">
          <i className={displayClasses} />
          <em className="filter">{ui + ": " + ((customRender ? customRender(displayValue, searchVm) : displayValue) || "<loading>")}</em>
          <a title="Remove filter" onClick={() => searchVm.clearFilterItem(vm)}>
            x
          </a>
        </span>
      </div>
    );
  }
}

@observer
class ToggleValueTemplate extends Component {
  render() {
    let {
        searchVm,
        packet: { displayClasses = "", vm, display, ui }
      } = this.props,
      isOn = searchVm.vmProperties[vm] > 0;
    return (
      <span>
        <div className="inline-block">
          <DropdownButton className="label flex no-padding margin-xs-right relative">
            <i title={ui} className={displayClasses} />
            <em className="filter" title={ui}>
              {ui + ": " + (isOn ? "" : "Not ") + searchVm.displayProperties[display]}
            </em>
            <a title="Switch include vs. exclude" className="dropdown-toggle">
              <b className="caret no-margin" />
            </a>
            <ul className="dropdown-menu">
              {isOn ? (
                <li>
                  <a onClick={() => searchVm.toggleOffItem(vm)}>Switch to exclude</a>
                </li>
              ) : null}
              {!isOn ? (
                <li>
                  <a onClick={() => searchVm.toggleOnItem(vm)}>Switch to include</a>
                </li>
              ) : null}
            </ul>
            <a className="remove" title="Remove filter" onClick={() => searchVm.clearFilterItem(vm)}>
              x
            </a>
          </DropdownButton>
        </div>
      </span>
    );
  }
}

@observer
class ArrayValueTemplate extends Component {
  render() {
    let {
      searchVm,
      arrayOptions: { excludable = true },
      packet: { displayClasses = "", vm, display, ui },
      labelStores = {},
      isFilterRound,
      isMaterialUi
    } = this.props;

    let labelStore = labelStores[vm];

    // reduce to unique list of items
    let items = searchVm.searchArrayCollections[`${vm}Collection`].reduce((acc, obj) => {
      if (!acc.some(({ id }) => `${id}` === `${obj.id}`)) {
          acc.push(obj);
      }
      return acc;
    }, []);

    return (
      <span>
        {items.map(obj => {
          if (labelStore) {
            let l = labelStore.allLabelsLookup.get(+obj.id);
            if (l) {
              var displayOverride = '"' + l.name + '"';
              if (!obj.isIncluded) {
                displayOverride = "exclude " + displayOverride;
              }
            }
          }
          return (
            <div key={obj.id} className="inline-block">
              {isFilterRound || isMaterialUi ? (
                <ExcludableChip item={obj} isExclude={excludable} displayOverride={displayOverride} searchVm={searchVm} isMaterialUi={isMaterialUi} />
              ) : (
                <DropdownButton className="label flex no-padding margin-xs-right relative">
                  <em className="filter" title={ui}>
                    {displayOverride || obj.displayValue}
                  </em>

                  {excludable && (
                    <a title="Switch include vs. exclude" className="dropdown-toggle">
                      <b className="caret no-margin" />
                    </a>
                  )}
                  {excludable && (
                    <ul className="dropdown-menu">
                      {obj.isIncluded ? (
                        <li>
                          <a onClick={() => searchVm.excludeArrayValue(obj)}>Switch to exclude</a>
                        </li>
                      ) : null}
                      {!obj.isIncluded ? (
                        <li>
                          <a onClick={() => searchVm.includeArrayValue(obj)}>Switch to include</a>
                        </li>
                      ) : null}
                    </ul>
                  )}
                  {/* end-toggle-include-exclude */}
                  <a className="remove" title="Remove filter" onClick={() => searchVm.removeArrayValue(obj)}>
                    x
                  </a>
                </DropdownButton>
              )}
            </div>
          );
        })}
      </span>
    );
  }
}

export default ({ properties, defaults, mapToArrays, suppressFromFilterBar, labelStores = {}, isFilterRound, isMaterialUi }) => {
  const arrayLookup = new Map(mapToArrays.map(item => (typeof item === "string" ? [item, { name: item.name }] : [item.name, item])));

  @observer
  class FilterBar extends Component {
    static validForFilterBarSearchVm(searchVm, key) {
      let vm = properties[key].vm;
      return key != "textActive" && suppressFromFilterBar.indexOf(vm) < 0 && searchVm.isActive(vm);
    }

    static filterBarActive(searchVm) {
      return Object.keys(properties).filter(key => FilterBar.validForFilterBarSearchVm(searchVm, key)).length;
    }

    validForFilterBar = key => FilterBar.validForFilterBarSearchVm(this.props.searchVm, key);

    render() {
      let { searchVm } = this.props,
        validKeys = Object.keys(properties).filter(this.validForFilterBar);

      return (
        <div className="inline-block">
          <div className="search-filters">
            {validKeys.map(packetKey => {
              let packet = properties[packetKey];

              if (packet.isToggle) {
                return <ToggleValueTemplate key={packetKey} searchVm={searchVm} packet={packet} displayClasses={packet.displayClasses || ""} />;
              }

              return arrayLookup.has(packet.vm) ? (
                <ArrayValueTemplate
                  key={packetKey}
                  searchVm={searchVm}
                  packet={packet}
                  arrayOptions={arrayLookup.get(packet.vm)}
                  displayClasses={packet.displayClasses || ""}
                  labelStores={labelStores}
                  isFilterRound={isFilterRound}
                  isMaterialUi={isMaterialUi}
                />
              ) : !isFilterRound && !isMaterialUi ? (
                <SingleValueTemplate key={packetKey} searchVm={searchVm} packet={packet} displayClasses={packet.displayClasses || ""} />
              ) : isFilterRound ? (
                <SingleValueTemplateRound key={packetKey} searchVm={searchVm} packet={packet} displayClasses={packet.displayClasses || ""} />
              ) : (
                <SingleValueTemplateMui key={packetKey} searchVm={searchVm} packet={packet} displayClasses={packet.displayClasses || ""} />
              );
            })}
          </div>
        </div>
      );
    }
  }
  return FilterBar;
};
