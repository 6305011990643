/* tslint:disable */ // TODO: remove this rule flag!
/*

This module default exports a searchVmFactory. This factory produces both a SearchVm class, and a FilterBar component,
instances of which will be linked to an instances of that same SearchVm.

Sample usage:

const { SearchVm: SearchVmBase, FilterBar } = searchVmFactory({
    properties: {
        ...defaultSearchMap,
        filter: { vm: 'filter', ui: '', display: 'filterName', option: item => item.name },
        hasTaskLabels: { vm: 'taskLabel', ui: 'Task label', display: 'taskLabelName', isToggle: true, option: item => item.name }
    },
    defaults: {
        sort: 'created_desc',
        filter: 'open',
        isNew: -1
    },
    pageInfo: {
        pageSize: 50,
        pageSizes: [50, 100, 150, 200, 250, 500, 1000]
    },
    mapToArrays: ['taskLabelId'],
    syncTo: ['tasks', 'tasks/list'],
    nonFilterBarSearchProperties: ['isSupportTicket', 'accessType', 'closeRequests'],
    suppressFromFilterBar: ['filter']
});

API:

properties
    An object representing the options that are capable of coming back in the advancedSearch component / mid tier action.
This is the same exact format as the former huge advancedSearch plugin object. In fact, if you want to use that, you can get it from 'app/util/defaultSearchMap';
as a default export

Each object in this hash will look like

claimLabel: { vm: 'claimLabelId', ui: 'Claim Label', display: 'claimLabelName', displayClasses: () => 'fa fa-fw fa-tag', option: item => '<i class="fa fa-fw fa-tag"></i> Claim label: ' + item.name; } },

Each entry will have these fields:

vm: the searchVm property holding the value
display: the searchVm property holding the display value associated with the value. (For example, assignedTo: 2396 would put 2396 into the vm property, and "Kenneth Penman" in the display value when the proc returns).
option: produces html for use in displaying a search item in the advancedSearch component
displayClasses: was used to produce icon classes for use in the filter bar displays, but this feature is now inactive.

nonFilterBarSearchProperties: if you want to add some properties to your searchVm which will sync with the hash like normal, but not have them ever show up in
the filter bar, just add them here.

suppressFromFilterBar: as the name implies, use this to suppress properties from the `properties` option to never show up in the filterBar. Add the `vm` string to this
array, as in, the `vm` property from the associated `properties` object hash described above.

mapToArrays: use this to turn certain vm properties into collections.  This is usually done with labels, where instead of a single label value, the user can add a bunch
of them.  You can pass a plain string of the vm property name, or an object like this `{name: 'taskLabelId', excludable: false }` if you don't want the items
to show an exclude button.

defaults: self explanatory - if set, those default values will apply if the property in question is now sync'd to a value in the hash

pageInfo: adds page and pageSize proeprties to vmProperties (see below) and syncs to hash.  Value specified for pageSize will be the default value

syncTo: the module/submodule patterns to sync with.  For example, if syncTo: ['tasks', 'tasks/list'] is set, then hash syncing will happen when the user is on
#tasks or #tasks/list, but will shut off when the user is on #tasks/templates.

------------------------------------------------------------------------------------------------------------------------------

Briefly, after being created, instances of this searchVm will have:

 - a vmProperties object with values relating to all search values
 - a displayProperties object with all display values
 - a searchArrayCollections object with all of the multi-value arrays. This will be keyed as [vm + 'Collection'].  For examples
this.searchArrayCollections.taskLabelIdCollection will give the current values for the taskLabel array configured abive.

*/

import React, { Component } from "react";
import { computed, observable, action, autorun, runInAction, untracked, extendObservable, toJS } from "mobx";
import currentHash from "app/util/currentHash";
import hashUtility from "app/util/hashUtility";
import filterBarFactory, { IFilterBarFactory } from "app/components/searchVm/filterBarFactory";
import { mappable } from "app/util/mobx-util";
import { ALL_DATES_VALUES } from "app/util/constants";
import apiTransport from "app/util/apiTransport";
import { JSV } from "third_party_libraries/jsv/jsv";

const api = new apiTransport();

//--------------------handle pre-canned dates----------------------------------------
//dates used for date range syncing
let today,
  yesterday,
  last7,
  last30,
  last45,
  last60,
  last90,
  daysago7,
  daysago14,
  daysago30,
  daysago45,
  daysago60,
  daysago90,
  r60,
  r60Start,
  r90,
  r90Start,
  r120,
  r120Start,
  thisMonthStart,
  thisMonthEnd,
  lastMonthStart,
  lastMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  thisWeekStart,
  thisWeekEnd,
  nextWeekStart,
  nextWeekEnd;

//I could have made these constants, but I'm paranoid, and afraid of a user opening our app at 11:55pm
//and crossing the midnight boundary.
function setDateConstants() {
  today = moment().format("MM-DD-YYYY");
  yesterday = moment().add(-1, "days").format("MM-DD-YYYY");
  last7 = moment().add(-6, "days").format("MM-DD-YYYY");
  last30 = moment().add(-30, "day").format("MM-DD-YYYY");
  last45 = moment().add(-45, "day").format("MM-DD-YYYY");
  last60 = moment().add(-60, "day").format("MM-DD-YYYY");
  last90 = moment().add(-90, "day").format("MM-DD-YYYY");
  daysago7 = moment().add(-6, "day").format("MM-DD-YYYY");
  daysago14 = moment().add(-13, "day").format("MM-DD-YYYY");
  daysago30 = moment().add(-29, "day").format("MM-DD-YYYY");
  daysago45 = moment().add(-44, "day").format("MM-DD-YYYY");
  daysago60 = moment().add(-59, "day").format("MM-DD-YYYY");
  daysago90 = moment().add(-89, "day").format("MM-DD-YYYY");
  r60 = moment().add(-31, "day").format("MM-DD-YYYY");
  r60Start = moment().add(-60, "day").format("MM-DD-YYYY");
  r90 = moment().add(-61, "day").format("MM-DD-YYYY");
  r90Start = moment().add(-90, "day").format("MM-DD-YYYY");
  r120 = moment().add(-91, "day").format("MM-DD-YYYY");
  r120Start = moment().add(-120, "day").format("MM-DD-YYYY");
  thisMonthStart = moment().startOf("month").format("MM-DD-YYYY");
  thisMonthEnd = moment().endOf("month").format("MM-DD-YYYY");
  lastMonthStart = moment().add(-1, "months").startOf("month").format("MM-DD-YYYY");
  lastMonthEnd = moment().add(-1, "months").endOf("month").format("MM-DD-YYYY");
  nextMonthStart = moment().add(1, "months").startOf("month").format("MM-DD-YYYY");
  nextMonthEnd = moment().add(1, "months").endOf("month").format("MM-DD-YYYY");

  thisWeekStart = moment().startOf("week").add(1, "days").format("MM-DD-YYYY");
  thisWeekEnd = moment().endOf("week").add(1, "days").format("MM-DD-YYYY");
  nextWeekStart = moment().add(1, "weeks").startOf("week").add(1, "days").format("MM-DD-YYYY");
  nextWeekEnd = moment().add(1, "weeks").endOf("week").add(1, "days").format("MM-DD-YYYY");
}
//------------------------------------------------------------

function normalizeArrayItemResponse(item) {
  if (typeof item.Name !== "undefined") {
    item.name = item.Name;
    delete item.Name;
  }
  if (typeof item.Id !== "undefined") {
    item.id = item.Id;
    delete item.Id;
  }
  if (typeof item.IsIncluded != "undefined") {
    item.isIncluded = item.IsIncluded;
    delete item.IsIncluded;
  }
  item.isIncluded = !!item.isIncluded;

  if (typeof item.id === "number" && item.id < 0) {
    item.id = item.id * -1;
  }

  if (!item.name) {
    item.error = true;
  }
}

@mappable
export class MultiValueSelection {
  static createFromResponse?: (r: any) => any;
  @observable id = "";
  @observable name = "";
  @observable error = false;
  @observable isIncluded = true;
  @observable formatDisplay?: (value: string) => string;
  @computed
  get displayValue() {
    if (!this.name && !this.error) {
      return "<loading>";
    }
    if (this.error) {
      return `Invalid Filter`;
    }

    if (this.formatDisplay) {
      return `${!this.isIncluded ? `"Exclude"` : ""} ${this.formatDisplay(this.name)}`;
    }

    return `${!this.isIncluded ? "exclude " : ""} "${this.name}"`;
  }
  arrayNamePrefix = ""; //code smell - but it just makes things easier having each value track the array it's in
}

@mappable
class DTO {
  constructor(objBlueprint) {
    extendObservable(this, objBlueprint);
  }
}

export const tasksFilterOptions = {
  overdue: ["Overdue", "overdue", moment().format("MM-DD-YYYY")],
  today: ["Today", "day", moment().format("MM-DD-YYYY")],
  tomorrow: ["Tomorrow", "day", moment().add(1, "days").format("MM-DD-YYYY")],
  thisweek: ["This week", "week", moment().startOf("week").add(1, "days").format("MM-DD-YYYY")],
  nextweek: ["Next week", "week", moment().add(1, "weeks").startOf("week").add(1, "days").format("MM-DD-YYYY")]
};

type displayClassesCallback = () => string;

/** Used to specify metadata about searchable properties within the current submodule view. */
export interface vm {
  /** Property name that will get the value (e.g., an object ID) for a search. */
  vm: string;
  /** Label/caption to display for the search criterion. Displayed, for example, next to the display value in the filter rail. */
  ui?: string;
  /** Property name that will get the display value (e.g., an object name/title/desc, such as contact name) to show in the UI for the current value found via the 'vm' property name. */
  display?: string;
  /** Indicates that the filter field is an on/off toggle for special handling in advanced search. */
  isToggle?: boolean;
  /** Indicates whether or not to try to map the display value. If true, it will use empty string for the display. */
  displayNotMapped?: boolean;
  /** Deprecated. Used to supply icon for filter bar. */
  displayClasses?: string | displayClassesCallback;
  /** Use to bypass mappers when there is a valid not set value for the filter. */
  notSetValue?: string;
  /** Use in conjunction with notSetValue to provide a value to show in cases where current value is same as notSetValue. */
  notSetDisplay?: string;
  /** Function that will return HTML to show as an option to select in an advanced search component. (User types a value, and it shows matches as a drop-down. This is what to show there.)  */
  option?: (value: { name: string; id: string }) => string;
  /** Will ask the provided function for a display value for the given value. */
  localMapper?: (value: any, searchVm?: any) => string;
  /** Similar to 'localMapper', only it evaluates through a server call to a filter map. This is evaluated if 'localMapper' returns falsy. More info: https://github.centralreach.com/CentralReach/api/wiki/Shared-filter-maps */
  remoteMapper?: (value: any, original: any) => string;
  /** This is used in calls to /filter/map along with any applied values to look up display values for that "class" of object. */
  mapperClass?: string;
  /** Used when no matching value is found for remote mapping lookup. Default: "Not Found" */
  notFoundDisplay?: (value: any) => string;
}

export interface vmMap {
  [index: string]: vm;
}

export interface richArrayMapElement {
  name: string;
  excludable?: boolean;
  localMapper?: (any) => string;
  mapperClass?: string;
  formatDisplay?: (value: string) => string;
}

export type arrayMapElement = string | richArrayMapElement;

export interface searchVmOptions {
  properties: vmMap;
  defaults?: any;
  mapToArrays?: arrayMapElement[];
  nonFilterBarSearchProperties?: any[];
  syncTo?: any[];
  pageInfo?: any;
  suppressFromFilterBar?: any[];
  manualDisplay?: any[];
  disconnectFromHash?: boolean;
  labelStores?: any;
  enableSavedFilters?: boolean;
  addStartAndEndDates?: any;
  datesWithPresets?: any[];
  clearPageOnFilterChange?: any;
  isFilterRound?: boolean; //this is deprectated. TODO: should be removed once draftTimesheets and visit verification migrate the styling to the newer MUI design.
  isMaterialUi?: boolean;
}

// tslint:disable-next-line:interface-name
export interface ISearchVm {
  [k: string]: any;
  page?: number | string;
  pageSize?: number | string;
  activeFilterCount: any;
  pageSizes: any;
  defaultPageSize: any;
  searchArrayCollections: any;
  vmProperties: any;
  displayProperties: any;
  defaults: any;
  toDispose: any[];
  onHashUpdated: any;
  startDate: any;
  endDate: any;
  dateRange: any;
  startDateMoment: any;
  endDateMoment: any;
  startDateDisplay: string;
  endDateDisplay: string;
  syncWithHash(parameters: { [key: string]: string }): void;
  getCleanRequestObject(maintainArrays?): any;
  getExportRequestObject(): any;
  mapFilters(): void;
  mapLocalFilters(): void;
  toggleFilterItem(prop: string, item: { id: number | string; name: string }, isInitiallyExcluded?: boolean): void;
  isActive(prop: string): boolean;
  updateHash(hash: { [key: string]: string });
  resetHashToState(hash: { [key: string]: string });
  getRequestObject(maintainArrays?): any;
  setSearchDisplayValues(resp: any): void;
  listenToFilterChanges(cb: (options: { queryParamsAreDirty?: boolean; nonQueryParamsAreDirty?: boolean }) => void, runImmediately?: boolean);
  addStartAndEndDates: boolean;
  datesWithPresets?: any[];
  unresolvedStore?: any;
}

export interface SearchVmFactory {
  new (): ISearchVm;
}

export interface SearchVmSet {
  FilterBar: IFilterBarFactory;
  SearchVm: SearchVmFactory;
}

const searchVmFactory: (options: searchVmOptions) => SearchVmSet = ({
  properties,
  defaults = {},
  mapToArrays = [],
  nonFilterBarSearchProperties = [],
  syncTo = [],
  pageInfo = null,
  suppressFromFilterBar = [],
  manualDisplay = [],
  disconnectFromHash = false,
  labelStores = {},
  enableSavedFilters = true,
  clearPageOnFilterChange = true,
  isFilterRound,
  isMaterialUi,
  ...miscOptions
}: searchVmOptions) => {
  // tslint:disable-line:no-big-function
  const mapToArraysKeys = mapToArrays.map(item => (typeof item === "string" ? item : item.name));

  if (pageInfo) {
    nonFilterBarSearchProperties.push("page");
    defaults.page = 1;
    if (pageInfo.pageSize) {
      nonFilterBarSearchProperties.push("pageSize");
      defaults.pageSize = pageInfo.pageSize;
    }
  }

  if (miscOptions.addStartAndEndDates) {
    nonFilterBarSearchProperties.push("startDate", "endDate");
  }

  // TODO: Fix this lint exception
  // tslint:disable-next-line:no-parameter-reassignment
  properties = Object.assign({}, properties);
  Object.keys(properties).forEach(key => {
    if (typeof properties[key].displayClasses === "function") {
      properties[key].displayClasses = (properties[key].displayClasses as displayClassesCallback)();
    }
  });

  //this variable name isn't really honest until Object.keys is done, below - basically copy over EVERYTHING and remove the few arrays, in a moment ¯\_(ツ)_/¯
  let nonArrayProperties = Object.assign({}, properties),
    arrayProperties = {};

  Object.keys(nonArrayProperties).forEach(key => {
    if (mapToArraysKeys.find(s => s == nonArrayProperties[key].vm)) {
      arrayProperties[key] = nonArrayProperties[key];
      delete nonArrayProperties[key];
    }
  });
  let nonArrayVmProperties = [
    ...new Set(Object.keys(nonArrayProperties).map(prop => nonArrayProperties[prop].vm)).keys(),
    ...nonFilterBarSearchProperties
  ];

  let manualDisplayLookup = new Set(manualDisplay);
  let nonArrayDisplayProperties = [
    ...new Set(
      Object.keys(nonArrayProperties)
        .filter(prop => !manualDisplayLookup.has(nonArrayProperties[prop].display))
        .map(prop => nonArrayProperties[prop].display)
    ).keys()
  ];

  let vmPropertiesDefaultState = nonArrayVmProperties.reduce((hash, name) => ((hash[name] = defaults[name] || ""), hash), {});
  let displayPropertiesDefaultState = nonArrayDisplayProperties.reduce((hash, name) => ((hash[name] = defaults[name] || ""), hash), {});
  let extendSearchVmWithArrays = mapToArraysKeys.reduce((hash, key) => ((hash[key + "Collection"] = []), hash), {});
  let vmToOriginalPropertyLookup = new Map(
    Object.keys(nonArrayProperties).map(prop => [nonArrayProperties[prop].vm, nonArrayProperties[prop]]) as [string, vm][]
  );

  let defaultHashState = Object.keys(vmPropertiesDefaultState).reduce((hash, key) => ((hash[key] = null), hash), {});
  mapToArraysKeys.forEach(prop => {
    defaultHashState[prop + "Included"] = defaultHashState[prop + "Excluded"] = null;
  });

  const routeMatches = syncTo.map(s => {
    let pieces = s.split("/");
    return { module: pieces[0], submodule: pieces[1] || "" };
  });

  const doesMatch = (module, submodule) =>
    routeMatches.some(({ module: targetModule, submodule: targetSubmodule }) => module == targetModule && (submodule || "") == targetSubmodule);

  const nonQueryParameters = ["page", "pageSize", "sort", "sortBy"];
  const nonQueryParametersLookup = new Set(nonQueryParameters);

  class DirtyHelper {
    @observable queryParamsDirty = 0;
    @observable nonQueryParamsDirty = 0;
  }

  @mappable
  abstract class Base implements ISearchVm {
    pageSizes: any;
    defaultPageSize: any;
    searchArrayCollections: any;
    vmProperties: any;
    displayProperties: any;
    defaults: any;
    toDispose: any[];
    onHashUpdated: any;
    addStartAndEndDates: boolean;
    datesWithPresets?: any[];

    constructor() {
      if (pageInfo && pageInfo.pageSizes) {
        this.pageSizes = pageInfo.pageSizes;
        this.defaultPageSize = pageInfo.pageSize;
      }
      this.searchArrayCollections = {};
      extendObservable(this.searchArrayCollections, extendSearchVmWithArrays);

      this.vmProperties = new DTO(vmPropertiesDefaultState);
      this.displayProperties = new DTO(displayPropertiesDefaultState);

      this.toDispose = [];
      this.defaults = defaults;

      this.addStartAndEndDates = miscOptions.addStartAndEndDates;
      this.datesWithPresets = miscOptions.datesWithPresets || [];

      if (routeMatches.length) {
        this.toDispose.push(
          autorun(() => {
            if (doesMatch(currentHash.module, currentHash.submodule)) {
              let parameters = currentHash.parameters;
              untracked(() => this.syncWithHash(parameters));
            }
          })
        );
      }
    }

    @observable enableSavedFilters = enableSavedFilters;
    get page() {
      return +this.vmProperties.page;
    }
    get pageSize() {
      return +this.vmProperties.pageSize;
    }
    @computed
    get canPageDown() {
      return this.page > 1;
    }
    get canPageUp() {
      return false; //abstract (sorta) - implement in base class as needed - covering all use cases is a pain - not worth abstracting it all
    }
    @action
    setPageSize = value => {
      this.updateHash({ pageSize: value == defaults.pageSize ? null : value });
    };
    syncWithHash(hashParameters) {
      runInAction(() => {
        this.nonArrayFiltersActive.forEach(prop => {
          let originalProp = vmToOriginalPropertyLookup.get(prop);

          if (originalProp && !hashParameters[originalProp.vm]) {
            let displayName = originalProp.display;
            if (displayName && typeof this.displayProperties[displayName] !== "undefined") {
              //prolly not needed - just in case
              this.displayProperties[displayName] = "";
            }
          }
        });
        this.vmProperties.mapResponse(Object.assign({}, vmPropertiesDefaultState, hashParameters));
        if (hashParameters["all-dates"]) {
          this.vmProperties.startDate = this.vmProperties.endDate = ALL_DATES_VALUES;
        }
        Object.keys(arrayProperties).forEach(k => this.syncArrayToHash(k, hashParameters));
      });
    }
    updateHash(hashObj) {
      if (clearPageOnFilterChange) {
        if (typeof hashObj.page === "undefined") {
          // we're changing other stuff, so reset page
          hashUtility.mergeParameters(Object.assign({}, hashObj, this.page ? { page: null } : {}));
        } else {
          hashUtility.mergeParameters(hashObj);
        }
      } else {
        hashUtility.mergeParameters(hashObj);
      }
    }
    //keeping this as a separate method - I can imagine a subclass someday wanting to inherit to add special behavior
    resetHashToState(filtersObj) {
      ["agGridColumns", "agGridFilters"].forEach(prop => delete filtersObj[prop]);

      mapToArraysKeys.forEach(prop => {
        if (filtersObj[prop + "Included"]) {
          filtersObj[prop + "Included"] = filtersObj[prop + "Included"].replace(/\,/g, "-");
        }
        if (filtersObj[prop + "Excluded"]) {
          filtersObj[prop + "Excluded"] = filtersObj[prop + "Excluded"].replace(/\,/g, "-");
        }
      });

      let extras: any = {};

      if (filtersObj.dateRange) {
        let [startDate, endDate] = this.translateDateRangeBack(filtersObj.dateRange);
        extras.startDate = moment(startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
        extras.endDate = moment(endDate, "MM-DD-YYYY").format("YYYY-MM-DD");
        extras.startdate = extras.enddate = null;

        delete filtersObj.dateRange;
      }

      this.datesWithPresets.forEach(prop => {
        if (filtersObj[prop]) {
          let savedValue = filtersObj[prop];
          delete filtersObj[prop];

          if (/^custom:/.test(savedValue)) {
            filtersObj[prop] = moment(savedValue.split("custom:")[1]).format("YYYY-MM-DD");
          } else {
            filtersObj[prop] = moment(this.translateDateValueBack(savedValue)).format("YYYY-MM-DD");
          }
        }
      });

      //leave whatever dates they currently have alone. The loaded filter may of course override, but if the loaded filter does not override, then
      //we leave what they have alone
      let defaultHashStateWithoutStartAndEndDates: any = { ...defaultHashState };
      delete defaultHashStateWithoutStartAndEndDates.startDate;
      delete defaultHashStateWithoutStartAndEndDates.endDate;
      delete defaultHashStateWithoutStartAndEndDates.enddate;
      delete defaultHashStateWithoutStartAndEndDates.startdate;

      this.updateHash(Object.assign({}, defaultHashStateWithoutStartAndEndDates, filtersObj, extras));
    }
    onSearchParametersChanged() {
      nonArrayVmProperties.forEach(vmProp => this.vmProperties[vmProp]);
      mapToArraysKeys.forEach(k => {
        this.searchArrayCollections[`${k}Collection`].forEach(o => {
          let included = o.isIncluded;
        });
      });
    }
    listenToFilterChanges(cb, runImmediately = true) {
      let d = new DirtyHelper();
      let last_nonQueryParamsDirty, last_queryParamsDirty;

      this.toDispose.push(
        autorun(() => {
          this.onNonQueryParametersChanged();
          runInAction(() => d.nonQueryParamsDirty++);
        })
      );
      this.toDispose.push(
        autorun(() => {
          this.onQueryParametersChanged();
          runInAction(() => d.queryParamsDirty++);
        })
      );

      last_nonQueryParamsDirty = d.nonQueryParamsDirty;
      last_queryParamsDirty = d.queryParamsDirty;

      this.toDispose.push(
        autorun(() => {
          let nonQueryParamsAreDirty = last_nonQueryParamsDirty !== d.nonQueryParamsDirty;
          let queryParamsAreDirty = last_queryParamsDirty !== d.queryParamsDirty;

          last_nonQueryParamsDirty = d.nonQueryParamsDirty;
          last_queryParamsDirty = d.queryParamsDirty;

          if (runImmediately) {
            runInAction(() => cb({ queryParamsAreDirty, nonQueryParamsAreDirty }));
          }

          // TODO: Fix this exception. Is this line needed/correct?
          //       Does it actually *do* anything, can it just be removed?
          // tslint:disable-next-line:no-parameter-reassignment
          runImmediately = true;
        })
      );
    }
    onNonQueryParametersChanged() {
      nonQueryParameters.forEach(vmProp => this.vmProperties[vmProp]);
    }
    onQueryParametersChanged() {
      nonArrayVmProperties.forEach(vmProp => {
        if (!nonQueryParametersLookup.has(vmProp)) {
          this.vmProperties[vmProp];
        }
      });
      mapToArraysKeys.forEach(k => {
        this.searchArrayCollections[`${k}Collection`].forEach(o => {
          let included = o.isIncluded;
        });
      });
    }

    getCleanRequestObject(maintainArrays = false, normalizeLookups = false) {
      let request: any = {},
        allVmProperties = toJS(this.vmProperties);

      Object.keys(allVmProperties).forEach(vm => {
        if (typeof allVmProperties[vm] !== "function" && this.isActive(vm)) {
          if (normalizeLookups && typeof properties[vm] !== "undefined" && properties[vm].isToggle && +allVmProperties[vm] < 0) {
            request[vm] = (+allVmProperties[vm] * -1).toString();
          } else {
            request[vm] = allVmProperties[vm];
          }
        }
      });

      if (this.vmProperties.tasksFilter) {
        Object.assign(request, (([ui, tasksPeriod, tasksDate]) => ({ tasksPeriod, tasksDate }))(tasksFilterOptions[request.tasksFilter] || []));
      }

      if (this.startDate == ALL_DATES_VALUES && this.endDate == ALL_DATES_VALUES) {
        delete request.startDate;
        delete request.endDate;
      } else {
        if (this.vmProperties.startDate) {
          request.startDate = this.startDateDisplay;
        }
        if (this.vmProperties.endDate) {
          request.endDate = this.endDateDisplay;
        }
      }

      let manualProperties = ["sortBy", "sortDir", "sort", "page", "pageSize", "filter"];
      manualProperties.forEach(prop => {
        if (this.vmProperties.hasOwnProperty(prop) && this.vmProperties[prop]) {
          request[prop] = this.vmProperties[prop];
        }
      });

      mapToArraysKeys.forEach(prop => {
        if (this.isActive(prop)) {
          request[prop + "s"] = this.getCollectionRequestValue(prop, maintainArrays);

          let vals = this.searchArrayCollections[prop + "Collection"],
            included = vals.filter(o => o.isIncluded).map(o => o.id),
            excluded = vals.filter(o => !o.isIncluded).map(o => o.id);
          request[prop + "Included"] = maintainArrays ? included : included.join(",");
          request[prop + "Excluded"] = maintainArrays ? excluded : excluded.join(",");
        }
      });
      return request;
    }
    getRequestObject(maintainArrays = false) {
      let request = toJS(this.vmProperties);

      Object.assign(request, (([ui, tasksPeriod, tasksDate]) => ({ tasksPeriod, tasksDate }))(tasksFilterOptions[request.tasksFilter] || []));

      if (this.startDate == ALL_DATES_VALUES && this.endDate == ALL_DATES_VALUES) {
        delete request.startDate;
        delete request.endDate;
      } else {
        if (this.vmProperties.startDate) {
          request.startDate = this.startDateDisplay;
        }
        if (this.vmProperties.endDate) {
          request.endDate = this.endDateDisplay;
        }
      }

      mapToArraysKeys.forEach(prop => {
        request[prop + "s"] = this.getCollectionRequestValue(prop, maintainArrays);

        let vals = this.searchArrayCollections[prop + "Collection"],
          included = vals.filter(o => o.isIncluded).map(o => o.id),
          excluded = vals.filter(o => !o.isIncluded).map(o => o.id);
        request[prop + "Included"] = maintainArrays ? included : included.join(",");
        request[prop + "Excluded"] = maintainArrays ? excluded : excluded.join(",");
      });
      return request;
    }

    getExportRequestObject() {
      return this.getCleanRequestObject();
    }

    @action
    serializeCurrentFilters(options: any = {}) {
      let result: any = {};
      Object.keys(this.vmProperties).forEach(vm => this.isActive(vm) && (result[vm] = this.vmProperties[vm]));

      mapToArraysKeys.forEach(vm => {
        let arr = this.searchArrayCollections[vm + "Collection"],
          include = arr
            .filter(o => o.isIncluded)
            .map(o => o.id)
            .join("-"),
          exclude = arr
            .filter(o => !o.isIncluded)
            .map(o => o.id)
            .join("-");

        include && (result[`${vm}Included`] = include);
        exclude && (result[`${vm}Excluded`] = exclude);
      });
      delete result.page;

      if (this.addStartAndEndDates) {
        result.dateRange = this.translateDateRange();
        if (!result.dateRange || options.dateRangeHandling == 1) {
          result.dateRange = `custom:${moment(this.startDate).format("MM-DD-YYYY")}|${moment(this.endDate).format("MM-DD-YYYY")}`;
        }
        if (options.dateRangeHandling == -1) {
          delete result.dateRange;
          delete result.startDate;
          delete result.endDate;
          delete result.startdate;
          delete result.enddate;
        }
      }

      if (options.datesWithPresets) {
        [...options.datesWithPresets]
          .filter(([prop, info]) => info.currentValue)
          .forEach(([prop, info]) => {
            result[prop] =
              info.candidatePreset && info.dateRangeHandling == "0"
                ? info.candidatePreset
                : `custom:${moment(info.currentValue).format("MM-DD-YYYY")}`;
          });
      }

      return result;
    }

    getCollectionRequestValue(prop, maintainArrays = false) {
      let vals = this.searchArrayCollections[prop + "Collection"],
        array = vals
          .filter(o => o.isIncluded)
          .map(o => o.id)
          .concat(vals.filter(o => !o.isIncluded).map(o => "-" + o.id));
      return maintainArrays ? array : array.join(",");
    }
    setSearchDisplayValues(resp: any): void {
      runInAction(() => {
        mapToArraysKeys.forEach(vm => {
          if (resp[vm + "s"]) {
            resp[vm + "s"].forEach(item => {
              normalizeArrayItemResponse(item);
              let existing = this.searchArrayCollections[vm + "Collection"].find(o => o.id == item.id);
              if (existing) {
                existing.mapResponse(item);
              }
            });
          }
        });
        this.displayProperties.mapResponse(resp);
      });
    }

    get startDate() {
      return this.vmProperties.startDate;
    }
    get endDate() {
      return this.vmProperties.endDate;
    }
    @computed
    get startDateMoment() {
      return this.startDate == ALL_DATES_VALUES ? null : moment(this.startDate);
    }
    @computed
    get endDateMoment() {
      return this.endDate == ALL_DATES_VALUES ? null : moment(this.endDate);
    }
    @computed
    get startDateDisplay() {
      return this.startDate == ALL_DATES_VALUES ? "ALL" : moment(this.startDate).format("YYYY-MM-DD");
    }
    @computed
    get endDateDisplay() {
      return this.endDate == ALL_DATES_VALUES ? "ALL" : moment(this.endDate).format("YYYY-MM-DD");
    }

    @computed
    get dateRange() {
      if (this.startDate == ALL_DATES_VALUES && this.endDate == ALL_DATES_VALUES) {
        return "All Dates";
      }

      //conditional formatting to save space when the year is either not needed or both years are the same
      let td = moment();
      let sd = this.startDateMoment;
      let ed = this.endDateMoment;

      if (td.year() === sd.year() && td.year() === ed.year()) {
        return sd.format("MMM D") + " - " + ed.format("MMM D");
      } else if (sd.year() === ed.year()) {
        return sd.format("MMM D") + " - " + ed.format("MMM D, YYYY");
      } else {
        return sd.format("MMM D, YYYY") + " - " + ed.format("MMM D, YYYY");
      }
    }

    @computed
    get activeFilterCount() {
      return (
        Object.keys(this.vmProperties).filter(vm => vm != "page" && vm != "pageSize" && vm != "defer" && this.isActive(vm)).length +
        mapToArraysKeys.filter(k => this.searchArrayCollections[`${k}Collection`].length).length
      );
    }
    @computed
    get nonArrayFiltersActive() {
      return Object.keys(this.vmProperties).filter(vm => vm != "page" && vm != "pageSize" && vm != "defer" && this.isActive(vm));
    }
    @computed
    get anyActiveFilters() {
      return !!this.activeFilterCount;
    }
    @computed
    get filterBarVisible() {
      return !!this.activeFilterBarFilterCount;
    }
    @computed
    get activeFilterBarFilterCount() {
      return (
        Object.keys(this.vmProperties).filter(
          vm => nonFilterBarSearchProperties.indexOf(vm) < 0 && suppressFromFilterBar.indexOf(vm) < 0 && this.isActive(vm)
        ).length + mapToArraysKeys.filter(k => this.searchArrayCollections[`${k}Collection`].length).length
      );
    }
    isActive = vm => {
      if (this.searchArrayCollections[`${vm}Collection`]) {
        return this.searchArrayCollections[`${vm}Collection`].length;
      } else {
        let emptyValue = typeof defaults[vm] !== "undefined" ? defaults[vm] : "";

        if (vm == "startDate" || vm == "endDate") {
          return !CrAreDatesEqual(this.vmProperties[vm], emptyValue);
        } else {
          return this.vmProperties[vm] !== emptyValue;
        }
      }
    };
    @action
    isAdvancedSearchOptionValid = obj => {
      let type = obj.type || obj.Type;
      if (!type) {
        return false;
      }

      let key = Object.keys(properties).find(k => type.toLowerCase() == k.toLowerCase());
      if (!key) {
        return false;
      }
      let packet = properties[key];

      if (nonArrayProperties[key]) {
        return this.vmProperties[packet.vm] != (obj.id || obj.Id);
      } else {
        let arr = this.searchArrayCollections[`${packet.vm}Collection`];
        if (arr == undefined) {
          return false;
        }
        return !arr.find(currentItem => currentItem.id == (obj.id || obj.Id));
      }
    };
    @action
    advancedSearchSelected = obj => {
      let nonArrayKey = Object.keys(nonArrayProperties).find(k => k.toLowerCase() == obj.type.toLowerCase());

      if (nonArrayKey) {
        let isText = ["text", "textActive"].indexOf(nonArrayKey) > -1;
        this.updateHash({ [nonArrayProperties[nonArrayKey].vm]: isText ? obj.name : obj.id });
      } else {
        let arrayKey = Object.keys(arrayProperties).find(k => k.toLowerCase() == obj.type.toLowerCase());
        if (!arrayKey) {
          return;
        }
        let packet = arrayProperties[arrayKey],
          arr = this.searchArrayCollections[`${packet.vm}Collection`];

        // append value to the URL hash only if it's not already added
        if (!arr.some(({ id }) => `${id}` === `${obj.id}`)) {
          arr.push(MultiValueSelection.createFromResponse({ id: obj.id, arrayNamePrefix: arrayKey }));
        }

        this.syncHashFromArray(arrayKey);
      }
    };
    
    getDeferValue() {
      return typeof this.vmProperties.defer !== "undefined" ? true : null;
    }

    @action clearFilterItem = vm => {
      this.updateHash({[vm]: null, defer: this.getDeferValue() });
    }
    @action
    includeArrayValue = item => {
      if (this.getDeferValue()) {
        this.updateHash({ defer: true });
      }
      item.isIncluded = true;
      this.syncHashFromArray(item.arrayNamePrefix);
    };
    @action
    excludeArrayValue = item => {
      if (this.getDeferValue()) {
        this.updateHash({ defer: true });
      }
      item.isIncluded = false;
      this.syncHashFromArray(item.arrayNamePrefix);
    };
    @action
    toggleExistingArrayValue = item => {
      item.isIncluded = !item.isIncluded;
      this.syncHashFromArray(item.arrayNamePrefix);
    };

    @action
    toggleFilterItem = (type, obj, isInitiallyExcluded = false) => {
      if (this.getDeferValue()) {
        this.updateHash({ defer: true });
      }
      let packet = arrayProperties[type],
        arr = this.searchArrayCollections[`${packet.vm}Collection`];

      let existingItem = arr.find(item => item.id == obj.id);
      if (existingItem) {
        this.toggleExistingArrayValue(existingItem);
      } else {
        arr.push(
          MultiValueSelection.createFromResponse({ id: obj.id, name: obj.name || "", arrayNamePrefix: type, isIncluded: !isInitiallyExcluded })
        );
        this.syncHashFromArray(type);
      }
    };

    @action
    toggleNonExcludableFilterItem = (type, obj) => {
      let packet = arrayProperties[type],
        arr = this.searchArrayCollections[`${packet.vm}Collection`];
      let existingItem = arr.find(item => item.id == obj.id);
      if (existingItem) {
        arr.remove(existingItem);
      } else {
        arr.push(MultiValueSelection.createFromResponse({ id: obj.id, name: obj.name || "", arrayNamePrefix: type }));
      }
      this.syncHashFromArray(type);
    };

    @action
    removeArrayValue = item => {
      if (this.getDeferValue()) {
        this.updateHash({ defer: true });
      }
      let packet = arrayProperties[item.arrayNamePrefix];
      this.searchArrayCollections[`${packet.vm}Collection`].remove(item);
      this.syncHashFromArray(item.arrayNamePrefix);
    };
    @action toggleOffItem = vm =>
      this.updateHash({ [vm]: (+hashUtility.getParameterValue(vm) > 1 ? +hashUtility.getParameterValue(vm) * -1 : 0).toString() });
    @action toggleOnItem = vm =>
      this.updateHash({ [vm]: (+hashUtility.getParameterValue(vm) < 0 ? +hashUtility.getParameterValue(vm) * -1 : 1).toString() });

    @action pageUp = () => this.updateHash({ page: this.page + 1 });
    @action pageDown = () => this.updateHash({ page: this.page == 2 ? null : this.page - 1 });
    @action
    syncHashFromArray(rootName) {
      let packet = arrayProperties[rootName],
        arr = this.searchArrayCollections[`${packet.vm}Collection`],
        includeIds = arr
          .filter(item => item.isIncluded)
          .map(item => item.id)
          .join("-"),
        excludeIds = arr
          .filter(item => !item.isIncluded)
          .map(item => item.id)
          .join("-");

      this.updateHash({
        [packet.vm + "Included"]: includeIds || null,
        [packet.vm + "Excluded"]: excludeIds || null
      });
    }
    @action
    syncArrayToHash = (packetKey, parameters) => {
      let packet = arrayProperties[packetKey],
        currentInclude = [...new Set((parameters[packet.vm + "Included"] || "").split("-").filter(s => s))],
        currentExclude = [...new Set((parameters[packet.vm + "Excluded"] || "").split("-").filter(s => s))],
        allIds = new Set([...currentInclude, ...currentExclude]),
        currentArray = this.searchArrayCollections[packet.vm + "Collection"],
        toRemove = currentArray.filter(o => !allIds.has("" + o.id));

      toRemove.forEach(o => currentArray.remove(o));
      currentArray.forEach(obj => {
        if (obj.isIncluded && currentExclude.includes("" + obj.id)) {
          obj.isIncluded = false;
        }
        if (!obj.isIncluded && currentInclude.includes("" + obj.id)) {
          obj.isIncluded = true;
        }
      });
      let currentIds = new Set(currentArray.map(o => "" + o.id));

      currentInclude.forEach(id => {
        if (!currentIds.has(id)) {
          currentArray.push(this.createMultiValueSelection(packetKey, id, true));
        }
      });
      currentExclude.forEach(id => {
        if (!currentIds.has(id)) {
          currentArray.push(this.createMultiValueSelection(packetKey, id, false));
        }
      });
    };

    createMultiValueSelection(packetKey, id, isIncluded) {
      let selection = Object.assign(new MultiValueSelection(), { arrayNamePrefix: packetKey, id, name: "", isIncluded: isIncluded });
      return selection;
    }

    @action
    removeItemFromArrayIfPresent(packetKey, id) {
      let packet = arrayProperties[packetKey],
        currentArray = this.searchArrayCollections[packet.vm + "Collection"],
        itemToRemove = currentArray.find(packet => packet.id == id);

      if (itemToRemove) {
        currentArray.remove(itemToRemove);
        this.syncHashFromArray(packetKey);
      }
    }
    @action
    getFiltersResetHashObject() {
      let resetObject: any = {};
      Object.keys(this.vmProperties).forEach(vm => this.isActive(vm) && (resetObject[vm] = null));

      mapToArraysKeys.forEach(key => {
        resetObject[key + "Included"] = null;
        resetObject[key + "Excluded"] = null;
      });
      delete resetObject.pageSize;
      return resetObject;
    }
    @action
    resetAllFilters = () => {
      this.updateHash({ ...this.getFiltersResetHashObject(), defer: this.getDeferValue() });
    };

    @action
    mapLocalFilters(): void {
      let localScalarFilters = Object.keys(this.vmProperties)
        .map(key => [vmToOriginalPropertyLookup.get(key), this.vmProperties[key]])
        .filter(([vm]) => vm && typeof vm.localMapper === "function")
        .reduce((filters, [vm, value]) => {
          const normalizedValue = typeof value !== "undefined" && vm.isToggle && +value < 0 ? +value * -1 : value;
          filters[vm.display] = vm.localMapper(normalizedValue, this);
          return filters;
        }, {} as { [index: string]: string });

      let localArrayFilters = mapToArrays
        .filter((item => typeof item !== "string" && typeof item.localMapper == "function") as (e: arrayMapElement) => e is richArrayMapElement)
        .reduce((filters, item) => {
          filters[item.name + "s"] = this.searchArrayCollections[item.name + "Collection"].map(value => ({
            id: value.id,
            isIncluded: value.isIncluded,
            name: item.localMapper(value),
            formatDisplay: item.formatDisplay
          }));
          return filters;
        }, {});

      this.setSearchDisplayValues({ ...localScalarFilters, ...localArrayFilters });
    }

    @action
    mapFilters = () => {
      this.mapLocalFilters();

      let clean = this.getCleanRequestObject(true, true);
      let lookup = this.getCleanRequestObject(true, true);
      let remoteLookups = {};
      let classLookups = new Map<string, Set<any>>();

      // tslint:disable-next-line:forin
      for (let key in lookup) {
        let prop = vmToOriginalPropertyLookup.get(key);
        if (!prop) {
          delete lookup[key];
          continue;
        }
        // check for a "not set" value and short-circuit to use it if it equals current value
        if (prop.notSetValue == lookup[key]) {
          this.displayProperties[prop.display] = prop.notSetDisplay;
          delete lookup[key];
          continue;
        }
        // check if:
        // 1) local mapper exists and there is a display value available already for the prop
        // 2) prop is specified to be suppressed from filter bar
        // 3) prop is specified as a non-filter bar prop
        if (
          (prop.localMapper && this.displayProperties[prop.display]) ||
          suppressFromFilterBar.indexOf(key) >= 0 ||
          nonFilterBarSearchProperties.indexOf(key) >= 0
        ) {
          delete lookup[key];
          continue;
        }
        // check if prop has a remote mapper class
        if (prop && prop.mapperClass) {
          let { mapperClass } = prop;
          remoteLookups[prop.vm] = true;
          // ensure class lookup exists for the class
          classLookups.set(mapperClass, classLookups.get(mapperClass) || new Set<any>());
          // add the current lookup value for the class
          classLookups.get(mapperClass).add(lookup[key]);
          delete lookup[key];
        }
      }

      mapToArrays.forEach(item => {
        let key = typeof item == "string" ? item : item.name;

        delete lookup[`${key}Included`];
        delete lookup[`${key}Excluded`];

        if (typeof item !== "string") {
          if (typeof item.localMapper == "function") {
            delete lookup[`${key}s`];
          } else {
            let { mapperClass } = item;
            if (mapperClass) {
              delete lookup[`${key}s`];
              let classLookup = classLookups.get(mapperClass) || new Set<any>();
              this.searchArrayCollections[`${key}Collection`].forEach(value => classLookup.add(value.id));
              classLookups.set(mapperClass, classLookup);
            }
          }
        }
      });

      [...classLookups].forEach(([mapperClass, values]) => (lookup[mapperClass] = [...values]));

      let request = {
        lookup: JSV.stringify(lookup)
      };

      return Object.keys(lookup).length
        ? api.get("filters/map", request).then(
            action<({ filters: any }) => void>(resp => {
              let displayValues = Object.keys(resp.filters || {}).reduce((obj, key) => {
                let keyResponse = resp.filters[key];
                let vmKeys = Object.keys(properties || {}).filter(prop => key == `${properties[prop].mapperClass}Names`);
                let arrayKeys = mapToArrays
                  .filter<richArrayMapElement>((item => typeof item !== "string") as (item: arrayMapElement) => item is richArrayMapElement)
                  .filter(({ mapperClass }) => key == `${mapperClass}` || key == `${mapperClass}Names`)
                  .map(({ name, formatDisplay }) => ({ key: name, formatDisplay }));
                if (vmKeys.length || arrayKeys.length) {
                  vmKeys.forEach(vmKey => {
                    let vm = properties[vmKey];
                    if (!vm) {
                      return;
                    }
                    let entry = keyResponse.find(entry => clean[vm.vm] == entry.key);
                    if (!entry) {
                      if (remoteLookups[vm.vm]) {
                        // lookup was actually requested for the item
                        obj[vm.display] = vm.notFoundDisplay ? vm.notFoundDisplay(this.vmProperties[vm.vm]) : "Not Found";
                      }
                      return;
                    }
                    obj[vm.display] = vm && vm.remoteMapper ? vm.remoteMapper(entry.value, entry.key) : entry.value;
                  });
                  arrayKeys.forEach(({ key, formatDisplay }) => {
                    let collection = this.searchArrayCollections[`${key}Collection`];
                    obj[`${key}s`] = keyResponse.map(resp => {
                      let id = resp.id || resp.key;
                      let name = resp.name || resp.value;                    

                      return {
                        id,
                        name,
                        formatDisplay,
                        isIncluded: (collection.find(item => item.id == id) || {}).isIncluded
                      };
                    });
                  });
                  return obj;
                }
                let vmKey = Object.keys(properties || {}).find(vmKey => properties[vmKey].display == key);
                let vm = properties[vmKey];
                obj[key] = vm && vm.remoteMapper ? vm.remoteMapper(keyResponse, clean[vm.vm]) : keyResponse;
                return obj;
              }, {});

              this.setSearchDisplayValues(displayValues);
            })
          )
        : Promise.resolve();
    };

    getLabels(vm, store) {
      return this.searchArrayCollections[vm + "Collection"].map(selected => {
        let label = store.allLabelsLookup.get(selected.id) || {};
        return {
          id: selected.id,
          name: label.name,
          isIncluded: selected.isIncluded ? 1 : 0
        };
      });
    }

    //---------------------------------------------------------------------------------------------------
    translateDateRangeFriendly() {
      if (!this.addStartAndEndDates) {
        return "";
      }

      let result = this.translateDateRange();

      if (result == "today") {
        return "today";
      }
      if (result == "yesterday") {
        return "yesterday";
      }
      if (result == "last7") {
        return "last 7 days";
      }
      if (result == "last30") {
        return "last 30 days";
      }
      if (result == "last45") {
        return "last 45 days";
      }
      if (result == "r60") {
        return "31–60 days";
      }
      if (result == "r90") {
        return "61–90 days";
      }
      if (result == "r120") {
        return "91–120 days";
      }
      if (result == "thismonth") {
        return "this month";
      }
      if (result == "lastmonth") {
        return "last month";
      }
      if (result == "thisweek") {
        return "this week";
      }
      if (result == "nextweek") {
        return "next week";
      }
      if (result == "nextmonth") {
        return "next month";
      }
      return result;
    }
    translateDateRange() {
      let startDate = this.startDate,
        endDate = this.endDate;

      setDateConstants();

      let start = moment(startDate).format("MM-DD-YYYY");
      let end = moment(endDate).format("MM-DD-YYYY");

      if (today == start && today == end) {
        return "today";
      } else if (yesterday == start && yesterday == end) {
        return "yesterday";
      } else if (today == end && last7 == start) {
        return "last7";
      } else if (today == end && last30 == start) {
        return "last30";
      } else if (today == end && last45 == start) {
        return "last45";
      } else if (start == r60Start && r60 == end) {
        return "r60";
      } else if (start == r90Start && r90 == end) {
        return "r90";
      } else if (start == r120Start && r120 == end) {
        return "r120";
      } else if (thisMonthStart == start && thisMonthEnd == end) {
        return "thismonth";
      } else if (lastMonthStart == start && lastMonthEnd == end) {
        return "lastmonth";
      } else if (nextMonthStart == start && nextMonthEnd == end) {
        return "nextmonth";
      } else if (thisWeekStart == start && thisWeekEnd == end) {
        return "thisweek";
      } else if (nextWeekStart == start && nextWeekEnd == end) {
        return "nextweek";
      } else {
        return "";
      }
    }
    translateDateRangeBack(dateRange) {
      setDateConstants();

      if (/custom/.test(dateRange)) {
        const updatedDateRange = dateRange.replace("custom:", "");
        return updatedDateRange.replace("custom:", "").split("|");
      } else {
        const translations = {
          today: [today, today],
          yesterday: [yesterday, yesterday],
          last7: [last7, today],
          last30: [last30, today],
          last45: [last45, today],
          r60: [r60Start, r60],
          r90: [r90Start, r90],
          r120: [r120Start, r120],
          thismonth: [thisMonthStart, thisMonthEnd],
          lastmonth: [lastMonthStart, lastMonthEnd],
          nextmonth: [nextMonthStart, nextMonthEnd],
          thisweek: [thisWeekStart, thisWeekEnd],
          nextweek: [nextWeekStart, nextWeekEnd]
        };

        return translations[dateRange] || [defaults.startDate, defaults.endDate];
      }
    }
    translateDateValueFriendly(dateValue) {
      let value = this.translateDateValue(dateValue);

      if (value == "today") {
        return "today";
      } else if (value == "yesterday") {
        return "yesterday";
      } else if (value == "daysago7") {
        return "7 days ago";
      } else if (value == "daysago14") {
        return "14 days ago";
      } else if (value == "daysago30") {
        return "30 days ago";
      } else if (value == "daysago45") {
        return "45 days ago";
      } else if (value == "daysago60") {
        return "60 days ago";
      } else if (value == "daysago90") {
        return "90 days ago";
      }

      return "";
    }
    translateDateValue(value) {
      const date = moment(value).format("MM-DD-YYYY");
      setDateConstants();

      if (date == today) {
        return "today";
      } else if (date == yesterday) {
        return "yesterday";
      } else if (date == daysago7) {
        return "daysago7";
      } else if (date == daysago14) {
        return "daysago14";
      } else if (date == daysago30) {
        return "daysago30";
      } else if (date == daysago45) {
        return "daysago45";
      } else if (date == daysago60) {
        return "daysago60";
      } else if (date == daysago90) {
        return "daysago90";
      }

      return "";
    }
    translateDateValueBack(value) {
      const translations = {
        today,
        yesterday,
        daysago7,
        daysago14,
        daysago30,
        daysago45,
        daysago60,
        daysago90
      };

      return translations[value];
    }
    //---------------------------------------------------------------------------------------------------

    dispose() {
      this.toDispose.forEach(d => d());
    }
  }

  const FilterBar = filterBarFactory({ properties, defaults, mapToArrays, suppressFromFilterBar, labelStores, isFilterRound, isMaterialUi });

  class SearchVm extends Base {}

  class DisconnectedSearchVm extends SearchVm {
    hash = {};

    @action
    updateHash(hashObj) {
      let newHash = {};

      // tslint:disable-next-line:forin
      for (let key in hashObj) {
        if (hashObj[key] === undefined || hashObj[key] === null || hashObj[key] === "") {
          delete this.hash[key];
          continue;
        }
        this.hash[key] = hashObj[key];
      }

      this.syncWithHash(this.hash);
      if (typeof this.onHashUpdated === "function") {
        return this.onHashUpdated();
      }
    }
  }

  return { FilterBar, SearchVm: disconnectFromHash ? DisconnectedSearchVm : SearchVm };
};

export default searchVmFactory;

export const legacyIsAdvancedSearchOptionValid = obj => {
  let type = obj.type || obj.Type;
  if (!type) {
    return false;
  }

  return true;
};
