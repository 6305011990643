import React, { Component, createElement } from "react";
import { observer } from "mobx-react";
import Dropzone from "third_party_libraries/react-dropzone/react-dropzone";
import { Alert } from "@material-ui/lab";
import { VisibilityOffIcon } from "@cr/icons/VisibilityOff";
import { RemoveRedEyeIcon } from "@cr/icons/RemoveRedEye";
import { DeleteOutlineIcon } from "@cr/icons/DeleteOutline";
import { ImageIconOutlined } from "@cr/icons/Image";
import { VideocamIconOutlined } from "@cr/icons/Videocam";
import { PictureAsPdfIcon } from "@cr/icons/PictureAsPdf";
import { ArticleIconOutlined } from "@cr/icons/Article";
import { CheckCircleIcon } from "@cr/icons/CheckCircle";
import { ErrorIcon } from "@cr/icons/Error";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { CircularProgress, Typography } from "@material-ui/core";

export const iconMap = {
  "fa-file-pdf": PictureAsPdfIcon,
  "fa-file-video": VideocamIconOutlined,
  "fa-file-image": ImageIconOutlined
};

export const defaultIcon = ArticleIconOutlined;

const errorMap = {
  100: "The file you are attempting to upload is too big. The maximum allowed size is 250MB for videos, 40MB for files.",
  200: `The file you are attempting to upload is in the wrong format.
  Acceptable file types are: .xls, .doc, .pdf, .txt, .rft, .jpg, .jpeg, .gif, .png
  Acceptable video types are: .avi, .wmv, .flv, .asf, .m4v, .mov, .mp4, .m4a, .3gp, .3g2, .mj2`,
  300: "Video files are not allowed to be uploaded as a new version.",
  400: "This upload has stopped responding and has been cancelled.",
  500: "File did not pass the virus detection process and failed to upload."
};

const defaultErrorMessage = `We were unable to process your file at this time. Possible reasons include intermittent connectivity issues or a corrupted file. Please double-check your file to ensure it's valid and try again in a few minutes.

We've also logged this error. If you believe there not to be any issues on your end please submit a support ticket by clicking your name on the top-right of the screen and select "Open a Support Ticket"`;

const iconSize = 18;

const File = observer(({ file, onDeleteClick, saving }) => (
  <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
    <Typography component="div" style={{ fontSize: iconSize, lineHeight: 0 }}>
      {file.isUploadSuccess ? (
        <CheckCircleIcon fontSize="inherit" style={{ color: "#18A794" }} />
      ) : file.isUploadError ? (
        <ErrorIcon fontSize="inherit" style={{ color: "#BA293C" }} />
      ) : (
        createElement(iconMap[file.iconClass] || defaultIcon, { fontSize: "inherit" })
      )}
    </Typography>
    <Typography
      component="div"
      variant="body2"
      style={{ marginLeft: 10, width: 132, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      title={file.name}
    >
      {file.name}
    </Typography>
    {saving && !file.isUploadFinished ? (
      <div style={{ flex: 1, textAlign: "right" }}>
        <CircularProgress size={iconSize} />
      </div>
    ) : (
      <>
        <Typography component="div" variant="body2" style={{ flex: 1, marginLeft: 10, color: "#BA293C", whiteSpace: "pre-line" }}>
          {file.isUploadError ? errorMap[file.errorCode.toString()] || defaultErrorMessage : null}
        </Typography>
        <Typography component="div" variant="body2" color="textSecondary" style={{ width: 75, textAlign: "right" }}>
          {file.humanSize}
        </Typography>
        <Typography
          component="div"
          color="textSecondary"
          style={{ fontSize: iconSize, lineHeight: 0, marginLeft: 20, color: file.previewShow ? "#3F4B50" : "rgba(0, 0, 0, 0.38)" }}
        >
          {file.previewShow ? (
            <RemoveRedEyeIcon onClick={file.preview} fontSize="inherit" color="inherit" style={{ cursor: "pointer" }} />
          ) : (
            <VisibilityOffIcon fontSize="inherit" color="inherit" />
          )}
        </Typography>
        <Typography component="div" color="textSecondary" style={{ fontSize: iconSize, lineHeight: 0, marginLeft: 20 }}>
          <DeleteOutlineIcon
            style={{ cursor: "pointer", visibility: !file.isUploadSuccess ? "visible" : "hidden" }}
            onClick={!file.isUploadSuccess ? onDeleteClick : null}
          />
        </Typography>
      </>
    )}
  </div>
));

@observer
class MultiFileUpload extends Component {
  open() {
    if (this.dropZone) {
      this.dropZone.open();
    }
  }
  render() {
    let {
      store,
      store: { saving, files },
      maxFiles,
      showFiles = true,
      label = "Drop your file here to upload",
      labelSelectFiles = "Click to select",
      ...rest
    } = this.props;

    let singleFileMode = maxFiles === 1;

    return (
      <div {...rest}>
        <div style={{ position: "relative" }}>
          {store.enabled ? ( // if this is in a react collapse, we can't use margin-top on the DropZone (makes Collapse jump on expand), so we wrap with padding-top
            <div className="padding-top">
              <Dropzone
                ref={el => (this.dropZone = el)}
                style={{ cursor: "pointer" }}
                className="multi-file-upload-dropzone"
                activeClassName="drop-hover"
                onDrop={files => store.onDrop(files)}
              >
                {singleFileMode ? (
                  <span>
                    <div>{label}</div>
                    <div className="txt-md">
                      or <a>{labelSelectFiles}</a>
                    </div>
                  </span>
                ) : (
                  <span>
                    <div>{label}</div>
                    <div className="txt-md">
                      or <a>{labelSelectFiles ?? `Select ${maxFiles ? `up to ${maxFiles} Files` : `Files`}`}</a>
                    </div>
                  </span>
                )}
              </Dropzone>
            </div>
          ) : !singleFileMode ? (
            <div className="alert alert-info" style={{ marginTop: "5px" }}>
              You've selected the maximum number of {store.maxFiles} file{store.maxFiles === 1 ? "" : "s"}.
            </div>
          ) : null}
          {saving ? <div style={{ height: "100%", width: "100%", position: "absolute", top: 0, left: 0, zIndex: 10, opacity: 0 }} /> : null}
        </div>
        <ThemeProvider skipGlobalStyles>
          {saving ? (
            <Alert style={{ marginTop: 20, marginBottom: 20 }} severity="info">
              All files are scanned for malware which may impact upload time.
            </Alert>
          ) : null}
          {showFiles ? files.map((file, index) => (
            <File key={`upload-file-${index}`} {...{ file, onDeleteClick: () => store.onDelete(file), saving }} />
          )) : null}
        </ThemeProvider>
      </div>
    );
  }
}

export default MultiFileUpload;
