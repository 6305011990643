import ApiTransport from "app/util/apiTransport";
import { observable, action, extendObservable } from "mobx";
import domUtil from "app/util/domUtil";

/**
 * Label utility for interacting (CRUD) with the API. Current version - new development should go against this file.
 */

const _transport = new ApiTransport();

export const addLabel = (endpoint, itemId, labelId) => {
  var req = { id: itemId, labelId: labelId };
  return _transport.post(endpoint, req);
};

export const removeLabel = (endpoint, itemId, labelId) => {
  var req = { id: itemId, labelId: labelId };
  return _transport.delete(endpoint, req);
};

export const createLabelMethods = (createEndpoint, deleteEndpoint) => ({
  addLabel: (itemId, labelId) => addLabel(createEndpoint, itemId, labelId),
  removeLabel: (itemId, labelId) => removeLabel(deleteEndpoint, itemId, labelId)
});

export const standardSelect2ItemPacket = item => ({
  addLabel: item.addAndSaveLabel,
  removeLabel: item.removeAndSaveLabel,
  selectedLabels: item.labels
});

export const activeLabelsCollection = ({ collection = "labels", suffix = "Label", createEndpoint, deleteEndpoint }) => Class => {
  const { addLabel, removeLabel } = createLabelMethods(createEndpoint, deleteEndpoint);

  return class extends Class {
    @observable labels = [];

    @action
    addLabel = labelId => {
      this[collection] = this[collection] || [];
      if (!this[collection].find(label => label == +labelId)) {
        this[collection].push(+labelId);
      }
    };

    @action removeLabel = labelId => this[collection].remove(+labelId);

    @action
    addAndSaveLabel = labelId => {
      this.addLabel(labelId); //cheap hack to help keep select2 Labels sync'd
      return Promise.resolve(addLabel(this.id, labelId)).catch(resp => domUtil.displayMessage("Error adding label", { isError: true }));
    };

    @action
    removeAndSaveLabel = labelId => {
      this.removeLabel(labelId);
      return Promise.resolve(removeLabel(this.id, labelId)).catch(resp => domUtil.displayMessage("Error removing label", { isError: true }));
    };
  };
};
