import React, { Component } from "react";
import { observer } from "mobx-react";
import Modal from "bootstrap-modal";
import VideoPlayer from "app/components/videoPlaying/videoPlayer";

@observer
export default class FilePreview extends Component {
  render() {
    let { store } = this.props;

    return store.isShowing ? (
      <Modal show={store.isShowing} onHide={store.endPreview} manual={true} className="fade padding">
        <div className="modal-dialog no-margin" style={{ width: "100%", height: "100%" }}>
          <div className="modal-content" style={{ minHeight: "100%" }}>
            <div className="modal-header text-center padding">
              <button
                type="button"
                onClick={store.endPreview}
                className="pull-right btn btn-danger"
                style={{ marginTop: "-4px", marginRight: "-4px" }}
              >
                Close
              </button>
              <h4 className="modal-title">{store.name}</h4>
            </div>
            <div className="modal-body no-padding">
              {store.pdfFile ? (
                <iframe
                  type="application/pdf"
                  src={store.resourceUrl}
                  className="no-border"
                  style={{ width: "100%", height: "90vh" }}
                  allowFullScreen
                />
              ) : null}
              {store.imgFile ? (
                <div className="">
                  <img src={store.resourceUrl} className="img-responsive" style={{ margin: "0 auto" }} />
                </div>
              ) : null}
              {store.videoFile ? (
                <VideoPlayer store={store.videoPlayer} preload="yes" loop style={{ width: "100%", height: "auto" }} controls="true" />
              ) : null}

              {store.otherFile ? <span>File preview not supported for this file!</span> : null}
              {store.failed ? <span>Failed to preview this file!</span> : null}
            </div>
          </div>
        </div>
      </Modal>
    ) : null;
  }
}
