import { DiagnosisCodeMetaData } from "billingmanager/drafttimesheeteditor/components/draftTimesheetTypes";
import Contact from "contacts/list/models/contact";

export interface ResourceLabel {
  color: string;
  isDeleted: boolean;
  labelId: number;
  name: string;
  ownerContactId: number;
  parentLabelId: number;
}

export interface SessionNoteTemplate {
  templateId: string;
  version?: string;
  name: string;
  fileNameTemplate?: string;
  description: string;
  isSystem: boolean;
  s3Url?: string;
  sections: NoteTemplateSection[];
  resourceLabels?: ResourceLabel[];
  createdOnUtc?: string;
  modifiedOnUtc?: string;
  changeReasonRequired: boolean;
  optional: number;
  templateType: TemplateType;
}

export interface SessionNoteTemplateMultiAssociation extends SessionNoteTemplate {
  serviceCodeIds: number[];
}

export interface AppointmentContextInput {
  AppointmentDate: string;
  TimeWorkedFrom: number;
  TimeWorkedTo: number;
  BillCode: string;
  Duration: number;
  Units: number;
  LocationOfService: string;
  PlaceOfService: string;
  TimeFormat: number;
  TimeZone: string;
  SchedulingSegmentId?: number;
}

export interface ServiceLineContextInput {
  ServiceCodeId: number;
  TimeWorkedFrom: number;
  TimeWorkedTo: number;
  BillCode: string;
  Duration: number;
  Units: number;
  DiagnosisCodes: DiagnosisCodeMetaData[];
}

export interface ServiceLineContext {
  ServiceCodeId: number;
  StartTime: string;
  EndTime: string;
  BillCode: string;
  Duration: number;
  Units: number;
  DiagnosisCodes: DiagnosisCodeMetaData[];
}

export interface SessionNoteContext {
  Appointment?: {
    AppointmentDate: DateFormat;
    StartTime: string;
    EndTime: string;
    BillCode: string;
    Duration: number;
    Units: number;
    LocationOfService: string;
    PlaceOfService: string;
    TimeFormat: number;
    TimeZone: string;
    SchedulingSegmentId?: number;
  };

  Learner?: {
    Id: number;
    DOB: string;
    Age: number;
    FirstName: string;
    LastName: string;
    Insurance: string;
    SecondaryInsurance: string;
    TertiaryInsurance: string;
    DiagnosisCodes: DiagnosisCodeMetaData[];
    Phone: string;
    Email: string;
    Gender: string;
    GuardianFirstName: string;
    GuardianLastName: string;
  };

  Provider?: {
    Id: number;
    FirstName: string;
    LastName: string;
    NPI: string;
    Credentials: string;
    Phone: string;
    Email: string;
    Gender: string;
  };

  LearnerMetadata?: Record<string, string>;
  ProviderMetadata?: Record<string, string>;

  ServiceLines?: ServiceLineContext[];
}

export interface SessionNoteTemplateAssignment extends SessionNoteTemplate {
  isRequired: boolean;
  optional: NoteTemplateOptionEnum;
}

interface TemplateFieldBase {
  name: string;
  dataType: FieldType;
  defaultValues?: string[];
  defaultValue?: string;
  defaultBoolValue?: boolean;
  options?: FieldOption[];
  isRequired: boolean;
  isEditable: boolean;
  fieldId: string;
  width?: number;
  lineBreakBefore?: boolean;
}

export interface DefaultTemplateField extends TemplateFieldBase {
  excludeByDefault: boolean;
}

export const enum TemplateType {
  Custom = "custom",
  Aba = "aba",
  Soap = "soap"
}

export interface TemplateField extends TemplateFieldBase {
  defaultFieldId?: string;
  defaultDateTimeUtcValue?: string;
  defaultDecimalValue?: number;
  isDisplayed?: boolean;
}

export interface SummaryTemplateField extends TemplateField {
  sessionSummaryConfiguration: SessionSummaryConfiguration;
}

export type SessionSummaryFieldConfiguration = {
  isDisplayed: boolean;
  displayName?: string;
  isRequired?: boolean;
};

export type SessionSummaryFieldRequirableConfiguration = SessionSummaryFieldConfiguration & {
  isRequired?: boolean;
};

export type SessionSummaryConfiguration = {
  branchName: SessionSummaryFieldConfiguration;
  branchNarrative: SessionSummaryFieldRequirableConfiguration;
  currentScore: SessionSummaryFieldConfiguration;
  currentPhase: SessionSummaryFieldConfiguration;
  masteryCriteria: SessionSummaryFieldConfiguration;
  goalStatus: SessionSummaryFieldConfiguration;
  cariAiNarrative?: SessionSummaryFieldConfiguration;
  comments?: SessionSummaryFieldConfiguration;
};

export interface FieldOption {
  id: string;
  description: string;
}

export interface SessionNoteSectionBase {
  name: string;
  description: string;
  sectionType: SessionNoteSectionType;
  defaultSectionId: string;
  defaultSectionVersion?: string;
  fields: TemplateFieldBase[];
}
export interface NoteTemplateDefaultSection extends SessionNoteSectionBase {
  includeByDefault: boolean;
  fields: DefaultTemplateField[];
}

export interface NoteTemplateSection extends SessionNoteSectionBase {
  sectionId: string;
  fields: TemplateField[];
}

export enum SessionNoteSectionType {
  Custom = "Custom",
  Learner = "Learner",
  Provider = "Provider",
  Signature = "Signature",
  Appointment = "Appointment",
  SessionSummary = "SessionSummary",
  CompanyHeader = "CompanyHeader",
  Footer = "Footer"
}

export enum FieldType {
  SessionSummary = "SessionSummary",
  ShortText = "ShortText",
  Paragraph = "Paragraph",
  Header = "Header",
  SingleSelect = "SingleSelect",
  MultiSelect = "MultiSelect",
  SignatureClient = "SignatureClient",
  SignatureProvider = "SignatureProvider",
  SignatureOther = "SignatureOther",
  Image = "Image",
  Date = "Date",
  Time = "Time",
  Decimal = "Decimal",
  Duration = "Duration",
  Bool = "Bool",
  DiagnosisCodes = "DiagnosisCodes",
  Phone = "Phone"
}

export enum FieldValidation {
  TemplateTitleMaxLength = 120,
  SectionTitleMaxLength = 120,
  QuestionMaxLength = 500,
  OptionMaxLength = 300,
  editReasonMaxLength = 300
}

export class Logo {
  static readonly defaultFileType: string = "image/jpeg";
  static readonly maxFileSize: number = 20000000;
  static readonly maxLogoSize: MaxLogoSize = {
    height: 100,
    width: 250
  };
  static readonly allowedFileTypes: string[] = ["image/jpeg", "image/png"];
}

export interface SessionNote {
  id: string;
  organizationId: number;
  name: string;
  description: string;
  s3Url?: string;
  sections: SessionNoteSection[];
  resourceLabels?: ResourceLabel[];
  createdOnUtc: string;
  modifiedOnUtc: string;
  deletedOnUtc?: string;
  templateId: string;
  templateVersion: string;
  templateType?: TemplateType;
  clientId?: number;
  providerId?: number;
  createdBy: number;
  createdOnMobile: boolean;
  createdByContact: Contact;
  modifiedBy: number;
  modifiedByContact: Contact;
  isLocked: boolean;
  changeReasonRequired: boolean;
  timeFormat: number;
  timeZone: string;
  serviceDate?: DateFormat;
  dynamicName?: string;
  searchName?: string;
}

export interface SessionNoteSection {
  id: string;
  templateSectionId: string;
  defaultSectionId?: string;
  name: string;
  description: string;
  sectionType: SessionNoteSectionType;
  fields: SessionNoteField[];
}

export interface SessionNoteField {
  id: string;
  defaultFieldId?: string;
  templateFieldId: string;
  name: string;
  description?: string;
  isRequired: boolean;
  isEditable: boolean;
  isDisplayed: boolean;
  dataType: FieldType;
  options?: SessionNoteFieldOption[];
  metadata?: Record<string, any>;
  value: string;
  values: string[];
  signatureValue: string;
  signatureDateTime: string;
  signatureTimeZone: string;
  savedSignatureId?: number;
  signatureAttestationStatementId?: number;
  signatureAttestationStatement: string;
  signatureIpAddress: string;
  signatureAssignedTo?: number;
  sessionSummaryValues: SessionSummary[];
  sessionSummaryConfiguration: SessionSummaryConfiguration;
  dateTimeUtcValue?: string;
  decimalValue?: number;
  boolValue?: boolean;
  width: number;
  lineBreakBefore?: boolean;
}

export interface SessionNoteFieldOption {
  id: string;
  description: string;
}

export interface SummaryBranch {
  name: string;
  currentDataPoint: string;
  typeDescription: string;
  trialsActivity: string;
  currentPhase: string;
  masteryCriteria: string;
  goalStatus: string;
  branchNarrative?: string;
  phaseChange: string;
  addedBy: string;
}

export interface SummaryComment {
  author: string;
  branch: string;
  comment: string;
}

export interface SessionSummary {
  sessionId: number;
  activityId: number;
  sessionName: string;
  startedAt: string;
  endedAt: string;
  startedBy: string;
  endedBy: string;
  duration: number;
  branches: SummaryBranch[];
  comments?: SummaryComment[];
}

export interface SummaryBranchApi {
  id: number;
  name: string;
  provider: string;
  currentPhase: string;
  phaseChange: string;
  duration: number;
  value: string;
}

export interface SessionSummaryApi {
  isComplete: boolean;
  id: number;
  sessionId: number;
  sessionName: string;
  sessionCreatedBy: string;
  sessionCompletedBy: string;
  startDate: string;
  endDate: string;
  duration: number;
  summaries: SummaryBranchApi[];
}

export interface MaxLogoSize {
  height: number;
  width: number;
}

export interface FileMetaData {
  formData: {
    key: string;
  };
  url: string;
}

export interface DefaultFieldSelector {
  label: string;
  defaultFieldId: string;
  include: boolean;
}

export type Metadata = {
  id: string;
  name: string;
  contactType: number;
};

export type MetadataValues = Record<string, string>;

export type SessionNoteMetadata = {
  provider: MetadataValues;
  learner: MetadataValues;
};

export type MetadataDefaultValue = `{{${"Learner" | "Provider"}Metadata.[${number}]}}`;

export type FieldSelector = DefaultFieldSelector & {
  isMetadata: boolean;
  defaultValue: string | MetadataDefaultValue;
  sort: number;
};

export type SessionNoteSecondaryAndTertiaryInsurance = {
  secondaryInsuranceId: number;
  tertiaryInsuranceId: number;
};

export enum NoteTemplateOptionEnum {
  Optional = 0,
  Required = 1,
  AtLeastOneRequired = 2
}

export const dataSummaryConfigs: Array<keyof SessionSummaryConfiguration> = [
  "branchName",
  "currentScore",
  "currentPhase",
  "masteryCriteria",
  "goalStatus",
  "branchNarrative"
];

export const additionalOptionsConfigs: Array<keyof SessionSummaryConfiguration> = ["cariAiNarrative", "comments"];

export const configDescription: { [key in keyof SessionSummaryConfiguration]: string } = {
  branchName: "Branch name",
  currentScore: "Current score",
  currentPhase: "Current phase",
  masteryCriteria: "Mastery criteria",
  goalStatus: "Goal status",
  branchNarrative: "Branch narrative",
  cariAiNarrative: "Include cari™ generated narrative on CR Mobile",
  comments: "Include comments"
};
