import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import transport from "app/util/transport";
import { JSV } from "third_party_libraries/jsv/jsv";
import elasticLookupMapper from "app/util/elasticLookupMapper";
import router from "app/util/router";
import CrSingleSelect from "./crSingleSelect";
import ApiTransport from "app/util/apiTransport";
import { cleanHtml } from "app/util/htmlHelpers";

const api = new ApiTransport();
const searchHistoryLimit = 10;

@observer
class SearchAreaContent extends Component {
  render() {
    let {
      searchResultsMap,
      helpInfo: { searchProps },
      onClear,
      hideClearHistory
    } = this.props;

    return (
      <div className="select2-result-label select2-searchBy" id="tab_a" style={{ cursor: "default" }}>
        <small className="block body-bg padding-md-left padding-md-right padding-xs-top" style={{ paddingBottom: "6px" }}>
          <span className="margin-xs-right text-muted">Search options: </span>
          {searchProps.map(prop => {
            let item = searchResultsMap[prop];
            if (!item) {
              try {
                console.error("Can't find key", prop);
              } catch (err) {}
              return null;
            }
            return (
              <b key={prop} className="padding-md-left padding-md-right inline-block">
                {item.ui}
              </b>
            );
          })}
        </small>
        {!hideClearHistory ? (
          <small className="block txt-bold text-muted body-bg padding-md-left padding-md-right" style={{ paddingBottom: "6px" }}>
            SEARCH HISTORY
            <a className="padding-md-left txt-normal" onClick={onClear}>
              Clear
            </a>
          </small>
        ) : null}
      </div>
    );
  }
}

@observer
export default class AdvancedSearch extends Component {
  @observable.ref results = null;
  @observable noResults = false;
  @observable clean = false;
  @observable searchTerm = "";
  @observable searching = false;
  @observable searchOpen = false;
  @observable.ref searchHistory = []; //will NOT react to push or unshift - but that's ok here. Want to disable auto-observable conversion

  __queryTimeout = null;
  __uuid = 0;

  @computed
  get historyItems() {
    let { searchResultsMap } = this.props;

    return this.searchHistory.length
      ? this.searchHistory
          .map((item, i) => {
            let searchMapItem = searchResultsMap[item.type];
            return searchMapItem ? item : null;
          })
          .filter(n => n)
      : [];
  }

  @computed
  get adjustedHistoryItems() {
    let { searchResultsMap, searchHistoryFilter } = this.props;

    return this.searchHistory.length
      ? this.searchHistory
          // If there's a searchHistoryFilter, apply it. This was added for Contacts, PRAC-348, in order to hide
          // contact labels that had been deleted.
          .filter(searchHistoryFilter ? searchHistoryFilter : () => true)
          .map((item, i) => {
            let searchMapItem = searchResultsMap[item.type];
            return item
              ? {
                  innerResult: item,
                  key: "history" + i,
                  fromHistory: true,
                  render: () => (
                    <div className="select2-result-label">
                      <b>
                        <i className="fa fa-history fa-fw" />&nbsp;{searchMapItem.ui}:
                      </b>
                      <span> {item.name}</span>
                    </div>
                  )
                }
              : null;
          })
          .filter(n => n)
      : [];
  }

  componentDidMount() {
    let { helpInfo } = this.props;

    if (helpInfo && helpInfo.searchHistoryKey) {
      api.get("search/history", { section: helpInfo.searchHistoryKey }).then(resp => {
        if (resp.searchHistoryJSON) {
          try {
            let arr = JSON.parse(resp.searchHistoryJSON);
            if (Array.isArray(arr)) {
              this.searchHistory = arr;
            }
          } catch (err) {}
        }
      });
    }
  }

  @computed
  get searchingContent() {
    if (!this.props.suppressText) return null;

    let { minSearchLength = 3 } = this.props;
    if (this.searching && !this.props.suppressSearchingContent) {
      return (
        <div className="select2-result-label">
          <i className="fa fa-fw fa-spin fa-spinner" /> Searching...
        </div>
      );
    } else if (this.noResults && !this.props.suppressSearchingContent) {
      return (
        <div className="select2-result-label">
          <i className="fa fa-fw fa-ban" /> No Results
        </div>
      );
    } else {
      let delta = minSearchLength - this.searchTerm.length;
      return delta > 0 && delta < 3 ? (
        <div className="select2-result-label">{`${delta} more character${delta === 1 ? "" : "s"}...`}</div>
      ) : !this.clean && delta < 3 && !this.props.suppressSearchingContent ? (
        <div className="select2-result-label">Search for {this.searchTerm}...</div>
      ) : null;
    }
  }

  @action
  searchInputClicked = () => {
    if (!this.searchOpen) {
      this.__uuid++;
      this.searchOpen = true;
      this.noResults = false;
      this.clean = true;
    } else {
      //this.close();
    }
  };

  @action
  getAdvSearchPacket = type => {
    var result = this.props.searchResultsMap[type];
    if (result) return result;

    for (var key in this.props.searchResultsMap) {
      if (key.toLowerCase() === type.toString().toLowerCase()) {
        return this.props.searchResultsMap[key];
      }
    }
  };

  @action
  formatAdvSearchResultOption = o => {
    var packet = this.getAdvSearchPacket(o.type);
    if (!packet) return o.name;
    return typeof packet.option === "function" ? packet.option(o) : o.name;
  };
  @action
  selectItem = obj => {
    if (obj.fromHistory) {
      this.searchHistory = [obj.innerResult, ...this.searchHistory.filter(item => item != obj.innerResult)].filter(
        (x, index) => index <= searchHistoryLimit
      );
    } else if (this.props.helpInfo && this.props.helpInfo.searchHistoryKey) {
      let searchItemKey = Object.keys(this.props.searchResultsMap).find(k => k.toLowerCase() == obj.type.toLowerCase());
      if (searchItemKey) {
        obj.type = searchItemKey;
        this.searchHistory = [obj, ...this.searchHistory.filter(item => !(item.type == obj.type && item.id == obj.id))].filter(
          (x, index) => index <= searchHistoryLimit - 1
        );
      }
    }
    this.props.itemSelected(obj.innerResult || obj);
    this.syncSavedHistory();
    this.close();
  };
  clearSearchHistory = () => {
    this.searchHistory = [];
    this.syncSavedHistory();
  };
  syncSavedHistory = () => {
    let { helpInfo } = this.props;

    if (helpInfo && helpInfo.searchHistoryKey) {
      try {
        let json = JSON.stringify(this.searchHistory);
        if (json) {
          api.post("search/history", null, { section: helpInfo.searchHistoryKey, json }).then(resp => {});
        }
      } catch (err) {}
    }
  };
  @action
  close = () => {
    this.__uuid++;
    this.searchOpen = false;
    this.searchTerm = "";
    this.results = null;
  };

  @action
  getTextAndStaticSearchItems = () => {
    let { suppressText } = this.props;

    if (!suppressText) {
      return [{ id: -1, name: this.searchTerm, type: this.searching ? "textActive" : "text" }, ...this.getStaticSearchItems()];
    } else {
      return this.getStaticSearchItems();
    }
  };

  @action
  setSearchTerm = term => {
    this.noResults = false;
    this.clean = false;

    let { suppressText, minSearchLength = 3, queryDelay = 500, useElastic = false } = this.props;

    this.searchTerm = term;
    this.searching = false;
    let longEnough = this.searchTerm.length >= minSearchLength;

    if (!this.searchTerm.length) {
      this.results = null;
    } else {
      // REL 11/20/18 CRDEV-220: This line had been commented out, its absence was causing a problem where user could quickly hit
      // enter after typing a search, and if the typed term hadn't yet shown up in the dropdown, whatever else (e.g. a prior search) is
      // first in that list would get searched. Currently belived uncommenting won't break anything else.
      this.results = this.getTextAndStaticSearchItems();
    }

    clearTimeout(this.__queryTimeout);
    if (longEnough) {
      let uuid = this.__uuid;
      this.__queryTimeout = setTimeout(
        action(() => {
          if (uuid != this.__uuid) {
            return; //stale search - we're done
          }
          this.searching = true;
          this.results = null; // this.getTextAndStaticSearchItems();

          if (elasticLookupMapper.hasElasticEquivalent(this.props.call)) {
            this.__runElasticSearch();
          } else {
            this.__runSearch();
          }
        }),
        queryDelay
      );
    }
  };

  addKeyToItem = item => (item._key = item.type + item.id);

  getStaticSearchItems = () => {
    let { staticSearchOptions } = this.props,
      regex = new RegExp(this.searchTerm, "i");

    return staticSearchOptions.filter(op => op.terms.some(t => regex.test(t))).map(op => ({ type: op.type, id: op.value, name: op.name }));
  };

  @action
  __processSearchResults(items, search, uuid) {
    if (this.__uuid !== uuid) {
      return; //stake search - ignore
    }
    let { suppressSearchingContent, suppressText, staticSearchOptions } = this.props;

    let validItems = items.map(shapeAdvSearchOption).filter(result => {
      let mapItem = this.getAdvSearchPacket(result.type);
      return mapItem && (!this.props.searchResultIsValid || this.props.searchResultIsValid(result));
    });

    let textResults = !suppressSearchingContent && !suppressText ? [{ id: -1, name: search, type: "text" }] : [],
      finalResults = [...textResults, ...this.getStaticSearchItems(), ...validItems];

    finalResults.forEach(this.addKeyToItem);
    this.results = finalResults;
    this.searching = false;
    if (!finalResults.length) {
      this.noResults = true;
    } else {
      this.clean = true;
    }
  }

  __runElasticSearch = () => {
    let uuid = this.__uuid,
      apiTier = elasticLookupMapper.mapMidTierToApi(this.props.call),
      lookupOptions = apiTier.source;

    let endpoint = `search/lookups?query=${encodeURIComponent(this.searchTerm)}&${lookupOptions}`;

    transport.api.get(endpoint).then(({ results }) => {
      results = results.map(({ key, resultType, displayName }) => ({ Id: key, Type: resultType, Name: displayName }));
      this.__processSearchResults(results, this.searchTerm, uuid);
    });
  };

  __runSearch = () => {
    let { call } = this.props,
      uuid = this.__uuid;

    if (typeof call === "string") {
      let [channel, action] = call.split("."),
        search = this.searchTerm;

      transport.transmitRequest(channel, action, { search }, ({ items }) => this.__processSearchResults(items, search, uuid));
    }
  };

  render() {
    let { placeholder, className, searchResultsMap, helpInfo, hideClearHistory, disabled = false } = this.props;
    let results = Array.isArray(this.results) ? [...this.results] : this.results;
    results = results && results.map((r, ix) => ({ ...r, _key: `${r._key}_${ix}` }));
    if (this.adjustedHistoryItems.length) {
      if (!results) {
        results = [];
      }
      results.push(...this.adjustedHistoryItems);
    }

    return (
      <CrSingleSelect
        searchTerm={this.searchTerm}
        searching={this.searching}
        setSearchTerm={this.setSearchTerm}
        inputClicked={this.searchInputClicked}
        outsideClick={this.close}
        searchOpen={this.searchOpen}
        results={results}
        resultKey={"_key"}
        selectItem={this.selectItem}
        placeholder={placeholder}
        className={className}
        formatResult={this.formatAdvSearchResultOption}
        selectedTerm={null}
        validationErrorMessage={null}
        disabled={disabled}
        searchAreaContents={[
          helpInfo ? (
            <SearchAreaContent searchResultsMap={searchResultsMap} helpInfo={helpInfo} onClear={this.clearSearchHistory} hideClearHistory={hideClearHistory} />
          ) : null,
          this.searchingContent
        ].filter(item => item)}
      />
    );
  }
}

AdvancedSearch.defaultProps = {
  staticSearchOptions: []
};

const shapeAdvSearchOption = o => ({ id: o.Id || o.id, name: cleanHtml(o.Name) || cleanHtml(o.name) || "", type: o.Type || o.type || "" });
