import { observable, action } from "mobx";
import { Component } from "react";

// @collapsible class ...
// @collapsible(true) class ...

export const collapsible = param => {
  function decorator(target, state) {
    return class extends target {
      @observable expanded = state;
      @action toggleExpanded = () => (this.expanded = !this.expanded);
    };
  }
  if (typeof param == "boolean") return target => decorator(target, param);
  return decorator(param, false);
};

@collapsible
export class CollapseState {
  constructor({ initiallyExpanded }) {
    this.expanded = initiallyExpanded;
  }
}

export const modalBacking = target => {
  return class extends target {
    @observable modalIsOpen = false;
    @action modalShow = () => (this.modalIsOpen = true);
    @action modalHide = () => (this.modalIsOpen = false);
  };
};
