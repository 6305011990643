import { DateRangeMode } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeMode";
import moment from "moment";

export const tasksDueDateRangeModes = [
    new DateRangeMode("today", "Today", () => ({
        startDate: moment(),
        endDate: moment()
    })),
    new DateRangeMode("yesterday", "Yesterday", () => ({
        startDate: moment().subtract(1, "day"),
        endDate: moment().subtract(1, "day")
    })),
    new DateRangeMode("last7Days", "Last 7 Days", () => ({
        startDate: moment().subtract(7, "day"),
        endDate: moment().add(-1, 'day')
    })),
    new DateRangeMode("last30Days", "Last 30 Days", () => ({
        startDate: moment().subtract(30, "day"),
        endDate: moment().add(-1, 'day')
    })),
    new DateRangeMode("thisMonth", "This Month", () => ({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month')
    })),
    new DateRangeMode("last365Days", "Last 365 Days", () => ({
        startDate: moment().subtract(365, "day"),
        endDate: moment()
    }))
];

export const creationDateRangeModes = [
    new DateRangeMode("today", "Today", () => ({
        startDate: moment(),
        endDate: moment()
    })),
    new DateRangeMode("yesterday", "Yesterday", () => ({
        startDate: moment().subtract(1, "day"),
        endDate: moment().subtract(1, "day")
    })),
    new DateRangeMode("last7Days", "Last 7 Days", () => ({
        startDate: moment().subtract(7, "day"),
        endDate: moment()
    })),
    new DateRangeMode("last30Days", "Last 30 Days", () => ({
        startDate: moment().subtract(30, "day"),
        endDate: moment()
    })),
    new DateRangeMode("last365Days", "Last 365 Days", () => ({
        startDate: moment().subtract(365, "day"),
        endDate: moment()
    }))
];

export const authLastVerifiedRangeModes = [
    new DateRangeMode("today", "Today", () => ({
        startDate: moment(),
        endDate: moment()
    })),
    new DateRangeMode("last7Days", "Last 7 Days", () => ({
        startDate: moment().subtract(7, "day"),
        endDate: moment()
    })),
    new DateRangeMode("last30Days", "Last 30 Days", () => ({
        startDate: moment().subtract(30, "day"),
        endDate: moment()
    })),
    new DateRangeMode("more7Days", "More than 7 days ago", () => ({
        startDate: null,
        endDate: moment().subtract(7, "day")
    })),
    new DateRangeMode("more30Days", "More than 30 days ago", () => ({
        startDate: null,
        endDate: moment().subtract(30, "day")
    })),
    new DateRangeMode("more6Months", "More than 6 months ago", () => ({
        startDate: null,
        endDate: moment().subtract(6, "month")
    })),
    new DateRangeMode("more1Year", "More than a year ago", () => ({
        startDate: null,
        endDate: moment().subtract(1, "year")
    })),
    new DateRangeMode("last365Days", "For a future day", () => ({
        startDate: moment().add(1, "day"),
        endDate: null
    }))
];