import React from "react";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import GlobalStyle from "../globalStyle";
import { FilesExplorer } from "shared/fileupload/components/FilesExplorer";

const MuiFilesExplorer = (props: any) => (
  <>
    <GlobalStyle styles=".cr-mui-wrapper-typography > * { display: inline-block; }" />
    <span className="cr-mui-wrapper-typography">
      <ThemeProvider skipGlobalStyles>
          <FilesExplorer {...props} />
      </ThemeProvider>
    </span>
  </>
);

export const knockoutName = "cr-mui-files-explorer";

export default MuiFilesExplorer;
