import {action, computed, IObservableArray, observable} from "mobx";
import { emailAddress, required, validated, Validated } from "app/components/forms/validation";
import domUtil from "app/util/domUtil";
import ApiTransport from "app/util/apiTransport";
import * as Dto from "app/util/api.dto";
import { NoteTemplateOptionEnum, SessionNoteTemplate, SessionNoteTemplateAssignment } from "sessionNotes/models";

const api = new ApiTransport();

export enum NoteType {
  Template = 0,
  Session = 1
}

export interface Section {
  id: NoteTemplateOptionEnum;
  name: string;
  note: string;
}

export interface Note {
  id?: number;
  templateId: string | number;
  name: string;
  type: NoteType;
  section: NoteTemplateOptionEnum;
}

export class NoteTemplatesAssociationsStore {

  @observable searchKey = '';
  @observable itemToAdd = {
    template: null,
    section: NoteTemplateOptionEnum.Optional
  };
  @observable notes: Note[] = [];
  @observable noteTemplates = [];
  @observable sessionNotesTemplates: SessionNoteTemplate[] = [];
  @observable loadingSearchResults = false;
  @observable loading = false;
  @observable isSnackBarOpen = false;
  removedSessionNotesTemplates: Note[] = [];

  clearSnackBar = () => {
    this.isSnackBarOpen = false;
  };
  
  load(
    assignedNoteTemplates,
    assignedSessionNotesTemplates: SessionNoteTemplateAssignment[] = [],
    sessionNotesTemplates: SessionNoteTemplate[] = []
  )  {
    this.loading = true;
    this.notes = [
      ...assignedNoteTemplates.map(i => ({
        id: i.id,
        templateId: i.templateId,
        name: i.name,
        type: NoteType.Template,
        section: i.optional
      })),
      ...assignedSessionNotesTemplates.map(i => ({
        templateId: i.templateId,
        name: i.name,
        type: NoteType.Session,
        section: i.optional
      }))
    ];
    this.sessionNotesTemplates = sessionNotesTemplates;
    this.removedSessionNotesTemplates = [];
    this.loading = false;
  }

  sections: Section[] = [
    { id: NoteTemplateOptionEnum.Required, name: 'Required', note: 'All note templates in this section must be completed for this service code.' },
    { id: NoteTemplateOptionEnum.AtLeastOneRequired, name: 'At least one required', note: 'At least one of the note templates in this section must be completed for this service code.' },
    { id: NoteTemplateOptionEnum.Optional, name: 'Optional', note: 'All note templates in this section are optional for this service code.' }
  ];

  loadSearchableNotes(key: string)   {
    this.searchKey = key;
    this.loadingSearchResults = true;
    api.get(`/notes/templates/list?search=${key}&showBillingEntryTemplates=true`)
      .then((response) => {
        this.noteTemplates = response.items;
        this.loadingSearchResults = false;
      });
  }
  
  clearSearch() {
    this.searchKey = '';
    this.noteTemplates = [];
  }

  @computed
  get searchResults() {
    const value = (v) => {
      return v?.name ? v.name.toUpperCase() : '';
    };
    return [
      ...this.noteTemplates.map(i => ({
        id: i.id,
        name: i.name,
        type: NoteType.Template
      })).sort((a, b) => value(a) < value(b) ? -1 : value(a) > value(b) ? 1 : 0),
      ...(this.searchKey ? this.sessionNotesTemplates.filter(i => i.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1).map(i => ({
        id: i.templateId,
        name: i.name,
        type: NoteType.Session
      })) : []).sort((a, b) => value(a) < value(b) ? -1 : value(a) > value(b) ? 1 : 0)
    ];
  }

  @computed
  get canAddTemplate()  {
    return this.itemToAdd.template;
  }
  
  getItems(section: NoteTemplateOptionEnum)    {
    const value = (v) => {
      return v?.name ? v.name.toUpperCase() : '';
    };
    return this.notes.filter(n => n.section === section).sort((a, b) => value(a) < value(b) ? -1 : value(a) > value(b) ? 1 : 0);
  }

  remove(templateId)  {
    const note = this.notes.find(n => n.templateId === templateId);
    if (note.type === NoteType.Session) {
      this.removedSessionNotesTemplates.push(note);
    }
    this.notes = this.notes.filter(n => n.templateId !== templateId);
  }
  

  add()  {
    if (!this.itemToAdd.template) return;
    if (this.notes.find(n => n.templateId === this.itemToAdd.template.id)) return;
    this.notes = [
      ...this.notes,
      {
        templateId: this.itemToAdd.template.id,
        name: this.itemToAdd.template.name,
        type: this.itemToAdd.template.type,
        section: this.itemToAdd.section
      }
    ];
    this.itemToAdd = {
      template: null,
      section: NoteTemplateOptionEnum.Optional
    };
    this.isSnackBarOpen = true;
    this.clearSearch();
  }

  update(templateId, section: NoteTemplateOptionEnum)  {
    this.notes = this.notes.map(n => {
      if (n.templateId === templateId) n.section = section;
      return n;
    });
  }

}