import { observable, computed, action } from "mobx";
import { collapsible, ICollapsible } from "app/util/bootstrapHelpers/decorators";

export interface NestedLabelLike {
  id: number;
  parent: number;
  isPublic: boolean;
  name: string;
  color: string;
  backgroundColor: string;

  // Currently this is always expected to be false, but adding it in case at some future point the browser may have both
  // and it matters to store consumers. Specifically, PRAC-348 checks isDeleted, so this way no need to modify that code
  // should we ever pull down deleted labels (e.g. legacy views, etc. that need to display friendly label names instead of "deleted" or raw id)
  isDeleted: boolean;
}

@collapsible
class NestedLabel implements NestedLabelLike {
  get collapsible() {
    return (this as any) as ICollapsible;
  }

  id: number = null;
  isPublic = false;

  // See note above in interface declaration.
  @observable isDeleted = false;

  @observable name = "";
  @observable color = "";
  @observable backgroundColor = "";
  @observable _children = observable.array<NestedLabel>([]);
  @observable parent = null;

  @observable depth = 0;
  @computed
  get children() {
    return this._children.sort(labelSortCompare);
  }
  constructor(nestedLabelLike?: NestedLabelLike) {
    if (!nestedLabelLike) {
      return;
    }
    this.id = nestedLabelLike.id;
    this.parent = nestedLabelLike.parent;
    this.isPublic = nestedLabelLike.isPublic;
    this.name = nestedLabelLike.name;
    this.color = nestedLabelLike.color;
    this.backgroundColor = nestedLabelLike.backgroundColor;
  }
}

export const labelSortCompare = ({ name: name1 }, { name: name2 }) => {
  const name1After = name1.toLowerCase() > name2.toLowerCase(),
    bothEqual = name1.toLowerCase() === name2.toLowerCase();
  return bothEqual ? 0 : name1After ? 1 : -1;
};

export default NestedLabel;
