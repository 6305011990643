import { observable, action, computed, IObservableArray } from "mobx";
import ApiTransport from "app/util/apiTransport";
import scanFile from "app/util/virusScan";
import filesize from "third_party_libraries/filesize/filesize.min";
import ExistingResource from "./existingResource";
import FilePreview, { globalFilePreview } from "./filePreview";
import { Moment } from "moment";

const apiTransport = new ApiTransport();

export type FileUploadStoreConstructor = new (file: FileInfo, settings?: FileUploadSettings) => FileUploadStoreInterface;

export interface FileUploadStoreInterface {}

// just stubbing these interfaces out based on what is used here
// please feel free to expand them as needed--I am not aware of what all else might be used..
export interface FileUploadSettings {
  channelId?: string;
  action?: string;
  uploadUrl?: string;
  scanUrl?: string;
  postUrl?: string;
  maxFiles?: number;
  fileUploadStoreFactory?: FileUploadStoreConstructor;
  metaData?: any;
}

export interface FileInfo {
  id: number;
  lastModifiedDate: string | Moment;
  name: string;
  type: string;
  size: number;
}

class FileUploadStore implements FileUploadStoreInterface {
  @observable __uploadAttempted: boolean = false;
  settings: FileUploadSettings;
  xhr: XMLHttpRequest = null;
  @observable files: IObservableArray<any> = observable([]);
  @observable id: number = 0;
  @observable file: FileInfo;
  @observable lastModifiedDate: string | Moment = null;
  @observable name: string = "";
  @observable type: string = "";
  @observable size: number = 0;
  @observable loading: boolean = false;
  @observable completed: boolean = false;
  @observable loadingPreview: boolean = false;
  @observable status: string = "";
  @observable errorCode: string = "";
  @observable loaded: number = 0;
  @observable total: number = 0;
  @observable bitrate: number = 0;
  @observable visibleToClient: boolean = false;

  constructor(file: FileInfo, settings: FileUploadSettings = {}) {
    if (file) {
      this.file = file;
      this.name = file.name;
      this.type = file.type;
      this.lastModifiedDate = file.lastModifiedDate;
      this.size = file.size;
    }
    this.settings = { ...settings };
    this.settings.metaData = this.settings.metaData || {};
  }

  @computed
  get previewShow() {
    return FilePreview.isSupportedType(this.name);
  }
  @computed
  get requiresUpload() {
    return !this.__uploadAttempted;
  }
  @computed
  get currentValidFiles() {
    return this.files.filter(f => f.id).map(f => f.id);
  }

  @computed
  get isUploadSuccess() {
    return this.status === "completed";
  }

  @computed
  get isUploadError() {
    return !!this.status && !this.isUploadSuccess;
  }

  @computed
  get isUploadFinished() {
    return this.isUploadError || this.isUploadSuccess;
  }

  @computed
  get invalid() {
    return this.status == "rejected" || this.status == "aborted";
  }

  @action preview = () => globalFilePreview.preview(this);

  @computed
  get iconClass() {
    if (this.type == "application/pdf") {
      return "fa-file-pdf";
    }

    if (/^image\//.test(this.type)) {
      return "fa-file-image";
    }

    if (/^video\//.test(this.type)) {
      return "fa-file-video";
    }

    return "fa-file";
  }

  @computed
  get humanSize() {
    return filesize(this.size);
  }

  @computed
  get progressPercent() {
    return ((this.loaded / this.total || 0) * 100).toFixed(0) + "%";
  }

  @action
  onProgress(event) {
    this.loaded = event.loaded;
    this.total = event.total;
  }

  @action
  onDone(resp) {
    this.loading = false;
    this.__uploadAttempted = true;
    if (!this.status) {
      this.status = "completed";
      this.file = null;
    }
    return this;
  }

  @action
  reset() {
    if (this.xhr) {
      this.xhr.abort();
      this.xhr = null;
    }
    this.file = null;
    this.status = "";
  }

  @action
  async onUploadComplete(resp, params, postUploadContext) {
    this.xhr = null;

    await scanFile(resp.resourceId, this.settings.scanUrl);

    if (this.settings.postUrl) {
      await apiTransport.post(this.settings.postUrl, null, { resourceId: resp.resourceId, metadata: postUploadContext });
    }
  }

  @action
  onUploadError(e, resp, params) {
    if (e && e.message && e.message.toLowerCase().indexOf("virus") !== -1) {
      this.status = "rejected";
      this.errorCode = "500";
    } else {
      this.reset();
      this.status = "aborted";
    }
  }

  @action
  onMetadataGetComplete(resp, params, postUploadContext) {
    let { url, formData } = resp;

    if (resp.metadata) {
      this.id = resp.metadata.resourceId;
    }
    let data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    data.append("file", this.file as any);

    let request: JQueryAjaxSettings = {
      type: "POST",
      xhr: () => {
        let xhr = $.ajaxSettings.xhr();
        if (xhr.upload) {
          xhr.upload.onprogress = event => this.onProgress(event);
        }
        this.xhr = xhr;
        return xhr;
      },
      url,
      data,
      processData: false,
      contentType: false
    };

    return Promise.resolve($.ajax(request))
      .then(() => this.onUploadComplete(resp, params, postUploadContext))
      .catch(e => this.onUploadError(e, resp, params));
  }

  @action
  onMetadataGetError(params, { data = {} }: any) {
    this.status = "rejected";
    this.errorCode = data.rejectErrorCode;
  }

  @action
  save(params) {
    let request = {
      fileName: this.name,
      contentLength: this.size,
      contentType: this.type,
      Metadata: {
        channelId: this.settings.channelId,
        action: this.settings.action,
        typeId: 6,
        contactId: 0,
        isPrivate: false,
        preventSeek: false,
        isMedicalRecord: false,
        ...this.settings.metaData
      }
    };

    this.loading = true;
    this.completed = false;

    return apiTransport
      .post(this.settings.uploadUrl, null, request)
      .then(resp => this.onMetadataGetComplete(resp, params, request.Metadata))
      .catch(error => this.onMetadataGetError(params, error))
      .then(resp => this.onDone(resp));
  }

  @action
  saveWithMetadata(params, metadata: any = {}) {
    this.settings.metaData = metadata;
    if (metadata.hasOwnProperty("fileName") && metadata.fileName.length > 0) {
      let extension = this.name.split(".").pop();
      this.name = `${metadata.fileName}.${extension}`;
    }
    return this.save(params);
  }
}

class FileUploadMultiStore {
  @observable saving: boolean = false;
  @observable saved: boolean = false;
  @observable files: IObservableArray<FileUploadStore> = observable([]);
  @observable maxFiles = 20;
  settings: FileUploadSettings;

  @computed
  get isDirty() {
    return this.files.some(f => f.requiresUpload);
  }
  @computed
  get validFiles() {
    return this.files.filter(f => !f.invalid && f.id);
  }
  @computed
  get validFileIds() {
    return this.validFiles.map(f => f.id);
  }
  @computed
  get invalidFileCount() {
    return this.files.filter(f => f.invalid).length;
  }
  @computed
  get anyInvalid() {
    return !!this.invalidFileCount;
  }

  @computed
  get anyValid() {
    return this.validFiles.length > 0;
  }

  @computed
  get enabled() {
    return this.files.length < this.maxFiles;
  }

  @computed
  get anyFailures() {
    return this.saved && this.files.some(file => !file.isUploadSuccess);
  }

  @computed
  get anySuccess() {
    return this.saved && this.files.some(file => file.isUploadSuccess);
  }

  constructor(settings: FileUploadSettings = {}) {
    this.maxFiles = settings.maxFiles === undefined ? this.maxFiles : settings.maxFiles;
    this.settings = Object.assign(
      {
        channelId: "resources",
        action: "upload",
        uploadUrl: "upload/prepare/resources/upload",
        scanUrl: "resources/scan/resources/upload"
      },
      settings
    );
  }

  @action
  searchResources = search => {
    return apiTransport.get(`search/lookups?query=${encodeURIComponent(search)}&lookupOptions={LookupType:Resource}`).then(resp => {
      // for backwards compatibility with the default crSelect Id/Name setting
      // we'll transform the resuts array and change their properties to Id/Name from id/displayName
      let resources = resp.results.map(o => ({ Id: o.id, Name: o.displayName }));

      resp.resources = resources;
      return resp;
    });
  };

  @action manuallyAddResource = (resource) => {
    const file = ExistingResource.createFromResponse(resource);
    file.data = resource;
    this.files.push(file);
  }

  @action
  reset() {
    this.saving = false;
    this.saved = false;
    this.files.forEach(file => file.reset());
    this.files.replace([]);
  }

  @action
  onDrop(newFiles) {
    let currentCount = this.files.length,
      remaining = this.maxFiles - currentCount;

    if (remaining < newFiles.length) {
      let overage = newFiles.length - remaining;

      cr.domUtil.displayMessage(
        `The file upload limit is ${this.maxFiles}, and this selection would put you over that limit by ${overage}. Please either remove some files, or choose fewer.`,
        { isError: true }
      );
      return;
    }

    let { fileUploadStoreFactory = FileUploadStore } = this.settings;
    this.files.push(...newFiles.slice(0, this.maxFiles - this.files.length).map(file => new fileUploadStoreFactory(file, this.settings)));
  }

  @action
  onDelete(file) {
    this.files.remove(file);
  }

  @action
  onDeleteFromIndex(index: number) {
    this.files = observable(this.files.filter((f, i) => i !== index));
  }

  @action purgeFailures = () => this.files.replace(this.files.filter(file => !file.invalid));

  @action
  save(params?, options = { purgeFailures: false }) {
    this.saving = true;

    if (options.purgeFailures) {
      this.purgeFailures();
    }
    return Promise.all(this.files.filter(file => file.requiresUpload).map(file => file.save(params))).then(
      action("onFileUploadMultiStoreSave", resp => {
        this.saving = false;
        this.saved = true;
        return resp;
      })
    );
  }

  @action
  saveWithMetadata(params, metadata, options = { purgeFailures: false }) {
    this.saving = true;

    if (options.purgeFailures) {
      this.purgeFailures();
    }
    return Promise.all(this.files.filter(file => file.requiresUpload).map(file => file.saveWithMetadata(params, metadata))).then(
      action("onFileUploadMultiStoreSave", resp => {
        this.saving = false;
        this.saved = true;
        return resp;
      })
    );
  }

  @action
  saveWithMetadataByIndex(index, params, metadata) {
    this.saving = true;
    return Promise.all(this.files.filter((file, i) => file.requiresUpload && index === i).map(file => file.saveWithMetadata(params, metadata))).then(
        action("onFileUploadMultiStoreSave", resp => {
          this.saving = false;
          this.saved = true;
          return resp;
        })
    );
  }

  @action
  onVisibleToClientChange = (index, value) => {
    this.files = observable(this.files.map((f, i) => {
      if (index === i) f.visibleToClient = value;
      return f;
    }));
  }
}

export { FileUploadStore, FileUploadMultiStore };
