import React, { Component } from "react";
import { observer } from "mobx-react";

//!!!!NOTE!!!!

//Please make sure this component is destroyed and re-rendered for each new video played.
//This is so fresh event listeners will be registered on a fresh <video> tag each time

//No time at the moment to remove this restriction

@observer
export default class VideoPlayer extends Component {
  componentDidMount() {
    this.props.store.init(this.videoPlayer);
  }
  render() {
    let { store, ...rest } = this.props;

    return (
      <video ref={el => (this.videoPlayer = el)} {...rest}>
        <source src={store.url} type="video/mp4" />
      </video>
    );
  }
}
