import React, { Component } from "react";
import hashUtility from "app/util/hashUtility";
import { chain } from "app/util/functionalHelpers";

export default class HashChangeButton extends Component {
  render() {
    let { toMerge, toKeep, children, onClick = () => {}, ...rest } = this.props;

    const handler = () => {
      let hashInfo = hashUtility.getCurrentHashInfo();
      Object.assign(hashInfo.parameters, toMerge);
      if (Array.isArray(toKeep)) Object.keys(hashInfo.parameters).forEach(key => toKeep.indexOf(key) == -1 && delete hashInfo.parameters[key]);
      hashUtility.setHash(hashInfo);
    };

    return (
      <button onClick={chain(handler, onClick)} {...rest}>
        {children}
      </button>
    );
  }
}

export const createHashButtonWithDefaults = defaults => props => <HashChangeButton {...props} toMerge={{ ...defaults, ...props.toMerge }} />;

export const PageResettingHashButton = createHashButtonWithDefaults({ page: null });
