import React, { Component, EventHandler, SyntheticEvent } from "react";
import ReactDOM from "react-dom";
import { observable, computed, action, IObservableArray } from "mobx";
import { observer } from "mobx-react";
import cn from "classnames";
import ApiTransport from "app/util/apiTransport";
import { rewriteUrlToEksApi } from "app/util/eks";
import InfiniteScroll from "third_party_libraries/react-infinite-scroller/InfiniteScroll";
import Loading from "app/components/util/loading";
import Measure, { Dimensions } from "react-measure";
import { Resource as IResource, ResourceConversionResult, ResourcePreviewResponse } from "app/util/api.dto";
import { CRConfirm, CRRichConfirm, DialogProps, dialog } from "app/components/util/crConfirm";
import Resource from "resources/list/models/resource";
import domUtil from "app/util/domUtil";

const api = new ApiTransport();

interface ResourcePreviewProps {
  store: ResourcePreviewStore;
}

interface ResourcePreviewPageProps {
  store: ResourcePreviewStore;
  page: number;
  image: ImageData;
}

@observer
class ResourcePreviewPage extends Component<ResourcePreviewPageProps, {}> {
  render() {
    const { store, page, image } = this.props;
    const { url, loaded, height, width } = image;
    const { resource } = store;
    const { previewPageCount } = resource;
    const paddingBottom = height && width ? `calc(${height} / ${width} * 100%)` : "800px";

    return (
      <div className={cn("preview-page", { "preview-page-no-dimensions": !(height && width) })} key={page}>
        <img
          className="preview-image"
          src={url}
          style={
            loaded
              ? {}
              : {
                  width: `${width}px`,
                  height: "0px",
                  paddingBottom
                }
          }
          onLoad={action<EventHandler<SyntheticEvent<HTMLImageElement>>>(e =>
            Object.assign(image, { loaded: true, height: e.currentTarget.naturalHeight.toString(), width: e.currentTarget.naturalWidth.toString() })
          )}
        />
      </div>
    );
  }
}

@observer
class ResourcePreviewConversionStatus extends Component<ResourcePreviewProps & DialogProps<void>, never> {
  render() {
    const { store, dismiss } = this.props;
    const { resource } = store;
    const {
      isConvertible,
      previewConversionComplete,
      previewConversionInProgress,
      previewConversionErrorCode,
      previewConversionErrorMessage,
      previewConversionErrorSource,
      previewPageCount
    } = resource;

    if (!isConvertible) {
      return null;
    }

    if (previewConversionInProgress) {
      return (
        <div className="height100vh flex align-items-center justify-content-center">
          <div className="width-120 text-center margin-md-top margin-auto">
            <div className="bubbles">
              <span />
              <span id="bubble2" />
              <span id="bubble3" />
            </div>
            <div className="txt-muted-more margin-md-top txt-lg">
              <b>Converting</b>
            </div>
          </div>
        </div>
      );
    }

    if (previewConversionErrorSource) {
      return (
        <div className="height100vh flex align-items-center justify-content-center">
          <div className="width-120 text-center margin-md-top margin-auto">
            <div className="txt-muted-more margin-md-top txt-lg">
              <b>Resource Conversion Failed</b>
            </div>
          </div>
        </div>
      );
    }

    if (!previewPageCount) {
      return (
        <div className="height100vh flex align-items-center justify-content-center">
          <div className="width-120 text-center margin-md-top margin-auto">
            <div className="txt-muted-more margin-md-top txt-lg">
              <b>No pages</b>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

@observer
class ResourceImagePreview extends Component<ResourcePreviewProps & DialogProps<void>, never> {
  render() {
    const { store } = this.props;
    const { loading, loadMore, hasMore, resource } = store;
    const { previewPageCount } = resource;

    return (
      <>
        <ResourcePreviewConversionStatus store={store} resource={resource} />
        {previewPageCount ? (
          <InfiniteScroll {...{ loadMore, hasMore }} initialLoad={true} pageStart={0} useWindow={false} className="preview-infinite-loader">
            {store.images.map((image, page) => (
              <ResourcePreviewPage key={page} {...{ store, image, page }} />
            ))}
            {store.images.map((image, page) => (image.loaded ? null : <div key={`${page}-loading`} />))}
            {loading ? (
              <div className="height100vh flex align-items-center justify-content-center">
                <div className="width-120 text-center margin-md-top margin-auto">
                  <div className="bubbles">
                    <span />
                    <span id="bubble2" />
                    <span id="bubble3" />
                  </div>
                  <div className="txt-muted-more margin-md-top txt-lg">
                    <b>Loading</b>
                  </div>
                </div>
              </div>
            ) : null}
          </InfiniteScroll>
        ) : null}
      </>
    );
  }
}

@observer
class ResourceVideoPreview extends Component<ResourcePreviewProps & DialogProps<void>, never> {
  render() {
    const { store } = this.props;
    const { loading, loadMore, hasMore, resource } = store;
    const { id, name = "&nbsp;", isConvertible, previewConversionComplete, previewConversionInProgress, previewPageCount } = resource;

    return (
      <video
        className="preview-video"
        src={rewriteUrlToEksApi(`${app_apiurl}/resources/converted/${id}`)}
        controls={true}
        controlsList="nodownload"
        preload="auto"
        autoPlay={true}
        onContextMenu={event => event.preventDefault()}
      />
    );
  }
}

@observer
class ResourcePreview extends Component<ResourcePreviewProps & DialogProps<void>, never> {
  render() {
    const { store, dismiss } = this.props;
    const { resource, loading, loadMore, hasMore } = store;
    const { name = "&nbsp;", isConvertible, isImagePreview, isVideoPreview } = resource;

    return (
      <div className="modal fade padding in" role="dialog" aria-hidden="false" style={{ display: "block" }}>
        <div className="modal-dialog no-margin flex drop-shadow" style={{ width: "100%", height: "100%", flexDirection: "column" }}>
          <div className="modal-header text-center padding">
            <a type="button" className="pull-right txt-muted-more txt-16" onClick={dismiss}>
              <i className="far fa-fw fa-times" />
            </a>
            <h2 className="modal-title txt-light">{name}</h2>
          </div>
          <div className="modal-body no-padding preview-modal">
            {isImagePreview ? <ResourceImagePreview store={store} /> : null}
            {isVideoPreview ? <ResourceVideoPreview store={store} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

interface ImageData {
  url: string;
  loaded: boolean;
  height?: string;
  width?: string;
}

class ResourcePreviewStore {
  resource: Resource = null;
  @observable loading = false;
  @observable.ref images = observable.array<ImageData>([]);

  @computed
  get hasMore() {
    return !this.loading && this.resource.previewPageCount > this.images.length;
  }

  constructor(resource: Resource) {
    this.resource = resource;
    if (resource.isConvertible) {
      this.initConvertible(resource);
    }
    if (resource.isImage) {
      this.initImage(resource);
    }
  }

  initConvertible(resource: Resource) {
    this.loading = false;
  }

  initImage(resource: Resource) {
    this.resource.previewPageCount = 1;
    this.images.push({
      url: rewriteUrlToEksApi(`${app_apiurl}/resources/${resource.id}`),
      loaded: true
    });
  }

  @action
  loadMore = async () => {
    const page = this.images.length;
    this.loading = true;

    const resourcePreview = await api.get<ResourcePreviewResponse>(`resources/preview/url/${this.resource.id}/${page}`);
    const imageData = await $.ajax({ url: resourcePreview.headUrl, method: "HEAD", async: true }).then<ImageData>((data, textStatus, xhr) => ({
      url: resourcePreview.getUrl,
      loaded: false,
      height: xhr.getResponseHeader("x-amz-meta-height"),
      width: xhr.getResponseHeader("x-amz-meta-width")
    }));

    this.images.push(imageData);

    this.loading = false;
  };
}

export const promptForConversion = async (resource: Resource) => {
  let contents: React.ReactNode = null;
  if (!resource.failedConversion) {
    return null;
  }
  if (resource.previewConversionErrorSource) {
    contents = (
      <>
        Our previous attempt to generate a preview for this file resulted in the following error:
        <ul>
          <li>
            <b>Source</b>: {resource.previewConversionErrorSource}
          </li>
          <li>
            <b>Code</b>: {resource.previewConversionErrorCode}
          </li>
          <li>
            <b>Message</b>: {resource.previewConversionErrorMessage}
          </li>
        </ul>
        Would you like to attempt generating a preview again?
      </>
    );
  } else if (!resource.previewPageCount) {
    contents = (
      <>
        Our previous attempt to generate a preview for this file was unsuccessful.
        <br />
        <br />
        Would you like to attempt generating a preview again?
      </>
    );
  }

  return new Promise(resolve =>
    CRRichConfirm({
      caption: "Preview Generation",
      resolve,
      contents,
      buttons: [
        { buttonContent: "Generate", className: "btn btn-primary yes-button pull-left", buttonValue: true, success: true },
        { buttonContent: "Cancel", className: "btn btn-default no-button", buttonValue: false, success: false }
      ]
    })
  );
};

const previewResource = (resource: Resource) => dialog(<ResourcePreview store={new ResourcePreviewStore(resource)} resource={resource} />);

const convertResource = action(async (resource: Resource) => {
  if (!resource.isConvertible) {
    return null;
  }
  if (resource.previewConversionComplete && resource.previewPageCount > 0) {
    return null;
  }
  resource.previewConversionInProgress = true;
  try {
    const previewConversionResult = await api.post<ResourceConversionResult>(`resources/${resource.id}/convert`, null, null);
    resource.previewConversionInProgress = false;
    resource.previewConversionComplete = true;
    resource.previewPageCount = previewConversionResult.convertedPageCount;
  } catch (resp) {
    resource.previewConversionInProgress = false;
    resource.previewConversionComplete = false;
    if (resp && resp.data) {
      const previewConversionResult: ResourceConversionResult = resp.data;
      resource.previewConversionErrorSource = previewConversionResult.errorSource;
      resource.previewConversionErrorCode = previewConversionResult.errorCode;
      resource.previewConversionErrorMessage = previewConversionResult.errorMessage;
    }
  }
});

export const preview = action(async (resource: Resource) => {
  await promptForConversion(resource);
  convertResource(resource);
  return previewResource(resource);
});
