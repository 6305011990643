import React from "react";
import { DateRangePickerInput } from "@cr/react-dates/dateRangePicker/components/input";
import { DateRangePickerProps } from "@cr/react-dates";
import { CustomDateCalendar, CustomPopoverProps } from "./CustomDateCalendar";
import { userDateRangePicker } from "./CustomUseDateRangePicker";
import { DateRangeMode } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeMode";

export interface CustomDateRangePickerProps extends Omit<DateRangePickerProps, "PopoverProps"> {
    customOptions?: DateRangeMode[];
    hideInputDate?: boolean;
    anchorEl?: HTMLElement | null;
    handlePopoverClose?: (event) => void;
    displayClearBtn?: boolean;
    customTextFieldShrink?: boolean;
    PopoverProps?: CustomPopoverProps;
}

export const CustomDatePicker = (props: CustomDateRangePickerProps) => {
    const {
        anchorEl,
        formattedDates,
        onTextInputDateChange,
        maskDateInput,
        textFieldValue,
        textFieldShrink,
        focusedIn,
        setfocusedInput,
        handlePopoverOpen,
        handlePopoverClose,
        isSmallScreen,
        isOutsideRangeCustom,
        setIsCustomRange,
        isCustomRange,
        tempRangeDates,
        updateTempDateRange,
        onReset
    } = userDateRangePicker(props);

    // disabling lint to get  DayPickerRangeControlle props
    // ...rest = props of DayPickerRangeController
    const {
        TextFieldProps,
        PopoverProps,
        displayFormat, // eslint-disable-line
        minDate, // eslint-disable-line
        maxDate, // eslint-disable-line
        onDatesChange,
        displayAdvancedOptions,
        displayPopupInputFields,
        hideInputDate,
        customTextFieldShrink,
        ...rest
    } = props;

    return (
        <>
            {hideInputDate ? null : <DateRangePickerInput
                handlePopoverOpen={handlePopoverOpen}
                textFieldShrink={customTextFieldShrink ?? textFieldShrink}
                textFieldValue={textFieldValue}
                TextFieldProps={TextFieldProps}
            />}
            <CustomDateCalendar
                PopoverProps={PopoverProps}
                anchorEl={props.anchorEl ?? anchorEl}
                displayAdvancedOptions={displayAdvancedOptions}
                displayPopupInputFields={displayPopupInputFields}
                handlePopoverClose={props.handlePopoverClose ?? handlePopoverClose}
                focusedIn={focusedIn}
                setfocusedInput={setfocusedInput}
                isSmallScreen={isSmallScreen}
                isOutsideRangeCustom={isOutsideRangeCustom}
                setIsCustomRange={setIsCustomRange}
                isCustomRange={isCustomRange}
                onReset={onReset}
                onDatesChange={onDatesChange}
                updateTempDateRange={updateTempDateRange}
                tempRangeDates={tempRangeDates}
                formattedDates={formattedDates}
                onTextInputDateChange={onTextInputDateChange}
                maskDateInput={maskDateInput}
                customOptions={props.customOptions}
                {...rest}
            />
        </>
    );
};