import { action, computed, observable } from "mobx";
import { GetResourceArchivedDateResponse, ResourceClassification, ResourceListResponse } from "app/util/api.dto";
import Resource from "../../resources/list/models/resource";
import resourceLabelsStore from "../../resources/shared/resourceLabelsStore";
import moment from "moment";
import ApiTransport from "app/util/apiTransport";
import { NoteType } from "billingmanager/servicecodes/item/sessionNoteTemplates/NoteTemplatesAssociationsStore";
import { splitRangeFilterDate } from "app/util/miscUtil";
import { FileUploadMultiStore } from "app/stores/upload/fileUploadMulti";
import { timeSheetServices } from "billingmanager/drafttimesheeteditor/timeSheetServices";
import domUtil from "app/util/domUtil";

const api = new ApiTransport();

export enum FileExplorerSourceEnum {
    Timesheets = 0,
    Messaging = 1,
    Billing = 2,
    Tasks = 3,
    TasksComment = 4
}

export class FilesExplorerStore {

    public readonly multiFileUploadStore: FileUploadMultiStore;
    @observable source: FileExplorerSourceEnum;
    @observable resources: Resource[] = [];
    @observable agGridStateManager = null;
    @observable loaded = false;
    @observable loading = false;
    @observable uploadLoading = false;
    @observable firstLoad = true;
    @observable maxFiles = null;
    
    @observable templateSearchKey = "";
    @observable noteTemplates = [];
    @observable loadingNoteTemplates = false;
    @observable sessionNoteTemplates = [];
    @observable archivedDate = null;
    @observable loadingArchivedDate = null;
    @observable searchKey = "";
    @observable resource = null;
    @observable contact = null;
    @observable createdbycontact = null;
    @observable resourceLabels = [];
    @observable contactLabels = [];
    @observable search = null;
    @observable template = null;
    @observable startDate = moment().subtract(2, "weeks");
    @observable endDate = moment();
    @observable pageSize = 5;
    @observable page = 1;
    @observable defaultClient = null;
    
    pageSizes = [ 5, 10, 20 ];
    columns = [
        { id: "name", label: "Name", isDate: false },
        { id: "contactDisplayName", label: "Owned by", isDate: false },
        { id: "createdByContactDisplayName", label: "Created by", isDate: false },
        { id: "creationDate", label: "Date Added", isDate: true }
    ];
    
    constructor(source: FileExplorerSourceEnum, settings = {}, client: number = null, maxFiles: number = null) {
        this.source = source;
        this.multiFileUploadStore = new FileUploadMultiStore(settings);
        this.defaultClient = client;
        this.maxFiles = maxFiles;
    }
    
    @action
    init = () => {
        this.firstLoad = true;
        if (this.defaultClient) {
            this.contact = { id: this.defaultClient, name: 'Loading...', isClient: true };
            this.loadContactName(this.defaultClient)
                .then(contactName => {
                    this.contact.name = contactName;
                })
            this.firstLoad = false;
        }
        this.multiFileUploadStore.files = observable([]);
        this.loadArchivedDate();
        this.load();
    }
    
    @action
    handlePageSizeChange = (size) => {
        this.pageSize = size;
        this.page = 1;
        this.load();
    }

    @action
    pageUp = () => {
        this.page++;
        this.load();
    }

    @action
    pageDown = () => {
        this.page--;
        this.load();
    }
    
    @computed
    get canPageDown() {
        return this.page > 1;
    }
    
    @computed
    get canPageUp() {
        return this.resources.length === this.pageSize;
    }

    @action
    addFile = async file => {
        try {
            if (this.multiFileUploadStore.files.find(f => f.id === file.id))    {
                return;
            }
            if (this.source == FileExplorerSourceEnum.Timesheets) {
                const resourceObj = await timeSheetServices.getResource(file.id);
                this.multiFileUploadStore.manuallyAddResource({ ...file, size: file.contentLength, visibleToClient: resourceObj?.OwnerHasAccess ? true : false });
            }
            else {
                this.multiFileUploadStore.manuallyAddResource({ ...file, size: file.contentLength });
            }
        }
        catch (e)   {
            domUtil.displayMessage("Could not add file, please try again.", { isError: true });
        }
    }

    @action
    clearSearch() {
        this.templateSearchKey = "";
        this.noteTemplates = [];
    }

    @action
    async loadArchivedDate() {
        if (!this.archivedDate)  {
            const resp = await api.get<GetResourceArchivedDateResponse>("resources/archived-date", {});
            this.archivedDate = moment(resp.archivedDate);
            if (this.archivedDate.isAfter(this.startDate)) {
                this.startDate = this.archivedDate.clone().add(1, "days");
            }
        }
    }

    @action
    async loadContactName(id: number) {
        const resp = await api.get (`filters/map?lookup={contacts:[${id}],resourceLabelIds:[],contactLabelIds:[]}`, {});
        return resp.filters.contactsNames.length > 0 ? resp.filters.contactsNames[0].value : 'Not Found';
    }

    @computed
    get templatesResults() {
        if (this.templateSearchKey === "") {
            return [];
        }
        const value = (v) => {
            return v?.name ? v.name.toUpperCase() : '';
        };
        return [
            ...(this.templateSearchKey ? this.sessionNoteTemplates.filter(i => i.name.toLowerCase().indexOf(this.templateSearchKey.toLowerCase()) !== -1).map(i => ({
                id: i.templateId,
                name: i.name,
                type: NoteType.Session
            })) : []).sort((a, b) => value(a) < value(b) ? -1 : value(a) > value(b) ? 1 : 0),
            ...(this.noteTemplates.map(i => ({
                id: i.id,
                name: i.name,
                type: NoteType.Template
            })).sort((a, b) => value(a) < value(b) ? -1 : value(a) > value(b) ? 1 : 0))
        ];
    }
    
    @action
    handleTextSearchSelection(result) {
        if (!result) return;
        switch (result.type) {
            case "resource":
                this.resource = result;
                break;
            case "contact":
                this.contact = result;
                this.contact.isClient = /.*\(Client ID: \d+\).*$/i.test(result.name);
                break;
            case "createdbycontact":
                this.createdbycontact = result;
                break;
            case "contactlabel":
                this.contactLabels.push(result);
                break;
            case "resourcelabel":
                this.resourceLabels.push(result);
                break;
            case "text":
                this.search = result;
                break;
        }
        this.clearPageAndLoad();
    }

    @action
    loadSearchableNotes(key: string)   {
        this.clearSearch();
        this.templateSearchKey = key;
        this.loadingNoteTemplates = true;
        api.get(`/notes/templates/list?search=${key}&showBillingEntryTemplates=true`)
            .then((response) => {
                this.noteTemplates = response.items;
                this.loadingNoteTemplates = false;
            });
    }

    @action
    async loadNotesTemplates() {
        this.loadingNoteTemplates = true;
        try {
            if (this.sessionNoteTemplates.length <= 0) {
                const resp = await api.get("note-templates", {});
                this.sessionNoteTemplates = resp.noteTemplates
            }
            this.loadingNoteTemplates = false;
        }
        catch (e) {
            this.loadingNoteTemplates = false;
        }
    }

    @action
    onTemplateChange = (template) => {
        this.clearSearch();
        this.template = template;
        this.clearPageAndLoad();
    }

    @action
    removeTemplate = () => {
        this.clearSearch();
        this.template = null;
        this.clearPageAndLoad();
    }

    @action
    removeSearch = () => {
        this.search = null;
        this.clearPageAndLoad();
    }

    @action
    removeResource = () => {
        this.resource = null;
        this.clearPageAndLoad();
    }

    @action
    removeContact = () => {
        this.contact = null;
        this.clearPageAndLoad();
    }

    @action
    removeCreatedbycontact = () => {
        this.createdbycontact = null;
        this.clearPageAndLoad();
    }

    @action
    removeContactLabel = (id) => {
        this.contactLabels = this.contactLabels.filter(l => l.id !== id);
        this.clearPageAndLoad();
    }

    @action
    removeResourceLabel = (id) => {
        this.resourceLabels = this.resourceLabels.filter(l => l.id !== id);
        this.clearPageAndLoad();
    }
    
    @action
    clearPageAndLoad = () => {
        this.page = 1;
        this.load();
    }

    @action
    clear = () => {
        this.search = null;
        this.template = null;
        this.startDate = moment().subtract(2, "weeks");
        this.endDate = moment();
        if (this.archivedDate.isAfter(this.startDate)) {
            this.startDate = this.archivedDate.clone().add(1, "days");
        }
        this.contact = null;
        this.createdbycontact = null;
        this.resource = null;
        this.resourceLabels = [];
        this.contactLabels = [];
        this.resources = [];
        this.uploadLoading = false;
    }

    @action
    onDatesChange = ({ startDate, endDate }) => {
        this.startDate = startDate;
        this.endDate = endDate;
        this.clearPageAndLoad();
    }

    @computed
    get isValidDateRange() {
        if (this.startDate === null || this.startDate.isBefore(this.archivedDate) || this.startDate.isAfter(this.endDate)) {
            return false;
        }
        return true;
    }
    
    @computed
    get dateValidationMessage() {
      if (this.startDate === null || this.startDate.isBefore(this.archivedDate)) {
        return "Date is out of range";
      } else if (this.startDate.isAfter(this.endDate)) {
        return "Start date must be before end date";
      } else {
        return "Date is invalid";
      }
    }

    @computed
    get isValidContact() {
        if (this.defaultClient !== null && (this.contact?.isClient ?? false) && this.contact?.id != this.defaultClient) {
            return false;
        }
        return true;
    }
    
    @computed
    get canAddFiles() {
        if (this.maxFiles !== null) {
            return this.multiFileUploadStore.files.length > 0 && this.multiFileUploadStore.files.length <= this.maxFiles;
        }
        return this.multiFileUploadStore.files.length > 0;
    }

    @computed
    get canDeleteContact() {
        return this.defaultClient === null;
    }
    
    @action
    load() {
        if (this.firstLoad || !this.isValidContact || !this.isValidDateRange) {
            this.firstLoad = false;
            this.resources = [];
            return;
        }
        this.loading = true;
        this.loaded = false;
        
        const request = {
            creationDate: splitRangeFilterDate(this.getFormattedDates()),
            sessionNoteId: this.template?.type === NoteType.Session ? this.template.id : null,
            notes: this.template?.type === NoteType.Template ? this.template.id : null,
            resourceId: this.resource?.id,
            contactId: this.contact?.id,
            createdByContactId: this.createdbycontact?.id,
            page: this.page,
            pageSize: this.pageSize,
            isArchived: 0,
            resourceLabels: this.resourceLabels.map(l => l.id),
            contactLabels: this.contactLabels.map(l => l.id),
            search: this.search?.name
        };

        api.get<ResourceListResponse>("resources/query", request).then(resp => {
            const resourcesResp = resp.resources;
            const classificationMap = new Map(
                resp.classifications
                    .groupBy(classification => classification.resourceId)
                    .map<[number, ResourceClassification[]]>(({ key, items }) => [key, items])
            );

            this.resources = resourcesResp.map<Resource>(r =>
                Resource.createFromResponse({
                    ...r,
                    labels: r.labels ? resourceLabelsStore.shapeIndividualLabelCollection(r.labels) : [],
                    documentTemplates: classificationMap.get(r.id) || [],
                    authStartDate: r.authStartDate ? moment(r.authStartDate).utc().format("YYYY-MM-DD") : "",
                    authEndDate: r.authEndDate ? moment(r.authEndDate).utc().format("YYYY-MM-DD") : "",
                    creationDate: moment(r.creationDate).format("MM/DD/YYYY HH:mm:ss")
                })
            );

            this.loading = false;
            this.loaded = true;
        });
    }

    getFormattedDates = () => {
        if (this.startDate != null && this.endDate != null)  {
            return `bt|${this.startDate?.clone().startOf("day").format('MM/DD/YYYY')}|${this.endDate?.clone().endOf("day").format('MM/DD/YYYY')}`;
        }
        else if (this.endDate != null)  {
            return `el|${this.endDate?.clone().endOf("day").format('MM/DD/YYYY')}`;
        }
        else if (this.startDate != null)  {
            return `eg|${this.startDate?.clone().startOf("day").format('MM/DD/YYYY')}`;
        }
        else {
            return null;
        }
    }
    
}