let currentTimeSymbol = Symbol("currentTime");
export default class VideoPlaybackEnforcer {
  constructor(lastTime) {
    this[currentTimeSymbol] = lastTime;
  }
  init(videoEl) {
    videoEl.addEventListener(
      "seeking",
      evt => {
        if (videoEl.currentTime > this[currentTimeSymbol]) {
          videoEl.currentTime = this[currentTimeSymbol];
        }
      },
      true
    );

    videoEl.currentTime = this[currentTimeSymbol];
  }
  get currentTime() {
    return this[currentTimeSymbol];
  }
  timeUpdate(videoEl) {
    if (!videoEl.seeking) {
      if (videoEl.currentTime > this[currentTimeSymbol]) {
        this[currentTimeSymbol] = videoEl.currentTime;
      }
    }
  }
  videoProgressed() {} //virtual
  metaDataLoaded() {}
}
