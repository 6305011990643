import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    ButtonGroup,
    debounce,
    Grid,
    Popper,
    TextField, Typography
} from "@material-ui/core";
import ResourceStore from "../resourceStore";
import { makeStyles } from "@material-ui/core/styles";
import AdvancedSearch from "app/components/select/advancedSearch";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { Alert, Autocomplete } from "@material-ui/lab";
import { CustomDatePicker } from "shared/CustomDatePicker/CustomDatePicker";
import { creationDateRangeModes } from "shared/CustomDatePicker/CustomCalendarDateRangeModes";
import HashChangeButton from "app/components/util/hashChangeButton";
import { NoteType } from "billingmanager/servicecodes/item/sessionNoteTemplates/NoteTemplatesAssociationsStore";

const useStyles = makeStyles({
    grid: {
        paddingRight: 12,
        paddingTop: 8,
        marginLeft: 0,
        "& .MuiGrid-item": {
            paddingRight: 24,
            paddingBottom: 6
        },
        alignItems: "flex-start"
    },
    date: {
        width: 235,
        "& .MuiInputBase-root": {
            background: "#fff"
        },
        "& .MuiFormLabel-root": {
            fontSize: 14,
        },
        '& .MuiInputBase-input': {
            padding: 10,
            fontSize: 14
        }
    },
    calendar: {
        '& .CalendarDay__blocked_out_of_range': {
            color: "#cacccd !important"
        }
    },
    autocomplete: {
        "& > div": {
            background: "#fff"
        },
        "& > div > label": {
            fontSize: 14,
            color: "#ccc",
        },
        "& .Mui-focused": {
            color: "#3F4B50"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            transform: "translate(12px, 12px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink.MuiInputLabel-marginDense": {
            transform: "translate(16px, -6px) scale(0.875)"
        },
        "& .MuiAutocomplete-input": {
            padding: "10px 0 10px 10px !important",
            fontSize: 14
        }
    },
    popper: {
        zIndex: 10,
        "& .MuiAutocomplete-noOptions": {
            display: "none"
        }
    },
    button: {
        backgroundColor: "#fff"
    },
    disabledButton: {
        backgroundColor: "#e0e0e0"
    },
    warning: {
        padding: "0px 16px",
        fontWeight: "bold",
        fontSize: 12,
        height: 32,
        lineHeight: "16px",
        "& .MuiAlert-icon": {
            padding: "4px 0"
        }
    },
    error: {
        flex: "100%",
        color: "#E51740",
        fontSize: 14
    }
});
export const ResourcesHeader = observer(({ submoduleStore, searchVm, advSearchOptions }: { submoduleStore: ResourceStore, searchVm: any, advSearchOptions: any }) => {

    const classes = useStyles();
    
    return <ThemeProvider skipGlobalStyles>
                    <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        className={classes.grid}
                    >
                        <Grid item xs={4}>
                            <div className="module-search smallSearch">
                                <i className="far fa-fw fa-2x fa-search"/>
                                <AdvancedSearch
                                    searchResultIsValid={searchVm.isAdvancedSearchOptionValid}
                                    itemSelected={searchVm.advancedSearchSelected}
                                    disabled={submoduleStore.isUiDisabled}
                                    {...advSearchOptions}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <NoteTemplateFilter
                                options={submoduleStore.templatesResults}
                                searchKey={submoduleStore.templateSearchKey}
                                clearSearch={submoduleStore.clearSearch.bind(submoduleStore)}
                                loadSearchableNotes={submoduleStore.loadSearchableNotes.bind(submoduleStore)}
                                onChange={(_, value) => submoduleStore.onTemplateChange(value)}
                                disabled={submoduleStore.isUiDisabled}
                                loading={submoduleStore.loadingNoteTemplates}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", flexWrap: "wrap" }}>
                            <CustomDatePicker
                                startDate={submoduleStore.startDate}
                                endDate={submoduleStore.endDate}
                                onDatesChange={submoduleStore.onDatesChange}
                                displayFormat="MM/DD/YYYY"
                                displayAdvancedOptions={true}
                                displayPopupInputFields={true}
                                displayClearBtn={!submoduleStore.isArchivePage()}
                                customOptions={submoduleStore.isArchivePage() ? [] : creationDateRangeModes}
                                isCustomRange={true}
                                customTextFieldShrink={true}
                                minDate={!submoduleStore.isArchivePage() && submoduleStore.archivedDate ? submoduleStore.archivedDate.endOf('day') : null}
                                maxDate={submoduleStore.isArchivePage() && submoduleStore.archivedDate ? submoduleStore.archivedDate.endOf('day') : moment()}
                                TextFieldProps={{
                                    disabled: submoduleStore.isUiDisabled,
                                    className: classes.date,
                                    placeholder: "mm/dd/yyyy-mm/dd/yyyy",
                                    label: "Date files created",
                                    error: !submoduleStore.isValidDateRange,
                                    inputProps: {
                                        "data-testid": "resources-date"
                                    },
                                }}
                                PopoverProps={{
                                    className: classes.calendar
                                }}
                            />
                            <Box style={{ margin: "3px 10px" }}>
                                {!submoduleStore.isUiDisabled ? <HashChangeButton 
                                    data-testid={"resources-refresh-btn"}
                                    className="btn btn-default"
                                    toMerge={{page: null}}
                                    onClick={() => submoduleStore.load()}
                                >
                                    <i className="fa fa-sync"/>
                                </HashChangeButton> : null}
                            </Box>
                            <Typography className={classes.error}>{!submoduleStore.isValidDateRange ? submoduleStore.dateValidationMessage : null}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonGroup variant="outlined" size="small">
                                <Button
                                    data-testid={"resources-all-btn"}
                                    disabled={submoduleStore.isUiDisabled}
                                    onClick={() => submoduleStore.goToAll()}
                                    className={!submoduleStore.isAuthorizedFilterActive ? classes.disabledButton : classes.button}
                                >All</Button>
                                <Button
                                    data-testid={"resources-authorized-btn"}
                                    disabled={submoduleStore.isUiDisabled}
                                    onClick={() => submoduleStore.goToAuthorized()}
                                    className={submoduleStore.isAuthorizedFilterActive ? classes.disabledButton : classes.button}
                                >Authorized</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={10} style={{display: "flex" }}>
                            {submoduleStore.isArchivePage() ? <>
                                {!submoduleStore.canViewArchivedEmployeeFiles && !submoduleStore.canViewArchivedClientFiles ?
                                    <Alert data-testid={"resources-warning"} variant="outlined" severity="warning" className={classes.warning}>You do not have access to archived employee or client files</Alert> :
                                    <>
                                        {!submoduleStore.canViewArchivedEmployeeFiles ? <Alert data-testid={"resources-warning"} variant="outlined" severity="warning" className={classes.warning}>You do not have access to archived employee files</Alert> : null}
                                        {!submoduleStore.canViewArchivedClientFiles ? <Alert data-testid={"resources-warning"} variant="outlined" severity="warning" className={classes.warning}>You do not have access to archived clients files</Alert> : null}
                                    </>}
                            </> : null}
                        </Grid>
                    </Grid>
                </ThemeProvider>;
});

export const NoteTemplateFilter = observer((
    {
        options,
        searchKey,
        onChange,
        clearSearch,
        loadSearchableNotes,
        disabled = false,
        loading = false,
    }: {
        options: { id: string; name: string; type: NoteType; }[];
        searchKey: string;
        onChange: (_, value) => void;
        clearSearch: () => void;
        loadSearchableNotes: (searchKey: string) => void;
        disabled?: boolean;
        loading?: boolean;
    }) => {
    
    const classes = useStyles();

    const onBlur = useCallback(() => {
        clearSearch();
    }, [ clearSearch ]);

    const handleChangeDebounced = useCallback(
        debounce((evt: React.ChangeEvent<HTMLInputElement>) => loadSearchableNotes(evt.target.value), 300),
        [ searchKey ]);

    const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.persist();
        return handleChangeDebounced(evt);
    }, [ handleChangeDebounced ]);
        
    return <Autocomplete
        disabled={disabled}
        className={classes.autocomplete}
        key={`resources-note-templates`}
        data-testid="resources-note-template"
        options={options}
        value={null}
        getOptionLabel={option => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        groupBy={option => option.type === NoteType.Template ? 'Notes & Forms' : 'Session Notes'}
        loading={loading}
        onChange={onChange}
        freeSolo={false}
        popupIcon={""}
        blurOnSelect={true}
        PopperComponent={(props) =>
            <Popper {...props} disablePortal className={classes.popper} placement="bottom"/>
        }
        renderInput={(params) => <TextField
            {...params}
            placeholder={"Search note templates"}
            label="Note template"
            onChange={handleChange}
            onBlur={onBlur}
            InputProps={{
                ...params.InputProps,
                value: searchKey
            }}
        />}
    />;
    
});