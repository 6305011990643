import { activeLabelsCollection } from "app/util/labels/labelUtilities";
import { action, observable, computed } from "mobx";
import { mappable } from "app/util/mobx-util";
import { htmlEscape } from "app/util/htmlHelpers";
import { FilePreview } from "app/stores/upload/filePreview";
import { Resource as IResource, ConversionErrorSource, ResourceConversionResult } from "app/util/api.dto";
import ApiTransport from "app/util/apiTransport";
import { CRConfirm } from "app/components/util/crConfirm";
import domUtil from "app/util/domUtil";
const api = new ApiTransport();

@mappable
class DocumentTemplate {
  id = "";
  effectiveDate = "";
  expirationDate = "";
  noExpirationDate = "";
  resourceNumber = "";
  groupName = "";
  templateName = "";
  typeName = "";
}

@mappable({ arrays: { documentTemplates: DocumentTemplate } })
@activeLabelsCollection({ createEndpoint: "resources/label/add", deleteEndpoint: "resources/label/delete" })
export default class Resource implements IResource {
  @observable selected = false;
  @observable isNew = false;
  @observable previewConversionInProgress = false;

  documentTemplates = [];

  m: number = null;
  id: number = null;
  copyNoteId: number = null;
  contactId: number = null;
  related: boolean = null;
  isMedicalRecord: boolean = null;
  name: string = null;
  contentLength: number = null;
  description: string = null;
  fileName: string = "";
  createdByContactId: number = null;
  creationDate: string = null;
  processingIsDone: boolean = null;
  originModule: string = null;
  originAction: string = null;
  deletedDate: string = null;
  deletedByContactId: number = null;
  authStartDate: string = null;
  authEndDate: string = null;
  payorName: string = null;
  error: boolean = null;
  labels: string = null;
  contactDisplayName: string = null;
  createdByContactDisplayName: string = null;
  deleted: boolean = null;
  editable: boolean = null;
  pageCount: number = null;
  @observable previewPageCount: number = null;
  @observable previewConversionComplete: boolean = null;
  @observable previewConversionErrorSource: ConversionErrorSource = null;
  @observable previewConversionErrorCode: number = null;
  @observable previewConversionErrorMessage: string = null;
  fileUploaded: boolean = null;
  verifiedBy: number = null;
  verifiedOn: string = "";
  verifiedDescription: string = null;
  verifiedByName: string = null;
  isContactWithheld: boolean = null;
  isCreatedByWithheld: boolean = null;

  get isConvertible() {
    return this.fileIsPdf(this.fileName) || this.fileIsDocument(this.fileName);
  }

  get isImage() {
    return this.fileIsPicture(this.fileName);
  }

  get isImagePreview() {
    return this.isConvertible || this.isImage;
  }

  get isVideo() {
    return this.fileIsMovie(this.fileName);
  }

  get isVideoPreview() {
    return this.isVideo;
  }

  get hasPreview() {
    return this.isImagePreview || this.isVideoPreview;
  }

  @computed
  get failedConversion() {
    return this.isConvertible && (this.previewConversionErrorSource || (this.previewConversionComplete && this.previewPageCount === 0));
  }

  @action toggleSelected = () => (this.selected = !this.selected);
  @action
  newLabel() {
    if (this.isNew) {
      return '<span class="label label-new">New</span> ';
    } else {
      return "";
    }
  }

  @action
  fileSize() {
    return CRFormatBytes(this.contentLength);
  }

  @action
  fileType() {
    if (this.fileIsPicture(this.fileName)) {
      return "fas fa-2x fa-file-image text-info";
    }
    if (this.fileIsSpreadsheet(this.fileName)) {
      return "fas fa-2x fa-file-excel text-success";
    }

    if (this.fileIsPdf(this.fileName)) {
      return "fas fa-2x fa-file-pdf text-danger";
    }

    if (this.fileIsMovie(this.fileName)) {
      return "fas fa-2x fa-file-video text-warning";
    }

    if (this.fileIsDocument(this.fileName)) {
      return "fas fa-2x fa-file-word text-primary";
    }

    if (this.fileIsText(this.fileName)) {
      return "fas fa-2x fa-file-alt text-muted";
    }

    if (this.fileIsMedical(this.fileName)) {
      return "fas fa-2x fa-medkit";
    }

    return "far fa-2x fa-file text-muted";
  }

  @action
  fileMatchesTypes(fileName, fileTypes) {
    const fileSplit = fileName.split(".");
    const result = fileSplit.filter(element => fileTypes.indexOf(element.toLowerCase()) > -1);
    return result.length > 0;
  }

  @action
  fileIsText(fileName) {
    const textEndings = ["txt", "rtf", "text"];
    return this.fileMatchesTypes(fileName, textEndings);
  }

  @action
  fileIsPicture(fileName) {
    const picEndings = ["png", "jpg", "jpeg", "bmp", "tiff", "greenshot"];
    return this.fileMatchesTypes(fileName, picEndings);
  }

  @action
  fileIsSpreadsheet(fileName) {
    const sheetEndings = ["xls", "xlsx", "csv"];
    return this.fileMatchesTypes(fileName, sheetEndings);
  }

  @action
  fileIsPdf(fileName) {
    const pdfEndings = ["pdf"];
    return this.fileMatchesTypes(fileName, pdfEndings);
  }

  @action
  fileIsMovie(fileName) {
    const movieEndings = ["mov", "mp4", "vid", "wmv", "avi", "flv", "asf"];
    return this.fileMatchesTypes(fileName, movieEndings);
  }

  @action
  fileIsDocument(fileName) {
    const docEndings = ["doc", "docx"];
    return this.fileMatchesTypes(fileName, docEndings);
  }

  @action
  fileIsMedical(fileName) {
    const medEndings = ["x12"];
    return this.fileMatchesTypes(fileName, medEndings);
  }

  @action
  previewShow() {
    return FilePreview.isSupportedType(this.fileName) && !FilePreview.isVideoFile(this.fileName);
  }

  @action
  markRead() {
    this.isNew = false;
    cr.messageBus.sendMessage("*", "resource-read", {});
  }
}
