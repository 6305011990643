import { Row } from "@cr/react-basics";
import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { DateRangeModeButton } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeModeButton";
import { DateRange, DateRangeMode } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeMode";

interface CalendarOptionsProps {
    onDatesChange: ({ startDate, endDate }) => void;
    setIsCustomRange: (isCustomRange: boolean) => void;
    isCustomRange: boolean;
    dateRange: DateRange;
    updateTempDateRange: (daterange: DateRange) => void;
    customOptions: DateRangeMode[];
    handlePopoverClose: (event) => void;
}

const useStyles = makeStyles(
    createStyles({
        optionButton: {
            fontSize: 14,
            justifyContent: "left",
            textTransform: "capitalize",
            color: "rgba(0,0,0,0.87)",
            width: "100%",
            fontWeight: 400
        },
        optionButtonSelected: {
            fontSize: 14,
            justifyContent: "left",
            textTransform: "capitalize",
            color: "rgba(0,0,0,0.87)",
            width: "100%",
            fontWeight: 400,
            backgroundColor: "rgba(0,0,0,0.07)"
        },
        optionsBorder: {
            borderRight: "0.5px rgba(0, 0, 0, 0.07) solid",
            width: "200px",
            minHeight: "100%",
            padding: "8px 0"
        }
    })
);

export const CustomCalendarOptions = (props: CalendarOptionsProps) => {
    const styles = useStyles(props);
    const { onDatesChange, setIsCustomRange, dateRange, isCustomRange, updateTempDateRange, handlePopoverClose } = props;

    const setDate = (date: DateRange) => {
        onDatesChange(date);
        if (isCustomRange) {
            updateTempDateRange(date);
        }
        handlePopoverClose(false);
    };
    const customRange = () => {
        setIsCustomRange(true);
    };

    return (
        <div className={styles.optionsBorder} data-testid="calendarOption">
            {props.customOptions.map(mode => {
                return (
                    <Row key={mode.mode}>
                        <DateRangeModeButton dateRangeOption={mode} now={dateRange} onSelect={setDate} />
                    </Row>
                );
            })}
            <Row key="customRange">
                <DateRangeModeButton
                    data-testid="customRange"
                    dateRangeOption={{
                        mode: "customRange",
                        displayName: "Custom Range",
                        range: () => ({
                            startDate: dateRange.startDate ?? null,
                            endDate: dateRange.endDate ?? null
                        }),
                        matches: () => isCustomRange
                    }}
                    now={dateRange}
                    onSelect={customRange}
                />
            </Row>
        </div>
    );
};