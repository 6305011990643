import React from "react";
import { render } from "react-dom";

import transport from "app/util/transport";
import { observable, action } from "mobx";
import ResourceVideoPlaying from "app/stores/videoPlaying/resourceVideoPlayer";
import VideoPlaybackEnforcer from "app/stores/videoPlaying/videoPlaybackEnforcer";
import domUtil from "app/util/domUtil";

import FilePreviewComponent from "app/components/upload/filePreview";

let isAndroid = false;
try {
  isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
} catch (e) {}

export const pdfTypes = ["pdf"];
export const videoTypes = ["vid", "mp4", "mov", "wmv", "avi", "flv"];
export const imgTypes = ["gif", "png", "jpg", "jpeg", "tiff", "greenshot"];
export const supportedTypes = pdfTypes.concat(videoTypes).concat(imgTypes);

export class FilePreview {
  static getFileFormat(fileName) {
    // return file extension based on file name
    if (typeof fileName === "string") {
      var dotpos = fileName.lastIndexOf(".");
      if (dotpos < 0) return "";
      return fileName.substring(dotpos + 1, fileName.length).toLowerCase();
    }
    return "";
  }
  static isVideoFile(fileName) {
    return videoTypes.indexOf(FilePreview.getFileFormat(fileName)) > -1;
  }
  static isSupportedType(fileName) {
    let fileformat = FilePreview.getFileFormat(fileName);

    if (pdfTypes.indexOf(fileformat) > -1 && isAndroid) {
      // disable android pdf preview for now
      return false;
    }
    return fileName != "" ? supportedTypes.indexOf(fileformat) > -1 : false;
  }

  videoPlayer = new ResourceVideoPlaying(this);

  @observable id = "";
  @observable name = "";
  @observable resourceUrl = "";
  @observable resourceName = "";
  @observable preventSeek = false;
  @observable latestTimeViewed = 0;

  @observable pdfFile = false;
  @observable imgFile = false;
  @observable videoFile = false;
  @observable otherFile = false;
  @observable failed = false;
  @observable isShowing = false;

  preview(fileToPreview) {
    //this works with different types of file objects, so we'll do a little bit of duck typing
    let id, name;

    if (fileToPreview.resourceId) {
      id = fileToPreview.resourceId;
    } else if (fileToPreview.uploadedId) {
      id = fileToPreview.uploadedId;
    } else if (fileToPreview.id) {
      id = fileToPreview.id;
    }

    if (fileToPreview.name) {
      name = fileToPreview.name;
    } else if (fileToPreview.fileName) {
      name = fileToPreview.fileName;
    } else if (fileToPreview.id) {
      id = fileToPreview.id;
    }

    if (!id) {
      if (fileToPreview.file) {
        this.previewLocalFile(fileToPreview);
      }
      return;
    }

    this.id = id;
    this.name = name;

    fileToPreview.loadingPreview = true;
    let fileformat = FilePreview.getFileFormat(name);

    transport.successRequest(
      "resources",
      "getresourceurl",
      { resourceId: this.id, preview: true },
      resp => {
        if (videoTypes.indexOf(fileformat) > -1) {
          if (!resp.EncodingFormats) {
            cr.domUtil.displayMessage("This video has not finished processing", { isError: true, scrollTop: false });
            fileToPreview.loadingPreview = false;
            return;
          }
        }
        this.runPreview(resp, fileToPreview);
      },
      resp => (this.failed = true)
    );
  }
  previewDirect(fileToPreview) {
    this.runPreview(
      {
        url: fileToPreview.previewUrl,
        fileName: fileToPreview.fileName
      },
      fileToPreview
    );
  }
  previewLocalFile(fileStore) {
    if (typeof FileReader === "undefined") {
      domUtil.displayMessage("Cannot preview local files on this browser", { isError: true });
      return;
    }
    fileStore.loadingPreview = true;

    let file = fileStore.file,
      reader = new FileReader();

    reader.addEventListener(
      "load",
      action(() => {
        this.runPreview(
          {
            fileName: file.name,
            url: reader.result
          },
          fileStore
        );
      }),
      false
    );
    reader.readAsDataURL(file);
  }
  runPreview(previewData, fileStore) {
    let fileformat = FilePreview.getFileFormat(previewData.fileName);

    this.resourceUrl = previewData.url;
    this.name = previewData.fileName;
    this.resourceName = previewData.fileName;
    this.preventSeek = previewData.preventSeek || false;
    this.latestTimeViewed = ~~previewData.latestTimeViewed || 0;

    this.pdfFile = false;
    this.imgFile = false;
    this.otherFile = false;
    this.videoFile = false;

    if (pdfTypes.indexOf(fileformat) > -1) {
      this.pdfFile = true;
    } else if (videoTypes.indexOf(fileformat) > -1) {
      this.videoPlayer.setResource(this);
      this.setVideo();
    } else if (imgTypes.indexOf(fileformat) > -1) {
      this.imgFile = true;
    } else {
      this.otherFile = true;
    }
    setTimeout(() => {
      this.isShowing = true;
      fileStore.loadingPreview = false;
    }, 100); //give the resource a chance to render to avoid jolting the modal in
  }
  endPreview = () => {
    setTimeout(
      action(() => {
        this.otherFile = false;
        this.imgFile = false;
        this.pdfFile = false;
        this.videoFile = false;
      }),
      150
    );
    this.isShowing = false;
  };
  setVideo() {
    this.videoPlayer.set(this.name, this.resourceUrl, this.preventSeek ? new VideoPlaybackEnforcer(this.latestTimeViewed) : null);
    this.videoFile = true;
  }
}

export const globalFilePreview = new FilePreview();

//conditional to protect against this module being bundled in two places
if (!window.__filePreviewRendered) {
  window.__filePreviewRendered = true;

  const containerId = "global-react-file-preview-modal";

  let container = $(`#${containerId}`);
  if (container.length === 0) {
    container = $(`<div id="${containerId}"></div>`).appendTo(document.body);
  }

  render(<FilePreviewComponent store={globalFilePreview} />, container.get(0));
}

export default FilePreview;
