import { observable, computed, action, autorun, extendObservable, untracked } from "mobx";
import searchVmFactory from "app/util/searchVm";
import defaultSearchMap, { rangeMapper, dateMapper } from "app/util/defaultSearchMap";
import user from "app/util/user";
import { JSV } from "third_party_libraries/jsv/jsv";
import { splitRangeFilter, splitRangeFilterDate } from "app/util/miscUtil";
import { canViewNewResourceUi } from "app/util/resourcesUtil";

export const originOptions = [
  { name: "Billing", value: "billingmanager" },
  { name: "Claims", value: "claims" },
  { name: "Learn/Bundles", value: "bundles" },
  { name: "Messages", value: "messaging" },
  { name: "Notes", value: "notes" },
  { name: "Payroll", value: "payroll" },
  { name: "Reporting", value: "reporting" },
  { name: "Resources", value: "resources" },
  { name: "Tasks", value: "tasks" },
  { name: "Scheduling", value: "scheduling" },
  { name: "Contacts", value: "contacts" },
  { name: "Session Notes", value: "sessionnotes" }
];

export const fileTypeOptions = [
  { name: "Documents", value: "doc" },
  { name: "Videos/Media", value: "media" },
  { name: "Spreadsheets", value: "spreadsheet" },
  { name: "PDFs", value: "pdf" },
  { name: "Notes and Forms", value: "note" },
  { name: "Session Notes", value: "sessionnote" }
];

export const fileSizeOptions = [
  { name: "0KB", value: "eq|0" },
  { name: "1MB or more", value: "gt|1" },
  { name: "5MB or more", value: "gt|5" },
  { name: "10MB or more", value: "gt|10" },
  { name: "20MB or more", value: "gt|20" },
  { name: "50MB or more", value: "gt|50" }
];

export const expirationDatesOptions = [
  { name: "Has expired", value: "lt|" + moment().format("YYYY-MM-DD") },
  {
    name: "Is still valid",
    value:
      "gt|" +
      moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD")
  },
  {
    name: "Expires this month",
    value:
      "bt|" +
      moment()
        .startOf("month")
        .format("YYYY-MM-DD") +
      "|" +
      moment()
        .add(1, "months")
        .startOf("month")
        .subtract(1, "days")
        .format("YYYY-MM-DD")
  },
  {
    name: "Expires next month",
    value:
      "bt|" +
      moment()
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD") +
      "|" +
      moment()
        .add(2, "months")
        .startOf("month")
        .subtract(1, "days")
        .format("YYYY-MM-DD")
  }
];

export const authLastVerifiedDatesOptions = [
  { name: "Today", value: "eq|" + moment().format("YYYY-MM-DD") },
  {
    name: "In last 7 days",
    value:
      "bt|" +
      moment()
        .add(-7, "day")
        .format("YYYY-MM-DD") +
      "|" +
      moment().format("YYYY-MM-DD")
  },
  {
    name: "In last 30 days",
    value:
      "bt|" +
      moment()
        .add(-30, "day")
        .format("YYYY-MM-DD") +
      "|" +
      moment().format("YYYY-MM-DD")
  },
  {
    name: "More than 7 days ago",
    value:
      "lt|" +
      moment()
        .add(-7, "day")
        .format("YYYY-MM-DD")
  },
  {
    name: "More than 30 days ago",
    value:
      "lt|" +
      moment()
        .add(-30, "day")
        .format("YYYY-MM-DD")
  },
  {
    name: "More than 6 months ago",
    value:
      "lt|" +
      moment()
        .add(-6, "month")
        .format("YYYY-MM-DD")
  },
  {
    name: "More than a year ago",
    value:
      "lt|" +
      moment()
        .add(-1, "year")
        .format("YYYY-MM-DD")
  },
  {
    name: "For a future date",
    value: "gt|" + moment().format("YYYY-MM-DD")
  }
];

export const effectiveDateOptions = [
  {
    name: "Is effective",
    value:
      "lt|" +
      moment()
        .add(1, "days")
        .format("YYYY-MM-DD")
  },
  { name: "Not yet effective", value: "gt|" + moment().format("YYYY-MM-DD") }
];

export const fileStatusOptions = [
  { name: "Error", value: 0 },
  { name: "Still uploading", value: 1 },
  { name: "Uploaded but processing", value: 2 },
  { name: "Uploaded and processed", value: 3 }
];

const canViewNewUi = canViewNewResourceUi() && !user.isClient();

const deferSearchMap = !canViewNewUi ? null: { defer: { vm: "defer" }};

function buildSearchMap() {
  let searchMap = {
    ...defaultSearchMap,
    ...deferSearchMap,
    isArchived: {
      vm: "isArchived",
      ui: "isArchived",
      isToggle: true
    },
    noteTemplateId: {
      vm: "noteTemplateId",
      ui: "Template"
    },
    notes: {
      vm: "notes",
      ui: "Template"
    },
    sessionNoteId: {
      vm: "sessionNoteId",
      ui: "Template",
      display: "sessionNoteName"
    },
    filter: { vm: "filter", ui: "", display: "filterName", option: item => item.name },
    hasResourceLabels: { vm: "resourceLabel", ui: "Resource Label", display: "resourceLabelName", isToggle: true, option: item => item.name },
    hasContactLabels: { vm: "contactLabel", ui: "Contact Label", display: "ContactLabelName", isToggle: true, option: item => item.name },
    createdbycontact: {
      ...defaultSearchMap.createdbycontact,
      ui: "Uploaded By"
    },
    contact: {
      ...defaultSearchMap.contact,
      ui: "Owned By"
    },
    shared: {
      ...defaultSearchMap.shared,
      ui: "Shared",
      display: "sharedName",
      localMapper: v => ({ true: "Yes", false: "No" }[v])
    },
    creationDate: {
      ...defaultSearchMap.creationDate,
      ui: canViewNewUi ? "Date Added" : "Upload Date"
    },
    originModule: {
      ...defaultSearchMap.originModule,
      ui: "Uploaded via",
      localMapper: v => (originOptions.find(option => option.value == v) || {}).name
    },
    fileType: {
      ...defaultSearchMap.fileType,
      localMapper: v => (fileTypeOptions.find(option => option.value == v) || {}).name
    },
    contentLength: {
      ...defaultSearchMap.contentLength,
      ui: "File Size",
      localMapper: v => rangeMapper(v, size => `${size} MB`)
    },
    authEndDate: {
      ...defaultSearchMap.authEndDate,
      ui: "Expires",
      localMapper: v => (expirationDatesOptions.find(option => option.value == v) || {}).name || dateMapper(v)
    },
    authorized: {
      ...defaultSearchMap.authorized,
      display: "authorizedName",
      localMapper: v => ["No", "Yes"][v]
    },
    signed: {
      ...defaultSearchMap.signed,
      display: "signedName",
      localMapper: v => ["No", "Yes"][v]
    },
    signedByAll: {
      ...defaultSearchMap.signedByAll,
      localMapper: v => ["No", "Yes"][v]
    },
    effectiveDate: {
      ...defaultSearchMap.effectiveDate,
      localMapper: v => (effectiveDateOptions.find(option => option.value == v) || {}).name || dateMapper(v)
    },
    expirationDate: {
      ...defaultSearchMap.expirationDate,
      localMapper: v => (expirationDatesOptions.find(option => option.value == v) || {}).name || dateMapper(v)
    },
    fileStatus: {
      ...defaultSearchMap.fileStatus,
      localMapper: v => (fileStatusOptions.find(option => option.value == v) || {}).name
    },
    claim: {
      ...defaultSearchMap.claim,
      display: "claimId"
    },
    resource: {
      ...defaultSearchMap.resource,
      localMapper: v => v
    },
    authVerified: {
      vm: "authVerified",
      ui: "Authorization Verified",
      display: "authVerifiedName",
      localMapper: v => ["No", "Yes"][v]
    },
    authLastVerified: {
      vm: "authLastVerified",
      ui: "Authorization Last Verified",
      display: "authLastVerifiedName",
      localMapper: dateMapper
    }
  };

  // Tweak the UI of a couple of the default search map values for resource-specific displays.
  return searchMap;
}

export const searchVmMap = buildSearchMap();
const minDate = "1/1/53 12:00:00 AM";
const maxDate = "12/30/99 12:00:00 AM";

const { SearchVm: SearchVmBase, FilterBar } = searchVmFactory({
  properties: searchVmMap,
  defaults: {},
  pageInfo: {
    pageSize: 50,
    pageSizes: [50, 100, 150, 200, 250, 500, 1000]
  },
  mapToArrays: [
    { name: "resourceLabelId", excludable: true, mapperClass: "resourceLabelIds" },
    { name: "contactLabelId", excludable: true, mapperClass: "contactLabelIds" }
  ],
  //mapToArrays: ["resourceLabelId", "contactLabelId"],
  syncTo: ["resources"],
  nonFilterBarSearchProperties: [],
  suppressFromFilterBar: canViewNewUi ? ["filter", "defer", "creationDate", "authorized", "isArchived" ] : ["filter", "defer", "isArchived"],
  deferSearch: canViewNewUi
});

export class SearchVm extends SearchVmBase {
  constructor(store) {
    super();
    this.resourcesStore = store;
  }

  get sortColumn() {
    let sort = this.vmProperties.sort || "";
    return sort.split("_")[0] || sort;
  }

  @computed
  get canPageUp() {
    return this.resourcesStore.resources.length == this.pageSize;
  }

  getApiRequestObject() {
    const cleanRequest = this.getCleanRequestObject();

    const request = {
      ...cleanRequest,
      resourceLabels: (cleanRequest.resourceLabelIds || "").split(",").filter(v => v),
      resourceLabelIds: null,
      resourceLabelIdIncluded: null,
      resourceLabelIdExcluded: null,
      contactLabels: (cleanRequest.contactLabelIds || "").split(",").filter(v => v),
      contactLabelIds: null,
      contactLabelIdIncluded: null,
      contactLabelIdExcluded: null,
      contentLength: splitRangeFilter(cleanRequest.contentLength),
      versions: splitRangeFilter(cleanRequest.versions),
      creationDate: splitRangeFilterDate(cleanRequest.creationDate),
      effectiveDate: splitRangeFilterDate(cleanRequest.effectiveDate),
      expirationDate: splitRangeFilterDate(cleanRequest.expirationDate),
      authStartDate: splitRangeFilterDate(cleanRequest.authStartDate),
      authEndDate: splitRangeFilterDate(cleanRequest.authEndDate),
      authLastVerified: splitRangeFilter(cleanRequest.authLastVerified)
    };

    return Object.keys(request).reduce((obj, key) => {
      obj[key] = JSV.serialize(request[key]);
      return obj;
    }, {});
  }

  getCleanRequestObject() {
    const request = super.getCleanRequestObject();

    request.isArchived = null;
    if (this.resourcesStore.canViewNewUi)  {
      request.isArchived = this.resourcesStore.isArchivePage() ? "1" : "0";
    }
    
    return request;
  }
}
export { FilterBar };
