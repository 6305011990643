import { observable, runInAction, computed, action } from "mobx";
import NestedLabel, { labelSortCompare, NestedLabelLike } from "./nestedLabel";
import user from "app/util/user";
import transport from "app/util/transport";
import router from "app/util/router";
import { CrLabel } from "app/util/api.dto";

export function gatherLabels(labels: NestedLabel[], filter: (label: any) => boolean = () => true) {
  const result: NestedLabel[] = [];
  labels.filter(filter).forEach(l => {
    result.push(l);
    if (l.children.length) {
      result.push(...gatherLabels(l.children.filter(filter)));
    }
  });
  return result;
}

const formatLabelResponse = (label: any) => {
  const color = "color" in label ? label.color : label.Color;
  return {
    id: "id" in label ? +label.id : +label.Id,
    name: "name" in label ? label.name : label.Name,
    isPublic: "isPublic" in label ? !!+label.isPublic : !!+label.IsPublic,
    backgroundColor: color.length > 0 ? color.split("|")[0] : "cccccc",
    color: color.length > 0 ? color.split("|")[1] : "000000",
    parent: "parent" in label ? +label.parent : +label.Parent
  } as NestedLabelLike;
};

const responseLabelToNestedLabel = (responseLabel: any) => {
  const nestedLabelLike = formatLabelResponse(responseLabel);
  return new NestedLabel(nestedLabelLike);
};

function descendAndCleanup(labels, currentDepth = 0) {
  labels.forEach(l => {
    l.depth = currentDepth;
    if (l._children.length) {
      descendAndCleanup(l._children, currentDepth + 1);
    }
  });
}

export const loadLabelsMidTier = (channel, actionName) => {
  return Promise.resolve(transport.transmitRequest(channel, actionName, {})).then(resp => {
    return runInAction(() => {
      return parseLabels(resp.labels);
    });
  });
};

export const loadLabelsApi = (url, loadAction) => {
  return Promise.resolve(transport.api.get(url, {})).then(resp => {
    return runInAction(() => {
      return parseLabels(resp.results.map(result => ({ ...result, id: result.labelId, isPublic: result.ownerContactId !== user.getId() })));
    });
  });
};

export const parseLabels = labels => {
  const topLabels = labels
      .filter(l => !l.parentLabelId)
      .map(responseLabelToNestedLabel)
      .sort(labelSortCompare),
    childrenMap = {},
    allChildLabels = [];

  labels.filter(l => !!l.parentLabelId).forEach(responseLabel => {
    const realLabel = responseLabelToNestedLabel(responseLabel);
    allChildLabels.push(realLabel);
    if (!childrenMap[responseLabel.parentLabelId]) {
      childrenMap[responseLabel.parentLabelId] = [];
    }
    childrenMap[responseLabel.parentLabelId].push(realLabel);
  });

  const masterLabelMap = topLabels.concat(allChildLabels).reduce((hash, label) => {
    hash[label.id] = label;
    return hash;
  }, {});

  Object.keys(childrenMap).forEach(parentLabelId => {
    const parent = masterLabelMap[parentLabelId];
    if (!parent) {
      return;
    }
    parent._children = childrenMap[parentLabelId];
    parent._children.forEach(child => (child.parent = parent));
  });

  descendAndCleanup(topLabels);
  return topLabels;
};

export class NestedLabelsStore {
  apiUrl: string;
  loadUrl: string;
  loadMethod: string;
  addUrl: string;
  addMethod: string;
  saveUrl: string;
  saveMethod: string;
  deleteUrl: string;
  deleteMethod: string;
  transportId: string;
  loadAction: string;
  saveLabelActionName: string;
  deleteLabelActionName: string;
  orgLabelsPermissionId: number;
  labelDeletedMessageName: string;

  constructor(options) {
    // If these options are set, then load/set/delete from the middle tier,
    this.transportId = options.transportId || router.currentModuleChannelId;
    this.loadAction = options.loadAction;
    this.saveLabelActionName = options.saveLabelActionName || "setlabel";
    this.deleteLabelActionName = options.deleteLabelActionName || "removelabel";

    // If this is set, load from the API instead.
    this.apiUrl = options.apiUrl;
    this.loadUrl = options.loadUrl || options.apiUrl;
    this.loadMethod = options.loadMethod || "get";
    this.addUrl = options.addUrl || options.apiUrl;
    this.addMethod = options.addMethod || "post";
    this.saveUrl = options.saveUrl || options.apiUrl;
    this.saveMethod = options.saveMethod || "put";
    this.deleteUrl = options.deleteUrl || options.apiUrl;
    this.deleteMethod = options.deleteMethod || "del";

    this.orgLabelsPermissionId = options.permissionId;
    this.labelDeletedMessageName = options.labelDeletedMessageName;
  }

  loadLabelsPromise = null;

  @observable deleting = false;
  @observable loaded = false;
  @observable loading = true;
  @observable labelsLoaded = true;
  @observable labels = observable.array<NestedLabel>([]);

  @computed
  get allLabels() {
    return gatherLabels(this.labels.sort(labelSortCompare));
  }
  @computed
  get allLabelsLookup() {
    return new Map(this.allLabels.map<[number, NestedLabel]>(l => [l.id, l]));
  }

  @computed
  get privateLabels() {
    return this.labels.filter(l => !l.isPublic).sort(labelSortCompare);
  }
  @computed
  get privateLabelsFlattened() {
    return gatherLabels(this.privateLabels);
  }

  @computed
  get orgLabels() {
    return this.labels.filter(l => l.isPublic).sort(labelSortCompare);
  }
  @computed
  get orgLabelsFlattened() {
    return gatherLabels(this.orgLabels);
  }

  get canEditOrgLabels() {
    return !user.isCurrentlyTheOrganization() && !user.isClient() && this.orgLabelsPermissionId && user.hasPermission(this.orgLabelsPermissionId);
  }
  get canUseOrgLabels() {
    return !user.isCurrentlyTheOrganization() && !user.isClient();
  }

  @action
  loadLabelsIfNotAlreadyLoaded() {
    if (!this.loadLabelsPromise) {
      this.loadLabelsPromise = this.loadLabels();
    }
    return this.loadLabelsPromise;
  }

  @action
  loadLabels() {
    this.loading = true;
    if (this.loadUrl === undefined) {
      return Promise.resolve(loadLabelsMidTier(this.transportId, this.loadAction)).then(labels => this.assignLabels(labels));
    } else {
      return Promise.resolve(loadLabelsApi(this.loadUrl, this.loadMethod)).then(labels => this.assignLabels(labels));
    }
  }

  @action
  assignLabels(labels) {
    this.loading = false;
    this.labelsLoaded = true;
    this.labels = labels;
    this.loaded = true;
    return this.labels;
  }

  getLabelById(id) {
    return this.allLabels.find(label => label.id === id);
  }

  @action
  // tslint:disable-next-line:cognitive-complexity
  saveLabel(label, labelInfo) {
    const packet = {
      ...(label.id ? { labelId: label.id } : {}),
      name: labelInfo.name,
      parentId: labelInfo.parentId,
      color: `${labelInfo.bgColor}|${labelInfo.fontColor}`,
      isOrgLabel: label.isPublic
    };

    let saveAction;

    if (this.saveUrl && label.id) {
      saveAction = transport.api[this.saveMethod](`${this.saveUrl}/${label.id}`, null, packet);
    } else if (this.addUrl && !label.id) {
      saveAction = transport.api[this.addMethod](this.addUrl, null, packet);
    }

    if (saveAction) {
      saveAction = saveAction.then(resp => ({ ...resp, fields: { ...resp.fields, id: resp.fields.id || resp.fields.labelId } }));
    } else {
      saveAction = Promise.resolve(transport.successRequest(this.transportId, this.saveLabelActionName, packet));
    }

    return saveAction.then(
      action((resp: any) => {
        const oldParentId = label.parent ? label.parent.id : 0;
        const newParentId = +packet.parentId || 0;
        const mapObject = formatLabelResponse(Object.assign({}, resp.fields, { isPublic: packet.isOrgLabel }));

        if (!label.id) {
          const newLabel = new NestedLabel(mapObject);
          if (newParentId) {
            const parent = this.getLabelById(newParentId);
            if (parent) {
              newLabel.parent = parent;
              newLabel.depth = parent.depth + 1;
              parent._children.push(newLabel);
            }
          } else {
            newLabel.depth = 0;
            this.labels.push(newLabel);
          }
        } else {
          Object.assign(label, mapObject);

          if (newParentId !== oldParentId) {
            if (oldParentId) {
              const oldParent = this.getLabelById(oldParentId);
              if (oldParent) {
                oldParent._children.remove(label);
              }
            } else {
              this.labels.remove(label);
            }

            if (newParentId) {
              const newParent = this.getLabelById(newParentId);
              if (newParent) {
                label.parent = newParent;
                label.depth = newParent.depth + 1;
                newParent._children.push(label);
              }
            } else {
              label.parent = null;
              label.depth = 0;
              this.labels.push(label);
            }
          }
        }
      })
    );
  }

  @action
  deleteLabel(label) {
    const request = { labelId: label.id };
    this.deleting = true;

    const deleteAction = this.deleteUrl
      ? transport.api[this.deleteMethod](`${this.deleteUrl}/${label.id}`)
      : Promise.resolve(transport.successRequest(this.transportId, this.deleteLabelActionName, request));

    return deleteAction.then(
      action(resp => {
        if (label.parent) {
          label.parent._children.remove(label);
          if (!label.parent._children.length) {
            label.parent.expanded = false;
          }
        } else {
          this.labels.remove(label);
        }
        this.deleting = false;
        if (this.labelDeletedMessageName) {
          router.currentModuleBusConnection.sendMessage(this.labelDeletedMessageName, request);
        }
      })
    );
  }

  shapeIndividualLabelCollection(labels) {
    return NestedLabelsStore.shapeIndividualLabelCollection(labels);
  }
  static shapeIndividualLabelCollection(labels: string) {
    let prelim = labels.split(",");

    //label names can have a comma in them.  If they do, the split will have false positives.  Since the UDF replaces , with "," (adds quotes)
    //these false positives will always start with a double quote, and will always be preceded by an entry ending with a ".  This circumstance
    //is otherwise impossible, again because of how the UDF works.
    for (let i = prelim.length - 1; i >= 0; i--) {
      const current = prelim[i],
        prev = prelim[i - 1];

      if (current[0] === '"' && prev && prev[prev.length - 1] === '"') {
        prelim = prelim
          .slice(0, i - 1)
          .concat([prev.substr(0, prev.length - 1), ",", current.substr(1)].join(""))
          .concat(prelim.slice(i + 1));
      }
    }

    return prelim
      .map(label => {
        let vals = label.split("|");
        const extras = vals.length - 5;

        //label names may have pipes - if so, we'll get more segments than we should, and they'll always be at the beginning because of how the UDF
        //works.  This code combines them
        if (extras > 0) {
          //don't think it could be negative, but better safe
          vals = [vals.slice(0, extras + 1).join("|")].concat(vals.slice(extras + 1));
        }
        return label ? +(vals[1] || "").trim() : null;
      })
      .filter(id => id);
  }
}

export default NestedLabelsStore;
export const bundleLabelsStore = new NestedLabelsStore({ apiUrl: "learn/labels" });
export const codeLabelsStore = new NestedLabelsStore({ apiUrl: "billing/servicecodes/labels", permissionId: 38 });
export const serviceCodeStore = new NestedLabelsStore({
  transportId: "billingmanager",
  loadAction: "loadlabels",
  saveLabelActionName: "savelabel",
  permissionId: 38
});

export const billingLabelsStore = new NestedLabelsStore({
  transportId: "billingmanager",
  loadAction: "timesheetloadlabels",
  saveLabelActionName: "timesheetSaveLabel",
  permissionId: 36
});
