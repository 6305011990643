import { observable } from "mobx";

let playbackEnforcerSym = Symbol("pe");
export default class VideoPlayer {
  @observable name = "";
  @observable url = "";

  init(element) {
    if (this[playbackEnforcerSym]) {
      this[playbackEnforcerSym].init(element);
    }

    element.addEventListener("timeupdate", evt => {
      if (this[playbackEnforcerSym]) {
        this[playbackEnforcerSym].timeUpdate(element, evt);
      }
      this.videoProgressed(element);
    });

    element.addEventListener("loadedmetadata", evt => {
      this.metaDataLoaded(evt.target.duration);
    });
  }
  set(name, url, playbackEnforcer) {
    this[playbackEnforcerSym] = playbackEnforcer;
    this.name = name;
    this.url = url; //do this before preventSeek so nodes are generated and available for preventSeek handlers to work with
  }
  metaDataLoaded() {}
  videoProgressed() {}
}
