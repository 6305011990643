import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";

const useStyles = makeStyles(theme => ({
  muiChip: {
    height: "32px",
    background: "#eee",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0B1317",
    marginBottom: theme.spacing(1)
  }
}));

interface SimpleChipProps {
  displayValue: string;
  ui: boolean;
  onDelete: any;
  vm: any;
}

export const SimpleChip = observer((props: SimpleChipProps) => {
  const { displayValue, onDelete, ui, vm } = props;
  const classes = useStyles({});

  return <Chip label={ui + ": " + (displayValue || "loading...")} onDelete={() => onDelete(vm)} className={classes.muiChip} />;
});
