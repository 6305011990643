const pause = time => new Promise(resolve => setTimeout(resolve, time));

const defaultOnError = () =>
  cr.domUtil.displayMessage("The file(s) have failed the virus detection process and cannot be uploaded.", { isError: true });

const isPreventUserNavigationDialogOpen = () => !!document.getElementById("prevent-user-navigation-dialog");

const checkFileStatus = async (
  resourceId,
  onError = defaultOnError,
  initialLocation = "",
  pauseTime = 2500,
  timeout = 1000 * 60 * 3,
  numChecks = 0
) => {
  const maxChecks = timeout / pauseTime;

  if (numChecks > maxChecks) {
    throw new Error(`Max number of checks made for resource with id ${resourceId}`);
  }

  initialLocation = initialLocation || window.location.href;

  // If the user has navigated away from the page, cancel pinging
  if (initialLocation !== window.location.href && !isPreventUserNavigationDialogOpen()) {
    throw new Error("User navigated away from page.");
  }

  const response = await cr.transport.api.get(`resources/${resourceId}/upload-status`);

  if (response.error) {
    if (onError) {
      onError();
    }

    throw new Error(`Resource with id '${resourceId}' failed virus scanning.`);
  }

  if (!response.fileUploaded) {
    await pause(pauseTime);
    return checkFileStatus(resourceId, onError, initialLocation, pauseTime, timeout, numChecks + 1);
  }
};

const scanFile = async (resourceId, scanUrl) => {
  await cr.transport.api.post(scanUrl, null, { resourceId });
  return checkFileStatus(resourceId);
};

export default scanFile;
