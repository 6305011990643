import NestedLabelsStore from "app/stores/labels/nestedLabelsStore";

export const labelDeletedMessageName = "___shared-resource-label-deleted___";

export default new NestedLabelsStore({
  loadUrl: "resources/labels",
  loadMethod: "get",
  saveUrl: "resources/labels",
  addUrl: "resources/labels",
  deleteUrl: "resources/labels",
  permissionId: 26,
  labelDeletedMessageName
});
