import { KEY_ESCAPE } from "app/util/constants";
import classNames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { render } from "react-dom";
import { CSSTransitionGroup } from "react-transition-group-v1";
import { PropTypes } from "../core";

const currentModals = observable.array([], { deep: false });

let __uuid = 0;

/** Gets the current maximum zIndex of all known modals, including the contactcard. */
export const getMaxZIndex = () => {
  let maxZIndex = 0;
  $(".modal,#contactcard").each((idx, domEl) => {
    let newMax = Math.max(maxZIndex, Number($(domEl).css("z-index")));
    maxZIndex = isNaN(newMax) ? maxZIndex : newMax;
  });
  return maxZIndex;
};

export type ModalProps = PropTypes.Container & {
  /** Whether or not to show the modal. Default: false */
  show?: boolean;
  /** Callback to call when the modal is hidden. */
  onHide?: () => void;
  /** If true, you are responsible to assign divs with proper classes for modal display. False will wrap given children in default containers. */
  manual?: boolean;
  /** Whether or not to force the modal to be on top of others that might be showing. Default: false */
  forceOnTop?: boolean;
  /** Use as true to set a larger(wider) modal */
  wideModal?: boolean;
  /** Use as true if tabs on the modal stack */
  mobileTabs?: boolean;
};

@observer
export default class Modal extends Component<ModalProps, any> {
  uuid = __uuid++;
  static Header: any;
  static Body: any;
  static Footer: any;
  componentDidMount() {
    if (this.props.show) {
      this.renderModal();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.show && this.props.show) {
      this.renderModal();
    } else if (prevProps.show && !this.props.show) {
      currentModals.remove(this);
    }
  }
  renderModal() {
    currentModals.push(this);
  }
  componentWillUnmount() {
    currentModals.remove(this);
  }
  render() {
    return null;
  }
}

function handleModalWindowClick(evt) {
  // Ensure this isn't a bubbled event
  if (evt.target == evt.currentTarget || $(evt.target.parentElement).attr("aria-label") === "Close") {
    evt.preventDefault();
    evt.stopPropagation();
    closeTopModal();
  }
  evt.nativeEvent.fromModal = true; // allows other event handlers to inspect and know if the click came from a modal or not, such as we don in contactCardStore
}

window.addEventListener("keydown", evt => {
  let key = evt.keyCode || evt.which;
  if (key == KEY_ESCAPE) {
    closeTopModal();
  }
});

function closeTopModal() {
  let modal = currentModals[currentModals.length - 1];
  if (modal && modal.props.onHide) {
    modal.props.onHide();
  }
}

@observer
export class ModalRaw extends Component<ModalProps, any> {
  static Header: any;
  static Body: any;
  static Footer: any;

  render() {
    let { children, className = "", style = {}, show, onHide, manual, forceOnTop, wideModal, mobileTabs, ...rest } = this.props;
    let modalClasses = classNames("modal-dialog", {
      "modal-lg": wideModal,
      "mobile-tabs": mobileTabs
    });

    if (forceOnTop) {
      style.zIndex = getMaxZIndex() + 1;
    }

    className = className.replace(/\bfade\b/g, "");

    return (
      <div className="modal animate" {...rest} tabIndex={-1} role="dialog" style={{ display: "block", ...style }} onClick={handleModalWindowClick}>
        {manual ? (
          children
        ) : (
          <div className={modalClasses} role="document">
            <div className="modal-content">{children}</div>
          </div>
        )}
      </div>
    );
  }
}

@observer
class Header extends Component<any, any> {
  render() {
    let { className = "", ...rest } = this.props;
    return (
      <div className={"modal-header " + className} {...rest}>
        {this.props.children}
      </div>
    );
  }
}
Modal.Header = ModalRaw.Header = Header;

@observer
class Body extends Component<any, any> {
  render() {
    let { className = "", ...rest } = this.props;
    return (
      <div className={"modal-body " + className} {...rest}>
        {this.props.children}
      </div>
    );
  }
}
Modal.Body = ModalRaw.Body = Body;

@observer
class Footer extends Component<any, any> {
  render() {
    let { className = "", ...rest } = this.props;
    return (
      <div className={"modal-footer " + className} {...rest}>
        {this.props.children}
      </div>
    );
  }
}
Modal.Footer = ModalRaw.Footer = Footer;

@observer
class ModalCollection extends Component<any, any> {
  render() {
    return (
      <div>
        <CSSTransitionGroup transitionName="modal" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
          {/*currentModals.map((modal, i) => cloneElement(modal, { key: `modal-${i}` }))*/}
          {currentModals.map((modal, i) => {
            let { children, ...rest } = modal.props;

            return (
              <ModalRaw {...rest} key={`modal-key-${modal.uuid}`}>
                {children}
              </ModalRaw>
            );
          })}
        </CSSTransitionGroup>
        {currentModals.length ? (
          <div key="modal-backdrop" onClick={closeTopModal} className="modal-backdrop simple-react-modal-backdrop fade in" />
        ) : null}
      </div>
    );
  }
}

const modalRoot = document.createElement("div");
document.body.appendChild(modalRoot);
render(<ModalCollection />, modalRoot);
