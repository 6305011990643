export const ALL_DATES_VALUES = Symbol("AllDatesValue");
export const KEY_UP = 38;
export const KEY_DOWN = 40;
export const KEY_ENTER = 13;
export const KEY_LEFT = 37;
export const KEY_RIGHT = 39;
export const KEY_DELETE = 46;
export const KEY_BACKSPACE = 8;
export const KEY_ESCAPE = 27;

export const COLLAPSE_DEFAULT_SPRING = { stiffness: 240, damping: 26 };

export const ALL_DATES__DATE_VALUE = moment().add(2, "years");

export const states = [
  "AK|Alaska",
  "AL|Alabama",
  "AR|Arkansas",
  "AZ|Arizona",
  "CA|California",
  "CO|Colorado",
  "CT|Connecticut",
  "DC|District of Columbia",
  "DE|Delaware",
  "FL|Florida",
  "GA|Georgia",
  "HI|Hawaii",
  "IA|Iowa",
  "ID|Idaho",
  "IL|Illinois",
  "IN|Indiana",
  "KS|Kansas",
  "KY|Kentucky",
  "LA|Louisiana",
  "MA|Massachusetts",
  "MD|Maryland",
  "ME|Maine",
  "MI|Michigan",
  "MN|Minnesota",
  "MO|Missouri",
  "MS|Mississippi",
  "MT|Montana",
  "NC|North Carolina",
  "ND|North Dakota",
  "NE|Nebraska",
  "NH|New Hampshire",
  "NJ|New Jersey",
  "NM|New Mexico",
  "NV|Nevada",
  "NY|New York",
  "OH|Ohio",
  "OK|Oklahoma",
  "OR|Oregon",
  "PA|Pennsylvania",
  "RI|Rhode Island",
  "SC|South Carolina",
  "SD|South Dakota",
  "TN|Tennessee",
  "TX|Texas",
  "UT|Utah",
  "VA|Virginia",
  "VI|Virgin Islands",
  "VT|Vermont",
  "WA|Washington",
  "WI|Wisconsin",
  "WV|West Virginia",
  "WY|Wyoming"
].map(str => {
  return { abbr: str.split("|")[0], name: str.split("|")[1] || "Select" };
});

export const statesWithSelect = [{ abbr: "", name: "Select" }, ...states];

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Anguilla",
  "Antarctica",
  "Antigua And Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote dIvoire",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "England",
  "Equatorial Guinea",
  "Eritrea",
  "Espana",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Great Britain",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (South)",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao Peoples Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federatd States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Ireland",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa (Independent)",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Scotland",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State (Holy See)",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wales",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe"
];
