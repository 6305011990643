import { useMediaQuery, useTheme } from "@material-ui/core";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { DateRange } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeMode";
import { CustomDateRangePickerProps } from "./CustomDatePicker";

export const userDateRangePicker = (props: CustomDateRangePickerProps) => {
    const { TextFieldProps, displayFormat, startDate, endDate, minDate, maxDate, onDatesChange } = props;
    const [textFieldValue, setTextFieldValue] = useState("");
    const [formattedDates, setFormattedDates] = useState({
        startDate: startDate ? moment(startDate).format("MM/DD/YYYY") : null,
        endDate: endDate ? moment(endDate).format("MM/DD/YYYY") : null
    });

    //manipulate the position of the label in the textfield
    const [textFieldShrink, setTextFieldShrink] = useState(false);

    //It is used to do not close the calendar when the button "Custom Range" from CalendarOptions.tsx is clicked.
    const [isCustomRange, setCustomRange] = useState(true);

    //used to display the calendar
    const [anchorEl, setAnchorEl] = useState(null);

    // DayPickerRangeController prop
    const [focusedIn, setfocusedInput] = useState("startDate");

    //Save initialState when the calendar is opened.
    const [initialDateRange, setInitialDateRange] = useState(null);

    // This is used to adjust the calendar responsively and set whether the calendar is horizontal or vertical.
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [longRangeStartDate, setStartDate] = useState(startDate || null);

    const [longRangeEndDate, setEndDate] = useState(endDate || null);

    const [ tempRangeDates, setTempRangeDates] = useState({
        startDate: longRangeStartDate,
        endDate: longRangeEndDate
    });

    //Set Value that is displayed in the text field
    //If "CustomRange is clicked the input wont change"
    const initTextFieldValue = useCallback(() => {
        if (isCustomRange) {
            if (startDate && endDate) {
                setFormattedDates({
                    startDate: moment(startDate).format("MM/DD/YYYY"),
                    endDate: moment(endDate).format("MM/DD/YYYY")
                });
                setTextFieldValue(`${moment(startDate).format(displayFormat)} - ${moment(endDate).format(displayFormat)}`);
                setAnchorEl(null);
            } else if (startDate) {
                setTextFieldValue(`${moment(startDate).format(displayFormat)} -`);
                setFormattedDates({
                    startDate: moment(startDate).format("MM/DD/YYYY"),
                    endDate: null
                });
            } else if (endDate) {
                setTextFieldValue(`- ${moment(endDate).format(displayFormat)}`);
                setFormattedDates({
                    startDate: null,
                    endDate: moment(endDate).format("MM/DD/YYYY")
                });
            }
            else {
                setTextFieldValue(``);
                setFormattedDates({
                    startDate: null,
                    endDate: null
                });
            }
            setTextFieldShrink(true);
        }
        setTempRangeDates({
            startDate: startDate,
            endDate: endDate
        });
    }, [startDate, endDate, displayFormat, isCustomRange]);

    useEffect(() => {
        initTextFieldValue();
    }, [initTextFieldValue, startDate, endDate]);

    // show calendar
    const handlePopoverOpen = event => {
        setTempRangeDates({
            startDate: startDate,
            endDate: endDate
        });
        setInitialDateRange({ startDate, endDate });
        if (TextFieldProps && !TextFieldProps.disabled) {
            setAnchorEl(event.currentTarget);
            setTextFieldShrink(true);
        } else if (!TextFieldProps) {
            setAnchorEl(event.currentTarget);
            setTextFieldShrink(true);
        }
    };

    // close calendar
    const handlePopoverClose = () => {
        setAnchorEl(null);
        if (!textFieldValue) {
            setTextFieldShrink(false);
        }
    };

    // disable the selection of days in the calendar according to the minDate and maxDate props
    const isOutsideRangeCustom = day => {
        if (minDate && maxDate) {
            return day.isBefore(minDate) || day.isAfter(maxDate);
        }

        if (minDate) {
            return day.isBefore(minDate);
        }

        if (maxDate) {
            return day.isAfter(maxDate);
        }

        return false;
    };

    // mask the input so the textfield autoformats to date MM/DD/YYYY and only allows numbers and slashes
    const maskDateInput = event => {
        const key = event.key || event.keyCode;
        if (key <= 47 || key > 57 || key === "/") {
            //only numbers and slashes
            const { length } = event.target.value;

            // let user input the slash
            if (length !== 1 || length !== 3) {
                if (key === 47 || key === "/") {
                    event.preventDefault();
                }
            }
            // or add it for them if they didn't
            if (length === 2) {
                // eslint-disable-next-line no-param-reassign
                event.target.value += "/";
            }
            if (length === 5) {
                // eslint-disable-next-line no-param-reassign
                event.target.value += "/";
            }
        } else {
            event.preventDefault();
        }
    };

    // set dates from text inputs
    const onTextInputDateChange = (date: string, dateInput: React.ChangeEvent<HTMLInputElement>) => {
        const { length } = dateInput.target.value;

        let inputStartDate: string | Moment = date === "start" ? dateInput.target.value : formattedDates.startDate;
        let inputEndDate: string | Moment = date === "end" ? dateInput.target.value : formattedDates.endDate;
        setFormattedDates({
            startDate: inputStartDate,
            endDate: inputEndDate
        });

        if (length === 10) {
            inputStartDate = moment(inputStartDate, "MM-DD-YYYY");
            inputEndDate = moment(inputEndDate, "MM-DD-YYYY");
            const set = {
                startDate: moment(inputStartDate, "MM-DD-YYYY").isValid() ? inputStartDate : null,
                endDate: inputEndDate.isValid() ? inputEndDate : null
            };
            if (isCustomRange) {
                // eslint-disable-next-line no-use-before-define
                updateTempDateRange(set);
            }
        }
    };

    const updateTempDateRange = (dateRange: DateRange) => {
        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
        setTempRangeDates({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        });
        setFormattedDates({
            startDate: dateRange.startDate ? moment(dateRange.startDate).format("MM/DD/YYYY") : null,
            endDate: dateRange.endDate ? moment(dateRange.endDate).format("MM/DD/YYYY") : null
        });
    };
    const setIsCustomRange = (customRange: boolean) => {
        setCustomRange(customRange);
        setStartDate(startDate);
        setEndDate(endDate);
        setFormattedDates({
            startDate: startDate ? moment(startDate).format("MM/DD/YYYY") : null,
            endDate: endDate ? moment(endDate).format("MM/DD/YYYY") : null
        });
    };
    const onReset = () => {
        setTempRangeDates({
            startDate: startDate,
            endDate: endDate
        });
        setFormattedDates({
            startDate: startDate?.format("MM/DD/YYYY"),
            endDate: endDate?.format("MM/DD/YYYY")
        });
    };
    return {
        anchorEl,
        formattedDates,
        onTextInputDateChange,
        maskDateInput,
        textFieldValue,
        textFieldShrink,
        focusedIn,
        setfocusedInput,
        handlePopoverOpen,
        handlePopoverClose,
        isSmallScreen,
        isOutsideRangeCustom,
        setIsCustomRange,
        isCustomRange,
        tempRangeDates,
        updateTempDateRange,
        onReset
    };
};