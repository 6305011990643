/* tslint:disable:no-parameter-reassignment */ // TODO: Fix these lint violations
import React, { cloneElement, Component, ReactNode, useEffect } from "react";
import { action, observable } from "mobx";
import { IModalBacking, modalBacking } from "app/util/bootstrapHelpers/decorators";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react";
import { render } from "react-dom";
import Modal from "./modal";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import MuiButton from "../../util/muiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

const currentConfirms = observable.array([], { deep: false });
const crConfirmHome = document.createElement("div");
document.body.appendChild(crConfirmHome);

let __uuid = 0;

@observer
class CrConfirmList extends Component<any, any> {
  render() {
    return <div>{currentConfirms.map(crConfirm => cloneElement(crConfirm, { key: "cr-confirm-" + crConfirm.props["data-key"] }))}</div>;
  }
}

render(<CrConfirmList />, crConfirmHome);

export const showConfirmDialog = (isShowing: boolean, title?: string, body?: string, cb?: (val: boolean) => void) =>
  render(<MaterialDialog {...{ isShowing, title, body, cb }} />, crConfirmHome);

interface ConfirmButtonOptions extends React.HTMLAttributes<HTMLButtonElement> {
  buttonContent: ReactNode;
  buttonValue: boolean;
  success: boolean;
}

interface ConfirmButtonProps extends ConfirmButtonOptions {
  store: ModalStore;
}

const ConfirmButton: any = observer<any>(({ store, buttonContent, buttonValue, success, ...props }) => (
  <button {...props} onClick={() => store.answer(buttonValue, success)}>
    {buttonContent}
  </button>
));

const ConfirmUiButton: any = observer<any>(({ store, buttonContent, buttonValue, success, ...other }) => {
  const props = {
    ...other,
    children: buttonContent
  };
  return <MuiButton {...props} onClick={() => store.answer(buttonValue, success)} />;
});

interface CRRichConfirmOptions {
  contents: ReactNode;
  caption: ReactNode;
  buttons?: (ConfirmButtonOptions | ButtonProps)[];
  resolve?: (value: any) => any;
  reject?: (value: any) => any;
  hideValue?: any;
  hideSuccess?: boolean;
  onHide?: () => any;
  mui?: boolean;
  maxWidth?: "sm" | "xs" | "md" | "lg" | "xl";
}

@modalBacking
class ModalStore implements CRRichConfirmOptions {
  contents: ReactNode | string;
  caption: ReactNode;
  buttons?: (ConfirmButtonOptions | ButtonProps)[];
  resolve?: (value: any) => any;
  reject?: (value: any) => any;
  hideValue?: any;
  hideSuccess?: boolean;
  unRender: any;
  onHide?: () => any;
  finished = () => {
    // tslint:disable-next-line:no-unused-expression
    typeof this.onHide === "function" && this.onHide();
    this.unRender && this.unRender();
  };

  constructor(options: CRRichConfirmOptions) {
    Object.assign(this, {
      ...options,
      resolve: typeof options.resolve == "function" ? options.resolve : () => undefined,
      reject: typeof options.reject == "function" ? options.reject : () => undefined
    });

    let instance = options.mui ? (
      <ThemeProvider skipGlobalStyles>
        <Dialog open={true} maxWidth={options.maxWidth || "sm"} onClose={this.finished} disableBackdropClick fullWidth>
          <DialogTitle style={{ margin: 0 }}>
            {this.caption || "Confirm"}
            <IconButton
              aria-label="close"
              onClick={this.finished}
              style={{
                position: "absolute",
                right: 8,
                top: 10
              }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: "1.25rem" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ marginTop: 20, marginBottom: 54 }}>
            <DialogContentText>
              {typeof this.contents === "string" ? (
                <span dangerouslySetInnerHTML={{ __html: this.contents }} />
              ) : React.isValidElement<any>(this.contents) && typeof this.contents.type !== "string" ? (
                React.cloneElement(this.contents, { confirmStore: this })
              ) : (
                this.contents
              )}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions style={{ padding: 0 }}>
            {this.buttons && this.buttons.length
              ? this.buttons.map((button, index) => <ConfirmUiButton {...button} store={this} key={`confirm-button-${index}`} />)
              : null}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    ) : (
      <Modal data-key={__uuid++} show={true} onHide={this.finished} className="fade">
        <Modal.Header>
          <div className="padding-left padding-right">
            <button
              onClick={() =>
                this.answer(this.hideValue !== undefined ? this.hideValue : false, this.hideSuccess !== undefined ? this.hideSuccess : false)
              }
              type="button"
              className="close"
            >
              &times;
            </button>
            <h2 className="title modal-title">{this.caption || "Confirm"}</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          {typeof this.contents === "string" ? (
            <p dangerouslySetInnerHTML={{ __html: this.contents }} />
          ) : React.isValidElement<any>(this.contents) && typeof this.contents.type !== "string" ? (
            React.cloneElement(this.contents, { confirmStore: this })
          ) : (
            this.contents
          )}
        </Modal.Body>
        <Modal.Footer>
          {this.buttons && this.buttons.length
            ? this.buttons.map((button, index) => <ConfirmButton {...button} store={this} key={`confirm-button-${index}`} />)
            : null}
        </Modal.Footer>
      </Modal>
    );
    currentConfirms.push(instance);
    this.unRender = () => currentConfirms.remove(instance);
  }

  @action
  answer = (value: any, success: boolean) => {
    (this as any as IModalBacking).modalHide();
    (success ? this.resolve : this.reject)(value);
    // tslint:disable-next-line:no-unused-expression
    this.unRender && this.unRender();
  };
}

// fixme: rewrite this as a call to dialog

export function CRRichConfirm(options: CRRichConfirmOptions) {
  // tslint:disable-next-line:no-unused-expression
  new ModalStore(options);
}

export function CRConfirm(
  caption?: ReactNode,
  contents?: ReactNode,
  resolve?: (result: any) => void,
  reject?: (result: any) => void,
  confirmButtonText?: ReactNode,
  cancelButtonText?: ReactNode
);
export function CRConfirm(
  caption?: ReactNode,
  resolve?: (result: any) => void,
  reject?: (result: any) => void,
  confirmButtonText?: ReactNode,
  cancelButtonText?: ReactNode
);
export function CRConfirm(caption?, contents?, resolve?, reject?, confirmButtonText = "Yes", cancelButtonText = "No") {
  if (typeof contents === "function") {
    reject = resolve;
    resolve = contents;
    contents = caption;
    caption = "Confirm";
  }

  return CRRichConfirm({
    caption,
    resolve,
    reject,
    contents,
    buttons: [
      {
        buttonContent: confirmButtonText,
        className: "btn btn-primary yes-button pull-left",
        buttonValue: true,
        success: true
      },
      {
        buttonContent: cancelButtonText,
        className: "btn btn-default no-button",
        buttonValue: false,
        success: false
      }
    ]
  });
}

export function CRAlert(caption?, contents?, resolve?, reject?) {
  return CRRichConfirm({
    caption,
    resolve,
    reject,
    contents: <p>{contents}</p>,
    hideSuccess: true,
    buttons: [
      {
        buttonContent: "OK",
        className: "btn btn-primary yes-button pull-left",
        buttonValue: true,
        success: true
      }
    ]
  });
}

export interface DialogProps<TResolve> {
  resolve?: (value?: TResolve | PromiseLike<TResolve>) => void;
  reject?: (reason?: any) => void;
  dismiss?: () => void;
  "data-key"?: number;
}

export function dialog<TResolve, TProps extends DialogProps<TResolve>>(component: React.ReactElement<TProps>): Promise<TResolve> {
  return new Promise((promiseResolve, promiseReject) => {
    let dismiss = () => currentConfirms.remove(dialogComponent);

    let resolve: (value?: TResolve | PromiseLike<TResolve>) => void = value => {
      dismiss();
      return promiseResolve(value);
    };

    let reject: (reason?: any) => void = reason => {
      dismiss();
      return promiseReject(reason);
    };

    let dialogComponent = React.cloneElement(component, {
      resolve,
      reject,
      dismiss,
      "data-key": __uuid++
    } as Partial<TProps> & React.Attributes);
    currentConfirms.push(dialogComponent);
  });
}

/**
 * A simple confirm dialog using Material UI,
 */
export function MaterialDialog({ isShowing, title, body, cb }: { isShowing: boolean; title?: string; body?: string; cb?: (val: boolean) => void }) {
  const [open, setOpen] = React.useState(false),
    handleClose = (response: boolean) => cb?.(response);

  useEffect(() => setOpen(isShowing), [isShowing]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
