import { Popper } from "@material-ui/core";
import { KEY_DOWN, KEY_ENTER, KEY_ESCAPE, KEY_UP } from "app/util/constants";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";

let uid = 1;

@observer
class CrSingleSelectResult extends Component {
  doMouseMove = evt => {
    this.props.onMouseMove(evt, this.props.result);
  };
  doClick = evt => {
    this.props.onClick(this.props.result);
  };
  render() {
    let { result, formatResult, highlighted, disabled = false, onMouseMove, onClick, render } = this.props;

    return (
      <li
        onMouseMove={this.doMouseMove}
        onClick={
          disabled
            ? evt => {
                evt.preventDefault();
              }
            : this.doClick
        }
        className={`select2-result ${disabled ? "select2-result-disabled" : "select2-result-selectable"} ${
          highlighted && !disabled ? "select2-highlighted" : ""
        } `}
        role="presentation"
      >
        {render ? (
          render()
        ) : (
          <div
            className={`select2-result-label ${disabled ? "select2-result-label-disabled" : ""}`}
            role="option"
            dangerouslySetInnerHTML={{ __html: formatResult(result) }}
          />
        )}
      </li>
    );
  }
}

const firstDefined = (...items) => items.find(item => typeof item !== "undefined");

@observer
export default class CrSingleSelect extends Component {
  autoId = `s2id_autogen${uid++}`;
  @observable.ref selectedItem = null;
  lastMouseX = null;
  lastMouseY = null;

  get safeResults() {
    return this.props.results || [];
  }
  optionUp() {
    let index = this.safeResults.indexOf(this.selectedItem);
    if (index > 0) {
      this.selectedItem = this.safeResults[index - 1];

      let el = findDOMNode(this[`resultAt${index - 1}`]),
        parent = el.parentNode;

      let deltaMissing = parent.scrollTop - el.offsetTop;
      if (deltaMissing > 0) {
        parent.scrollTop -= deltaMissing;
      }
    } else if (index === 0) {
      let topHelper = this.helperContentEl0;
      if (topHelper) {
        let el = findDOMNode(topHelper),
          parent = el.parentNode;

        let deltaMissing = parent.scrollTop - el.offsetTop;
        if (deltaMissing > 0) {
          parent.scrollTop -= deltaMissing;
        }
      }
    }
  }
  optionDown() {
    let index = this.safeResults.indexOf(this.selectedItem);
    if (index < this.safeResults.length - 1) {
      this.selectedItem = this.safeResults[index + 1];

      let el = findDOMNode(this[`resultAt${index + 1}`]),
        parent = el.parentNode;

      let deltaMissing = el.offsetTop + el.offsetHeight - parent.offsetHeight - parent.scrollTop;
      if (deltaMissing > 0) {
        parent.scrollTop += deltaMissing;
      }
    }
  }
  itemHover = (evt, result) => {
    if (evt.screenX == this.lastMouseX && evt.screenY == this.lastMouseY) {
      return;
    }
    this.lastMouseX = evt.screenX;
    this.lastMouseY = evt.screenY;

    this.selectedItem = result;
  };
  itemClick = result => {
    this.selectedItem = result;
    this.selectCurrent();
  };
  inputClicked = evt => {
    if (this.props.searchOpen) return;
    if (this.props.disabled) return;
    if (evt.target.classList.contains("select2-search-choice-close")) return;
    this.props.inputClicked();
  };
  selectCurrent() {
    this.props.selectItem(this.selectedItem);
  }
  componentDidMount() {
    $(window).on(`click.${this.autoId}`, evt => {
      let root = findDOMNode(this);
      if (root.contains(evt.target)) {
        return;
      } else if (this.props.searchOpen) {
        if (evt.target.parentNode.className.includes("select2-result-disabled")) return;
        this.props.outsideClick();
      }
    });
    $(window).on(`keydown.${this.autoId}`, evt => {
      let key = evt.keyCode || evt.which;

      if (this.props.searchOpen) {
        if (key == KEY_UP && this.selectedItem) {
          this.optionUp();
          try {
            evt.preventDefault();
          } catch (e) {}
        } else if (key == KEY_DOWN) {
          this.optionDown();
        } else if (key == KEY_ENTER && this.selectedItem) {
          this.selectCurrent();
        } else if (key == KEY_ESCAPE) {
          this.props.outsideClick();
        }
      }
    });
    this.container.parents().add(window).on("resize scroll orient", this.positionDropdown);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.searchOpen && this.props.searchOpen) {
      this.searchBox.focus();
    }
    if (prevProps.searchOpen && !this.props.searchOpen) {
      this.searchBox.blur();
    }

    let { results: currentResults } = this.props;

    if (this.props.searching) {
      this.selectedItem = null;
    } else if (prevProps.results !== currentResults) {
      if (currentResults && currentResults.length) {
        this.selectedItem = currentResults[0];
      }
    }
    this.positionDropdown();
  }

  positionDropdown = () => {
    if (!this.dropdown || !this.container) {
      return;
    }
    // The below partially inspired by positionDropdown in http://ivaynberg.github.io/select2/
    // Ours is simpler because our dropdown is in the tree rather than appended separatley to body.
    let offset = this.container.offset(),
      height = this.container.outerHeight(false),
      dropHeight = this.dropdown.outerHeight(false),
      $window = $(window),
      windowWidth = $window.width(),
      windowHeight = $window.height(),
      viewPortRight = $window.scrollLeft() + windowWidth,
      viewportBottom = $window.scrollTop() + windowHeight,
      dropTop = offset.top + height,
      dropLeft = offset.left,
      enoughRoomBelow = dropTop + dropHeight <= viewportBottom,
      enoughRoomAbove = offset.top - dropHeight >= $window.scrollTop(),
      dropWidth = this.dropdown.outerWidth(false),
      enoughRoomOnRight = dropLeft + dropWidth <= viewPortRight,
      css = {};

    // Always prefer the current above/below alignment, unless there is not enough room
    if (this.dropAbove) {
      this.dropAbove = enoughRoomAbove;
    } else {
      this.dropAbove = !enoughRoomBelow && enoughRoomAbove;
    }

    // Shift us over if we're extending off the right
    if (!enoughRoomOnRight) {
      //css.left = offset.left + this.container.outerWidth(false) - dropWidth;
    }

    this.dropdown.css(css);
  };

  setSearchTerm = evt => {
    this.props.setSearchTerm(evt.target.value);
  };
  componentWillUnmount() {
    $(window).off(`.${this.autoId}`);
    this.container.parents().add(window).off("resize scroll orient", this.positionDropdown);
  }
  render() {
    let searchBoxContents = null;

    let {
      searching,
      minSearchLength,
      searchTerm,
      results,
      formatResult,
      className,
      searchAreaContent = null,
      usePopper = false,
      disabledElementIds = [],
      disabled = false
    } = this.props;

    if (!searching) {
      if (results && results.length) {
        searchBoxContents = results.map((result, i) => (
          <CrSingleSelectResult
            key={firstDefined(result.key, result[this.props.resultKey], result["id"], result["Id"])}
            ref={el => (this[`resultAt${i}`] = el)}
            result={result}
            onClick={this.itemClick}
            onMouseMove={this.itemHover}
            highlighted={result == this.selectedItem}
            disabled={disabledElementIds.includes(result["id"])}
            formatResult={formatResult}
            render={result.render}
          />
        ));
      } else if (results && !results.length) {
        searchBoxContents = (
          <li key="select2-no-results" className="select2-no-results">
            No matches found
          </li>
        );
      }
    } else {
      searchBoxContents = (
        <li key="select2-no-results" className="select2-no-results">
          Searching&nbsp;&nbsp;&nbsp;
          <i className="fa fa-fq fa-spinner fa-spin" />
        </li>
      );
    }

    if (!Array.isArray(searchBoxContents)) {
      searchBoxContents = [searchBoxContents];
    }

    let classesForContainer = [this.props.selectedTerm && this.props.onClear ? "select2-allowclear" : ""].filter(s => s).join(" ");

    let placeholderText =
      this.props.searchOpen && this.props.minSearchLength !== 0 ? `Please enter ${minSearchLength} more characters...` : this.props.placeholder;

    return (
      <div className="relative crSelect2" style={this.props.style || {}}>
        <div
          ref={el => (this.container = $(el))}
          onClick={this.inputClicked}
          className={`select2-container ${classesForContainer} ${className}` + (this.props.searchOpen ? "select2-dropdown-open" : "")}
        >
          {this.props.selectedTerm}
        </div>
        <div
          ref={el => (this.dropdown = $(el))}
          className={"select2-drop select2-with-searchbox select2-drop-active" + (this.props.selectedTerm ? " select2-selectedTerm" : "")}
          style={{ display: "block", position: "absolute" }}
        >
          <div className="select2-search">
            {this.props.selectedTerm ? <abbr onClick={this.props.onClear} className="select2-close" /> : null}
            <label htmlFor="s2id_autogen2_search" className="select2-offscreen" />
            <input
              ref={el => (this.searchBox = el)}
              tabIndex={-1}
              value={this.props.selectedTerm ? this.props.selectedTerm : this.props.searchTerm}
              onClick={this.inputClicked}
              onFocus={this.inputClicked}
              onChange={this.setSearchTerm}
              type="text"
              autoComplete="new-password"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              className="select2-input"
              role="combobox"
              aria-expanded="true"
              aria-autocomplete="list"
              id="s2id_autogen2_search"
              placeholder={placeholderText}
              disabled={disabled}
            />
          </div>
          {usePopper ? (
            <Popper
              open={this.props.searchOpen}
              anchorEl={this.container?.[0]}
              style={{ backgroundColor: "#fff", width: this.container?.width() || "300px", marginTop: "4px", fontSize: "13px" }}
            >
              <ul className={(this.props.searchOpen ? "select2-open" : "") + " select2-results"} role="listbox" style={{ position: "relative" }}>
                {this.props.searchOpen && this.props.searchAreaContents
                  ? this.props.searchAreaContents.map((item, i) => (
                      <li key={`helperContentEl${i}`} ref={el => (this[`helperContentEl${i}`] = el)} className="select2-result">
                        {item}
                      </li>
                    ))
                  : null}
                {searchBoxContents}
              </ul>
            </Popper>
          ) : (
            <ul className={(this.props.searchOpen ? "select2-open" : "") + " select2-results"} role="listbox" style={{ position: "relative" }}>
              {this.props.searchOpen && this.props.searchAreaContents
                ? this.props.searchAreaContents.map((item, i) => (
                    <li key={`helperContentEl${i}`} ref={el => (this[`helperContentEl${i}`] = el)} className="select2-result">
                      {item}
                    </li>
                  ))
                : null}
              {searchBoxContents}
            </ul>
          )}
        </div>
        {this.props.validationErrorMessage ? (
          <span style={{ position: "absolute", bottom: "-20px", left: 0 }} className="error">
            {this.props.validationErrorMessage}
          </span>
        ) : null}
      </div>
    );
  }
}

CrSingleSelect.defaultProps = {
  minSearchLength: 3,
  placeholder: "Search",
  className: ""
};
