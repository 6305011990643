import { mappable } from "app/util/mobx-util";
import { computed } from "mobx";
import filesize from "third_party_libraries/filesize/filesize.min";

@mappable
export default class ExistingResource {
  id = 0;
  name = "";
  fileName = "";
  size = 0;
  data = null;
  visibleToClient = false;
  get requiresUpload() {
    return false;
  }
  get invalid() {
    return false;
  }
  
  @computed
  get humanSize() {
    return filesize(this.size);
  }

  reset() {}

  @computed
  get iconClass() {
    let pieces = (this.fileName || "").split(".");

    let extension = pieces.length < 2 ? "" : pieces[pieces.length - 1].toLowerCase();

    if (extension == "pdf") {
      return "fa-file-pdf";
    }

    if (["avi", "wmv", "flv", "asf", "m4v", "mov", "mp4", "m4a", "3gp", "3g2", "mj2"].indexOf(extension) >= 0) {
      return "fa-file-video";
    }

    if (["jpg", "jpeg", "gif", "png"].indexOf(extension) >= 0) {
      return "fa-file-image";
    }

    return "fa-file";
  }
}
