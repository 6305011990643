import React, { createElement } from "react";
import classes from "classnames";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import {
    Box, Button, Checkbox, Chip, CircularProgress,
    Grid, IconButton, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import AdvancedSearch from "app/components/select/advancedSearch";
import { searchVmMap as searchMap} from "../../../resources/list/searchVm";
import { FileExplorerSourceEnum, FilesExplorerStore } from "../FilesExplorerStore";
import { CustomDatePicker } from "shared/CustomDatePicker/CustomDatePicker";
import { creationDateRangeModes } from "shared/CustomDatePicker/CustomCalendarDateRangeModes";
import { NoteTemplateFilter } from "../../../resources/list/components/ResourcesHeader";
import { InlineLoading } from "app/components/util/loading";
import MultiFileUpload, {defaultIcon, iconMap } from "app/components/upload/fileUploadMulti";
import { ChevronRightIcon } from "@cr/icons/ChevronRight";
import { RemoveRedEyeIcon } from "@cr/icons/RemoveRedEye";
import { VisibilityOffIcon } from "@cr/icons/VisibilityOff";
import { DeleteOutlineIcon } from "@cr/icons/DeleteOutline";
import { AttachFileIcon } from "@cr/icons/AttachFile";
import Resource from "resources/list/models/resource";
import { preview } from "app/components/resources/preview";
import { ButtonProps } from "@material-ui/core/Button/Button";
import { ChevronLeftIcon } from "@cr/icons/ChevronLeft";
import { FileUploadMultiStore } from "app/stores/upload/fileUploadMulti";
import { Alert } from "@material-ui/lab";

const iconSize = 18;
const useStyles = makeStyles({
    dialogx: {
        padding: 0,
        zIndex: "1040 !important" as any
    },
    dialogPaper: {
        minHeight: '90vh !important',
        maxHeight: '90vh',
        padding: 0
    },
    dialogContent: {
        padding: "0px 12px 0px 12px"
    },
    dialogActions: {
        padding: 12
    },
    title: {
        padding: "12px 24px",
        margin: 0,
        borderBottom: "1px solid #E0E0E0",
        "& h2": {
            fontSize: 20,
            fontWeight: 400
        }
    },
    alert: {
        marginTop: 12
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 600,
        padding: 12
    },
    dropzone: {
        margin: "0px 24px",
        "& > div > div": {
            paddingTop: "0 !important",
            marginBottom: 12,
            "& > div > span > div": {
                fontSize: 14,
                fontWeight: 600
            }
        }
    },
    filters: {
        paddingBottom: 12
    },
    searchBox: {
        backgroundColor: "#F2F2F2"
    },
    searchBoxChild: {
        padding: "0px 24px 12px 24px"
    },
    grid: {
        alignItems: "flex-start",
        "& > div": {
            paddingRight: 24,
            paddingBottom: 6
        }
    },
    table: {
        backgroundColor: "#fff"
    },
    tableCellHeader: {
        padding: "6px 10px !important"
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#EBEEF2"
        }
    },
    tableCell: {
        padding: "6px 10px !important",
        cursor: "pointer"
    },
    date: {
        width: 250,
        "& > div": {
            background: "#fff"
        },
        "& .MuiFormLabel-root": {
            fontSize: 14,
        },
        '& .MuiInputBase-input': {
            padding: 10,
            fontSize: 14
        }
    },
    calendar: {
        '& .CalendarDay__blocked_out_of_range': {
            color: "#cacccd !important"
        }
    },
    smallSearch: {
        padding: 0,
        marginLeft: 0,
        position: "relative",
        zIndex: 100,
        "& .select2-search": {
            height: "37px !important",
            border: "1px solid #c4c4c4",
            borderRadius: 4
        },
        "& .fa-search": {
            left: 12,
            top: 12,
            zIndex: 2000
        },
        "& .select2-search input": {
            marginTop: "3px !important"
        },
        "& .select2-drop": {
            zIndex: "1301 !important"
        },
        "& .select2-results": {
            fontSize: 12,
            lineHeight: "16px"
        }
    },
    smallSearchTimesheets: {
        padding: 0,
        marginLeft: 0,
        position: "relative",
        zIndex: 100,
        "& .select2-search": {
            height: "48px !important",
            border: "1px solid #c4c4c4",
            borderRadius: 4,
            padding: "4px 0 0 40px !important"
        },
        "& .fa-search": {
            left: 12,
            top: 14,
            zIndex: 2000
        },
        "& .select2-search input": {
            marginTop: "3px !important"
        },
        "& .select2-drop": {
            zIndex: "1301 !important"
        },
        "& .select2-results": {
            fontSize: 12,
            lineHeight: "16px"
        }
    },
    filesList: {
        "& .file-upload-file": {
            borderTop: "1px solid #E0E0E0",
            padding: 6
        }
    },
    pagingBox: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: 16,
        alignItems: "center",
        background: "#fff"
    },
    pagingDropDown: {
        "&:before": {
            border: "none !important"
        }
    },
    pagingText: {
        color: "#666"
    },
    pagingButtons: {
        color: "#666"
    },
    error: {
        flex: "100%",
        color: "#E51740",
        fontSize: 14
    }
});

export const FilesExplorer = observer(({
    source,
    onUploadFilesSelected,
    buttonProps,
    fileSettings,
    client,
    maxFiles,
    uploadLoader
}: { 
    source: FileExplorerSourceEnum;
    onUploadFilesSelected: (files, store) => Promise<void>;
    buttonProps?: ButtonProps;
    fileSettings?: any;
    useButton?: boolean;
    tooltip?: string;
    client?: number;
    maxFiles?: number;
    uploadLoader?: React.ReactNode;
}) => {

    const [ showModal, setShowModal ] = React.useState(false);
    const [ store ] = React.useState(new FilesExplorerStore(source, fileSettings, client, maxFiles));

    const handleShowingModal = (show: boolean) => {
        setShowModal(show);
        if (show)  {
            store.init();
        }
        else {
            store.clear();
        }
    }
    
    const onSubmit = async (files, store) => {
        store.uploadLoading = true;
        setShowModal(false);
        await onUploadFilesSelected(files, store);
        store.clear();
    }
    
    return <ThemeProvider skipGlobalStyles>
        {store.uploadLoading ? (uploadLoader ?? <CircularProgress size={iconSize}/>) : <Button
                fullWidth={false}
                onClick={() => handleShowingModal(true)}
                variant={"outlined"}
                startIcon={<AttachFileIcon />}
                children={"Attach file(s)"}
                {...buttonProps}
            />}
            <FilesModal show={showModal} onHide={() => handleShowingModal(false)} store={store} onUploadFilesSelected={onSubmit} />
        </ThemeProvider>;
});

const FilesModal = observer(({
    show,
    onHide,
    store,
    onUploadFilesSelected
}: {
    show: boolean;
    onHide: () => void;
    store: FilesExplorerStore;
    onUploadFilesSelected: (files, store) => void;
}) => {
    
    const customClasses = useStyles();
    const advSearchOptions = {
        searchResultsMap: searchMap,
        placeholder: "Search files...",
        maxResults: "10",
        call: "resources.lookupactiveresourcesmodalsearch",
        helpInfo: {
            searchProps: ["resourcelabel", "contact"],
            searchHistoryKey: "resources.list.modal"
        },
        suppressText: false
    };

    const onUploadSelected = evt => {
        onUploadFilesSelected(store.multiFileUploadStore.files, store);
    };
    
    return <Dialog
        classes={{ root: customClasses.dialogx, paper: customClasses.dialogPaper }}
        open={show}
        onClose={onHide}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle className={customClasses.title}>
            {store.maxFiles === 1 ? "Attach file" : "Attach files"}
        </DialogTitle>
        <DialogContent className={customClasses.dialogContent}>
            {store.maxFiles === 1 ? <Alert severity={"info"} className={customClasses.alert}><b>Note:</b> Only one attachment may be added per payment entry. Any file you attach will replace the existing attachment.</Alert> : null}
            <Box style={{ position: "relative" }}>
                {store.maxFiles > 0 && store.maxFiles === store.multiFileUploadStore.files.length ? <Box style={{ position: "absolute", width: "100%", height: "100%", zIndex: 1000 }}></Box> : null}
                <Box>
                <Typography className={customClasses.subtitle}>{store.maxFiles === 1 ? "Upload New File" : "Upload New File(s)"}</Typography>
                <MultiFileUpload
                    store={store.multiFileUploadStore} 
                    className={customClasses.dropzone}
                    data-fix-for-collapse-to-sync-with-files={store.multiFileUploadStore.files.slice().length}
                    showFiles={false}
                    label={store.maxFiles === 1 ? "Drop file here to upload" : "Drop file(s) here to upload"}
                    labelSelectFiles={store.maxFiles === 1 ? "CHOOSE FILE" : "CHOOSE FILES"}
                />
            </Box>
                <Box className={customClasses.searchBox}>
                <Typography className={customClasses.subtitle}>{store.maxFiles === 1 ? "Search and Attach Existing File" : "Search and Attach Existing Files"}</Typography>
                <Box className={customClasses.searchBoxChild}>
                <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={customClasses.grid}
                >
                    <Grid item xs={4}>
                        <div className={classes('module-search', store.source === FileExplorerSourceEnum.Timesheets || store.source === FileExplorerSourceEnum.Billing ? customClasses.smallSearchTimesheets : customClasses.smallSearch)}>
                            <i className="far fa-fw fa-2x fa-search"/>
                            <AdvancedSearch
                                searchResultIsValid={() => true}
                                itemSelected={result => store.handleTextSearchSelection(result)}
                                {...advSearchOptions}
                            />
                        </div>
                        <Typography className={customClasses.error}>{!store.isValidContact ? "\"Owned by Client\" files are limited to the Client of the timesheet" : null}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <NoteTemplateFilter
                            options={store.templatesResults}
                            searchKey={store.templateSearchKey}
                            clearSearch={store.clearSearch.bind(store)}
                            loadSearchableNotes={store.loadSearchableNotes.bind(store)}
                            onChange={(_, value) => store.onTemplateChange(value)}
                            loading={store.loadingNoteTemplates}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex", flexWrap: "wrap" }}>
                        <CustomDatePicker
                            startDate={store.startDate}
                            endDate={store.endDate}
                            onDatesChange={store.onDatesChange}
                            displayFormat="MM/DD/YYYY"
                            displayAdvancedOptions={true}
                            displayPopupInputFields={true}
                            customOptions={creationDateRangeModes}
                            isCustomRange={true}
                            minDate={store.archivedDate ? store.archivedDate.endOf('day') : null}
                            TextFieldProps={{
                                className: customClasses.date,
                                placeholder: "Date",
                                label: "Date files created",
                                error: !store.isValidDateRange,
                                inputProps: {
                                    "data-testid": "resources-modal-date"
                                },
                            }}
                            PopoverProps={{
                                className: customClasses.calendar
                            }}
                        />
                        <Typography className={customClasses.error}>{!store.isValidDateRange ? store.dateValidationMessage : null}</Typography>
                    </Grid>
                </Grid>
                <Filters store={store} />
                    <Table className={customClasses.table}>
                        <TableHead>
                            <TableRow>
                                {store.columns.map (c => <TableCell key={`column_${c.id}`} className={customClasses.tableCellHeader}>{c.label}</TableCell>)}
                            </TableRow>
                        </TableHead>
                            {!store.loading && store.resources.length > 0 ? <TableBody>
                            {store.resources.map(r => <TableRow key={`resource_${r.id}`} className={customClasses.tableRow} onClick={() => store.addFile(r)}>
                                {store.columns.map(c => <TableCell key={`resource_${r.id}_${c.id}`} className={customClasses.tableCell}>{c.isDate ? moment(r[c.id]).format("MM/DD/YYYY") : r[c.id]}</TableCell>)}
                            </TableRow>)}
                        </TableBody> : <TableBody style={{height: 100}}>
                                <TableRow>
                                    <TableCell colSpan={store.columns.length} className={customClasses.tableCell}>
                                        {store.loading ? <InlineLoading/> : null}
                                    </TableCell>
                                </TableRow>
                        </TableBody>}
                    </Table>
                    <Paging store={store} />
                </Box>
            </Box>
            </Box>
            <Files store={store.multiFileUploadStore} source={store.source} maxFiles={store.maxFiles} />
        </DialogContent>
        <DialogActions className={customClasses.dialogActions}>
            <Button
                data-testid={"resources-modal-cancel-btn"}
                onClick={onHide}
            >
                Cancel
            </Button>
            <Button
                data-testid={"resources-modal-confirm-btn"}
                disabled={!store.canAddFiles}
                variant={"contained"}
                onClick={onUploadSelected}
            >
                {store.maxFiles === 1 ? "Add File" : "Add File(s)"}
            </Button>
        </DialogActions>
    </Dialog>;
});


const Filters = observer(({
    store
}: {
    store: FilesExplorerStore
}) => {

    const customClasses = useStyles();
    
    return <Box className={customClasses.filters}>
        {store.search ? <Chip label={`Text Search: ${store.search.name}`} onDelete={() => store.removeSearch()} /> : null}
        {store.template ? <Chip label={`Template: ${store.template.name}`} onDelete={() => store.removeTemplate()} /> : null}
        {store.resource ? <Chip label={`Resource: ${store.resource.name}`} onDelete={() => store.removeResource()} /> : null}
        {store.contact ? <Chip label={`Owned By: ${store.contact.name}`} onDelete={store.canDeleteContact ? () => store.removeContact() : null} /> : null}
        {store.createdbycontact ? <Chip label={`Created By: ${store.createdbycontact.name}`} onDelete={() => store.removeCreatedbycontact()} /> : null}
        {store.contactLabels.map(l => <Chip key={l.id} label={`${l.name}`} onDelete={() => store.removeContactLabel(l.id)} />)}
        {store.resourceLabels.map(l => <Chip key={l.id} label={`${l.name}`} onDelete={() => store.removeResourceLabel(l.id)} />)}
    </Box>;
});

export const Paging = observer((
    {
        store
    }: {
        store: FilesExplorerStore
    }
) => {
    const customClasses = useStyles();

    const getMin = () => {
        return (store.page - 1) * store.pageSize + 1;
    }

    const getMax = () => {
        return store.page * store.pageSize;
    }

    return <Box className={customClasses.pagingBox}>
            <Typography className={customClasses.pagingText}>Rows per page: </Typography>
            <Select
                data-testid={"paging-page-size"}
                className={customClasses.pagingDropDown}
                value={store.pageSize}
                onChange={(event) => store.handlePageSizeChange(event.target.value)}
            >
                {store.pageSizes.map(size => <MenuItem key={`paging_${size}`} value={size}>{size}</MenuItem>)}
            </Select>
            <Typography
                data-testid={"paging-page-info"}>{getMin()}-{getMax()}</Typography>
            <IconButton
                data-testid={"paging-page-prev"}
                disabled={!store.canPageDown}
                size="small"
                className={customClasses.pagingButtons} onClick={store.pageDown}>
                <ChevronLeftIcon />
            </IconButton>
            <IconButton
                data-testid={"paging-page-next"}
                disabled={!store.canPageUp}
                size="small"
                className={customClasses.pagingButtons} onClick={store.pageUp}>
                <ChevronRightIcon />
            </IconButton>
        </Box>;
});

export const Files = observer((
    {
        source,
        store,
        showHeader = true,
        maxFiles
    }:
        {
            source: FileExplorerSourceEnum;
            store: FileUploadMultiStore;
            showHeader?: boolean;
            maxFiles?: number;
        }
) => {
    const customClasses = useStyles();
        
    return <Box className={customClasses.filesList}>
        {showHeader ? <Typography className={customClasses.subtitle}>{maxFiles === 1 ? "Attaching File" : "Attaching Files"}</Typography> : null}
        {store.files.length > 0 ? <Table>
            {showHeader ? <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>File</TableCell>
                    {source === FileExplorerSourceEnum.Timesheets ? <TableCell style={{ textAlign: "center", whiteSpace: "nowrap" }}>Visible to Client</TableCell> : null}
                    <TableCell style={{ width: 100 }}></TableCell>
                    <TableCell style={{ width: 20 }}></TableCell>
                    <TableCell style={{ width: 20 }}></TableCell>
                </TableRow>
            </TableHead> : null}
            <TableBody>
            {store.files.map((file: any, index) => {
                const resource = file.data ? Resource.createFromResponse(file.data) : null;
                
                return <TableRow key={`attached-file-${index}`}>
                    <TableCell className={customClasses.tableCell}>
                        <Typography component="div" style={{fontSize: iconSize, lineHeight: 0}}>
                            {createElement(iconMap[file.iconClass] || defaultIcon, {fontSize: "inherit"})}
                        </Typography>
                    </TableCell>
                    <TableCell className={customClasses.tableCell} style={{width: "80%"}}>
                        <Typography
                            component="div"
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                width: 300,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}
                            title={file.name}
                        >
                            {file.name}
                        </Typography>
                    </TableCell>
                    {source === FileExplorerSourceEnum.Timesheets ?
                        <TableCell className={customClasses.tableCell} style={{ textAlign: "center" }}>
                            {file.id == 0 ? <Checkbox
                                checked={file.visibleToClient}
                                onClick={(event: any) => store.onVisibleToClientChange(index, event.target.checked)}
                                style={{ padding: 0 }}
                            /> : null}
                        </TableCell> : null}
                    <TableCell className={customClasses.tableCell}>
                        <Typography component="div" variant="body2" color="textSecondary"
                                    style={{width: 75, textAlign: "right"}}>
                            {file.humanSize}
                        </Typography>
                    </TableCell>
                    <TableCell className={customClasses.tableCell}>
                        <Typography
                            component="div"
                            color="textSecondary"
                            style={{
                                fontSize: iconSize,
                                lineHeight: 0,
                                marginLeft: 20,
                                color: file.previewShow || resource?.hasPreview ? "#3F4B50" : "rgba(0, 0, 0, 0.38)"
                            }}
                        >
                            {file.previewShow || resource?.hasPreview ? (
                                <RemoveRedEyeIcon onClick={file.previewShow ? file.preview : () => preview(resource)} fontSize="inherit" color="inherit"
                                                  style={{cursor: "pointer"}}/>
                            ) : (
                                <VisibilityOffIcon fontSize="inherit" color="inherit"/>
                            )}
                        </Typography>
                    </TableCell>
                    <TableCell className={customClasses.tableCell}>
                        <Typography component="div" color="textSecondary"
                                    style={{fontSize: iconSize, lineHeight: 0, marginLeft: 20}}>
                            <DeleteOutlineIcon
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => store.onDeleteFromIndex(index)}
                            />
                        </Typography>
                    </TableCell>
                </TableRow>;
            })}
            </TableBody>
        </Table> : null}
    </Box>;
});