import { LogLevel, Logger } from "@cr/core/logger";
import user from "./user";

// Parameters<typeof newrelic.noticeError> + { method }
type RemoteLoggerError = (error: string | Error, customAttributes?: { [key: string]: newrelic.SimpleType; method?: string }) => void;

export class NewRelicLogger extends Logger {

    protected constructor(context: string = null) {
      super(context);
    }
  
    private getLoggingContext = () => ({
      userId: user?.id,
      organizationId: user?.organizationId,
      url: window.location.href,
      date: new Date().toISOString()
    })
  
    static create(context: string): NewRelicLogger & ((level: LogLevel, ...args) => void) {
      const logger = new NewRelicLogger(context);
      const log = logger.log;
      Object.assign(log, logger);
      return log as any;
    }
  
    remoteError: RemoteLoggerError = (error, customAttributes = {}) => {
      customAttributes = { ...this.getLoggingContext(), ...customAttributes };
  
      this.log("error", error, customAttributes);
  
      if (window.newrelic?.noticeError) {
        if (this.context) {
          customAttributes.context = this.context;
          if (customAttributes.method) {
            customAttributes.method = `${this.context}.${customAttributes.method}`;
          }
        }
        try {
          newrelic.noticeError(error, customAttributes);
        } catch (err) {
          console.error("error in newrelic.noticeError", err);
        }
      }
    }
  }