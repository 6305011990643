import transport from "./transport";

export class PlaceOfServiceUtil {
  oPlaceOfService: string | number = null;
  originalPlaceOfService(value: string | number = null) {
    if (this.oPlaceOfService) {
      return this.oPlaceOfService;
    }
    this.oPlaceOfService = value;
    return this.oPlaceOfService;
  }
  static async getList() {
    const resp = await transport.api.get(`placesofservice`);
    return (resp && resp.list) ? resp.list : [];
  }
  filterById(list: PlaceOfServiceLocation[] = [], id: string | number)  {
    return PlaceOfServiceUtil.filterId(list, this.originalPlaceOfService(id));
  }
  filterByCode(list: PlaceOfServiceLocation[] = [], code: string)  {
    return list.filter(entry => entry.enabled || entry.code === this.originalPlaceOfService(code));
  }
  static filterId(list: PlaceOfServiceLocation[] = [], id: string | number)  {
    return list.filter(entry => entry.enabled || entry.id === id);
  }
}

export interface PlaceOfServiceLocation {
  id: number;
  code: string;
  name: string;
  formatted: string;
  enabled: boolean;
}