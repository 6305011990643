export default class Http {
  static get(path, params, overrides) {
    return _sendRequest("get", `${path}${_buildParams(params)}`, null, overrides);
  }

  static post(path, params, body, overrides) {
    return _postPutDelete("post", path, params, body, overrides);
  }

  static put(path, params, body, overrides) {
    return _postPutDelete("put", path, params, body, overrides);
  }

  static del(path, params, body, overrides) {
    return _postPutDelete("delete", path, params, body, overrides);
  }
}

function _postPutDelete(type, path, params, body, overrides) {
  return _sendRequest(type, `${path}${_buildParams(params)}`, body, overrides);
}

function _sendRequest(type, url, body, overrides) {
  let options = {
    url,
    type,
    async: true
  };

  $.extend(options, overrides);

  if (body) options.data = JSON.stringify(body);
  return Promise.resolve($.ajax(options));
}

function _buildParams(params) {
  if (!params) return "";
  let keys = Object.keys(params),
    rv = [];

  keys.forEach(k => rv.push(`${k}=${params[k]}`));
  return `?${rv.join("&")}`;
}
