import {
  GetMetadataResponse,
  LoadClientNoteResponse,
  LoadNoteContent,
  LoadNoteResponse,
  LoadTemplateResult,
  MetadataDefinitionAndValue
} from "app/util/api.dto";
import ApiTransport from "app/util/apiTransport";
import { PlaceOfServiceUtil, PlaceOfServiceLocation } from "app/util/placeOfServiceUtil";
import Http from "app/util/http";
import {
  AuthCode,
  ContactInsuranceResponse,
  ExtendedActivities,
  LoadAppointmentParams,
  LoadCodeConfigResponse,
  LoadTimeSheetsLocationResponse,
  LoadUserCodeRate,
  ProcedureCodeInfo,
  QuestionsNoteTemplates,
  ResourceResponse,
  TimesheetBulkConversionResponse,
  TimesheetGroup,
  TimesheetResponse,
  ValidationExceptionResponse
} from "./components/draftTimesheetTypes";
import { MetadataValues, SessionNoteSecondaryAndTertiaryInsurance } from "sessionNotes/models";
import domUtil from "app/util/domUtil";
import _ from "lodash";
import { safeJsonStringify } from "app/util/safeJsonStringify";
import { NewRelicLogger } from "app/util/newRelicLogger";

const api = new ApiTransport();
const metadataLogger = NewRelicLogger.create("SessionNoteMetadata");
const notesAndFormsLogger = NewRelicLogger.create("NotesAndForms");

class TimeSheetServices {
  loadProcedureCodes(params: {
    clientId: number;
    providerId: number;
    dateOfService: string;
    segmentId: string;
    includeRequiresConversion: boolean;
    _utcOffsetMinutes: number;
  }): Promise<{ procedureCodes: AuthCode[]; authorizations: AuthCode[] }> {
    return cr.transport.transmitRequest("billingmanager", "loadprocedurecodesandauthorizations", params);
  }

  async loadReasonCodes(state: string = null): Promise<any> {
    return api.get(`reason-codes/?state=${state}`);
  }

  async loadValidationException(id: number): Promise<ValidationExceptionResponse> {
    return api.get(`validation-exception/${id}`);
  }

  loadCodeConfig(params: { id: number; _utcOffsetMinutes: number }): Promise<LoadCodeConfigResponse> {
    return cr.transport.transmitRequest("billingmanager", "loadcodeconfig", params);
  }

  loadInsurance(clientId: number, insuranceId: number): Promise<ContactInsuranceResponse> {
    return api.get(`billing/contacts/${clientId}/insurances/${insuranceId}`);
  }

  async loadUserCodeRates(params: {
    clientId: number;
    providerId: number;
    procCodeId: number;
    serviceDate: string;
    payorId: number;
    _utcOffsetMinutes: number;
  }): Promise<LoadUserCodeRate> {
    const resp = await cr.transport.transmitRequest("billingmanager", "loadusercoderates", params);
    if (!resp.success) {
      return null;
    }
    return resp;
  }

  async loadTimeSheetsLocations(params: {
    principal1: number;
    principal2: number;
    deletedLocationId: number;
    startDateTime: string;
  }): Promise<LoadTimeSheetsLocationResponse[]> {
    const resp = await api.get("scheduling/appointment/locations", { ...params });
    if (resp.locations) {
      return resp.locations;
    }
    return [];
  }

  async getDefaultDiagnosis(params: { contactId: number; authId: string; _utcOffsetMinutes?: number }): Promise<any> {
    return cr.transport.transmitRequest("billingmanager", "getdefaultdiagnosis", params);
  }

  loadPlaceOfServiceList(): Promise<PlaceOfServiceLocation[]> {
    return PlaceOfServiceUtil.getList();
  }

  async loadTimesheetProviders(params: { search: string; searchTerm: string }): Promise<any> {
    let resp = await cr.transport.transmitRequest("billingmanager", "lookupTimeSheetClaimsProviders", {
      allowGeneric: true,
      search: params.search,
      searchTerm: params.searchTerm,
      _utcOffsetMinutes: new Date().getTimezoneOffset()
    });
    return resp.providers;
  }

  async loadTimesheetProviderSupplier(params: { search: string; searchTerm: string }): Promise<any> {
    let resp = await cr.transport.transmitRequest("billingmanager", "lookupTimeSheetClaimsProviders", {
      search: params.search,
      searchTerm: params.searchTerm,
      _utcOffsetMinutes: new Date().getTimezoneOffset()
    });
    return resp.providers;
  }

  async searchDiagnosisCodes(searchTerm: string): Promise<any> {
    return this.processResponse(await api.get(`/billing/diagnosis-codes?search=${searchTerm}`));
  }

  deleteTimesheet = (timesheetId: number) => {
    return api.delete(`timesheets/${timesheetId}`);
  };

  private processResponse(resp) {
    if (!resp.failed && resp.results) {
      return resp.results;
    }
    return undefined;
  }

  private cleanupTimesheetRequest(timesheet: TimesheetGroup): TimesheetGroup {
    let result = timesheet;
    result.segments.forEach(s => {
      delete s.serviceDateScheduledUtcOffset;
      delete s.serviceDateUtcOffset;
    });
    return result;
  }

  private processTimesheetResponse(timesheetResponse: TimesheetGroup) {
    if (timesheetResponse && timesheetResponse.segments) {
      timesheetResponse.segments.forEach((segment, index) => {
        if (!segment.exceptions) {
          segment.exceptions = [];
        }
        // as per comments on QAT-1048, adding global exceptions to first segment only
        if (index === 0 && timesheetResponse.exceptions && timesheetResponse.exceptions.length) {
          timesheetResponse.exceptions.forEach(exception => {
            segment.exceptions.push(exception);
          });
        }
        segment.exceptions.forEach(exception => {
          let modExceptionText = exception.exceptionText.replace(/\[X\]/g, `[${index + 1}]`);
          exception.exceptionText = modExceptionText;
        });
      });
    }
    return timesheetResponse;
  }

  async loadDraftTimesheet(id: number): Promise<any> {
    return this.processTimesheetResponse(this.processResponse(await api.get(`timesheets/${id}`)));
  }

  async loadConvertedTimesheet(id: number, bySegment: boolean): Promise<any> {
    let path = bySegment ? "/converted-timesheets/by-segment" : "/converted-timesheets";
    return this.processTimesheetResponse(this.processResponse(await api.get(`${path}/${id}`)));
  }

  async updateConvertedTimesheet(timesheetId: number, timesheet: TimesheetGroup): Promise<TimesheetGroup> {
    const resp = await api.put(`converted-timesheets/${timesheetId}`, null, { timesheet: this.cleanupTimesheetRequest(timesheet) });
    if (!resp.updateSuccess) {
      return this.processTimesheetResponse(resp.results);
    }
    return null;
  }

  async loadClientNotes(params: { templateId: number; clientId: number; page?: number; pageSize?: number }): Promise<LoadClientNoteResponse> {
    return cr.transport.transmitRequest("notes", "loadClientNotes", params);
  }

  async loadNoteTemplate(id: number): Promise<LoadTemplateResult> {
    const resp = await api.get(`notes/templates/${id}`);
    if (!resp.failed) {
      return resp.template;
    }

    notesAndFormsLogger.remoteError(`Error loading note template ${id}`, {
      method: "loadNoteTemplate",
      templateId: id,
      response: safeJsonStringify(resp)
    });
    return undefined;
  }

  async loadNoteSelected(id: number): Promise<LoadNoteResponse> {
    return api.get(`notes/${id}`);
  }
  async loadNoteSections(id: number, ref: string) {
    return api.get(`notes/${id}/sections/${ref}`);
  }
  async getNoteFileUrl(url: string) {
    return Http.get(url);
  }
  async cloneNoteSelected(id: number) {
    return api.get(`notes/clone/${id}`);
  }

  async loadNoteTemplateFile(id: number, ref: string) {
    return api.get(`notes/templates/${id}/sections/${ref}`);
  }

  async loadContactInfo(id: number): Promise<any> {
    if (id == null) {
      return undefined;
    }
    const resp = this.processResponse(await api.get(`contacts/${id}`));
    if (resp) {
      return resp[0];
    }
  }

  async loadContactAddress(id: number): Promise<any> {
    if (id !== null) {
      const result = await api.get(`contacts/address?contactId=${id}&typeId=7`);

      if (result && result.address) {
        return result.address;
      }
    }
  }

  async loadDiagnosisCodeInfo(id: number): Promise<any> {
    return this.processResponse(await api.get(`billing/diagnosis-codes/${id}`));
  }

  loadTimesheetClientData(clientData: { clientId: number }) {
    const params = {
        clientId: clientData.clientId,
        payorId: 0,
        _utcOffsetMinutes: new Date().getTimezoneOffset()
      },
      failedResponse = { success: false };

    try {
      return cr.transport.transmitRequest("billingmanager", "loadTimesheetClientData", params) ?? failedResponse;
    } catch (error) {
      console.error(error);
      return failedResponse;
    }
  }

  loadClientList(params?: string) {
    return api.get(
      `search/lookups?query=${params}&lookupOptions={LookupType:Client,State:Active&lookupTransforms={Options:0,OriginalType:email,TargetTypes:[]}`
    );
  }

  loadPayorList(params: any): any {
    return cr.transport.transmitRequest("billingmanager", "loadtimesheetpayors", {
      clientId: params.clientId,
      payorId: params.payorId,
      conversionDate: params.conversionDate
    });
  }

  async updateDraft(timesheetId: number, timesheet: TimesheetGroup): Promise<TimesheetResponse> {
    const resp = await api.put(`timesheets/${timesheetId}`, {}, { timesheet: this.cleanupTimesheetRequest(timesheet) });
    resp.results = this.processTimesheetResponse(resp.results);
    return resp;
  }

  async loadAppointment(params: LoadAppointmentParams): Promise<TimesheetGroup> {
    return this.processResponse(await api.get(`events/${params.eventId}/to-convert`, params));
  }

  async convertAppointmentTimesheet(SchedulingEventId: number, timesheet: TimesheetGroup, offsetMinutes: number): Promise<TimesheetGroup> {
    let resp = await api.post(
      `timesheets/convert-appointment/${SchedulingEventId}`,
      { offsetMinutes },
      { timesheet: this.cleanupTimesheetRequest(timesheet) }
    );
    if (!resp.conversionSuccess) {
      return this.processTimesheetResponse(resp.results);
    }
    return null;
  }

  async bulkConvertAppointmentTimesheet(
    SchedulingEventId: number,
    SchedulingSegmentId: number,
    offsetMinutes: number,
    overrideWarnings: boolean
  ): Promise<TimesheetBulkConversionResponse> {
    let resp = await api.post(`timesheets/convert-appointment/${SchedulingEventId}/segment/${SchedulingSegmentId}/bulk`, {
      offsetMinutes,
      overrideWarnings
    });
    return {
      exceptions: resp.results,
      conversionSuccess: resp.conversionSuccess,
      timesheetId: resp.timesheetId,
      timesheetSegmentId: resp.timesheetSegmentId
    };
  }

  async loadQuestionsNotesTemplates(serviceCodeId: number, clientId: number, providerId: number): Promise<QuestionsNoteTemplates[]> {
    const resp = await cr.transport.transmitRequest("billingmanager", "loadnotes", {
      serviceCodeId,
      id: "",
      clientId,
      providerId,
      _utcOffsetMinutes: new Date().getTimezoneOffset()
    });
    if (resp.success && resp.questions) {
      return resp.questions;
    }
  }

  async loadAvailableNotes(clientId: number, providerId: number, date: string, billingEntryId: number | string): Promise<ExtendedActivities[]> {
    const resp = await cr.transport.transmitRequest("billingmanager", "loadavailablenotes", {
      clientId,
      providerId,
      date,
      billingEntryId,
      _utcOffsetMinutes: new Date().getTimezoneOffset()
    });
    if (resp.success && resp.available) {
      return resp.available;
    }
  }

  async getProcedureCodeInfo(id: number): Promise<ProcedureCodeInfo> {
    return this.processResponse(await api.get(`billing/service-codes/${id}`));
  }

  async getResource(resourceId: number): Promise<ResourceResponse> {
    const resp = await cr.transport.transmitRequest("resources", "getresource", { resourceId, _utcOffsetMinutes: new Date().getTimezoneOffset() });
    if (resp.success && resp.resource) {
      return resp.resource;
    }
  }

  async convert(timesheetId: number, timesheet: TimesheetGroup, offsetMinutes: number): Promise<TimesheetGroup> {
    let resp = await api.post(`timesheets/convert-draft/${timesheetId}`, { offsetMinutes }, { timesheet: this.cleanupTimesheetRequest(timesheet) });
    if (!resp.conversionSuccess) {
      return this.processTimesheetResponse(resp.results);
    }
    return null;
  }

  async createNewTimeSheet(timesheet: TimesheetGroup, offsetMinutes: number): Promise<TimesheetGroup> {
    let resp = await api.post(`converted-timesheets`, { offsetMinutes }, { timesheet: this.cleanupTimesheetRequest(timesheet) });
    if (!resp.creationSuccess) {
      return this.processTimesheetResponse(resp.results);
    }
    return null;
  }

  async searchResources(clientId: number, search: string) {
    const resp = await cr.transport.transmitRequest("billingmanager", "lookuptimesheetresourcestoattach", {
      clientId,
      search,
      _utcOffsetMinutes: new Date().getTimezoneOffset()
    });
    if (resp.resources) {
      return resp.resources;
    }
    return [];
  }

  async loadNoteContent(clientId: number, providerId: number, payorId: number): Promise<LoadNoteContent> {
    const resp = await cr.transport.transmitRequest("notes", "loadContent", { clientId, providerId, payorId });
    if (resp.success) {
      return resp.content;
    }

    notesAndFormsLogger.remoteError("Error loading note content", {
      method: "loadNoteContent",
      clientId,
      providerId,
      payorId,
      response: safeJsonStringify(resp)
    });
    return undefined;
  }

  convertToCamelCase(obj: any) {
    const newObj: any = {};
    for (let k of Object.keys(obj)) {
      newObj[k[0].toLowerCase() + k.slice(1)] = obj[k];
    }
    return newObj;
  }

  async loadMetadata(contactId?: number): Promise<MetadataValues> {
    if (contactId == null) {
      // This can happen if we unset the provider when creating a new timesheet
      return {};
    }

    const metadataResp = await api.get<GetMetadataResponse>(`contacts/${contactId}/metadata`);
    if (metadataResp.failed) {
      console.error(`Metadata retrieval error (Status: ${metadataResp.responseStatus}): ${metadataResp.errorMessage}`);
      domUtil.displayMessage("Failed to retrieve metadata.", { isError: true });
      return {};
    }

    // Convert to Javascript Object for dynamic value resolution
    return metadataResp.items.reduce<Record<string, string>>((acc, curr) => {
      const value = this.getFormattedMetadataValue(curr.metadataDefinitionAndValue);
      if (value != null) {
        acc[curr.id + ""] = value;
      }

      return acc;
    }, {});
  }

  private getFormattedMetadataValue(inputValue: MetadataDefinitionAndValue): string | undefined {
    const currentType = inputValue.type;
    const numbersFormat = new Intl.NumberFormat("en-US");

    const customLoggingAttributes = { method: "getFormattedMetadataValue", inputValue: safeJsonStringify(inputValue) };

    switch (currentType) {
      case "Input":
        return inputValue.inputValue;
      case "TextArea":
        return inputValue.textAreaValue;
      case "YesNo":
        // null/undefined != false
        if (inputValue.yesNoValue === true) {
          return "yes";
        }
        if (inputValue.yesNoValue === false) {
          return "no";
        }
        break;
      case "Integer":
        return inputValue.integerValue && numbersFormat.format(inputValue.integerValue);
      case "Decimal":
        return inputValue.decimalValue && numbersFormat.format(inputValue.decimalValue);
      case "Date":
        if (inputValue.dateValue == null) {
          break;
        }
        // The date comes in ISO 8601 format => YYYY-MM-DDTHH:mm:ss.sssZ
        // conforming to the rest of the dates on PDFs / Mockups
        const toDate = new Date(inputValue.dateValue);
        return toDate.toLocaleDateString("en-US");
      case "Single":
        return inputValue.singleSelect;
      case "Multi":
        return inputValue.multiSelect?.join(", ");
      case "None":
        // This value is possible but cannot be set by users directly
        metadataLogger.remoteError("Unspecified metadata type None", customLoggingAttributes);
        break;
      default:
        metadataLogger.remoteError(`Unsupported metadata type ${currentType}`, customLoggingAttributes);
    }

    return undefined;
  }

  async loadSecondaryAndTertiaryInsurancesIds(clientId, insuranceId): Promise<SessionNoteSecondaryAndTertiaryInsurance> {
    const resp = await timeSheetServices.loadPayorList({
      clientId: clientId,
      payorId: insuranceId
    });

    const payors = resp.payors
      // Primary/Tertiary
      .filter(p => p.CompanyTypeId === 2 || p.CompanyTypeId === 3)
      // Sort for dedup
      .sort((a, b) => a.CompanyTypeId - b.CompanyTypeId);

    const [secondaryInsuranceId, tertiaryInsuranceId] = _.uniqBy(payors, "CompanyTypeId").map(p => p["Id"]);

    return {
      secondaryInsuranceId,
      tertiaryInsuranceId
    };
  }
}

export const timeSheetServices = new TimeSheetServices();
