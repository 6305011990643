import { Row } from "@cr/react-basics";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { DateRange } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeMode";

interface ChangeRangeProps {
    handlePopoverClose: (event) => void;
    setIsCustomRange: (isCustomRange: boolean) => void;
    isCustomRange: boolean;
    onDatesChange: ({ startDate, endDate }) => void;
    dateRange: DateRange;
    onReset: () => void;
    formattedDates: { startDate: string; endDate: string };
    onTextInputDateChange: (date: string, dateInput: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    maskDateInput: (event) => void;
    displayClearBtn?: boolean;
}

const useStyles = makeStyles(() => ({
    buttonGroup: {
        marginLeft: "auto"
    },
    rangeButtonsContainer: {
        padding: "8px 24px",
        alignItems: "center",
        borderBottom: "solid 0.5px #cbcbcb"
    }
}));

/**Custom date range buttons  save the initial date range state when it was clicked "Custom Range"
 * cancel() the dateRange is updated with the initial date range
 * applyClose() update the dateRange
 */
export const CustomRangeButtons = (props: ChangeRangeProps) => {
    const {
        setIsCustomRange,
        isCustomRange,
        handlePopoverClose,
        onDatesChange,
        dateRange,
        onReset,
        formattedDates,
        onTextInputDateChange,
        maskDateInput,
        displayClearBtn
    } = props;
    const classes = useStyles(props);

    //Apply changes and close popup
    const applyClose = () => {
        const startDate = moment(formattedDates.startDate, "MM-DD-YYYY");
        const endDate = moment(formattedDates.endDate, "MM-DD-YYYY");
        onDatesChange({
            startDate: startDate?.isValid() ? startDate : null,
            endDate: endDate?.isValid() ? endDate : null
        });
        handlePopoverClose(false);
    };
    // Cancel changes, reload the date range to the last selected and close the pop up
    const cancel = () => {
        onReset();
        handlePopoverClose(false);
    };

    const clear = () => {
        onDatesChange({
            startDate: null,
            endDate: null
        });
        handlePopoverClose(false);
    };

    const isDateValid = (date: string) => {
        return moment(date, "MM-DD-YYYY").isValid() || !date;
    };

    return (
        <Row className={classes.rangeButtonsContainer} justifyContent="flex-start">
            <Box width={118}>
                <TextField
                    id="startDate"
                    data-testid="inputStartDate"
                    label="From"
                    variant="outlined"
                    size="small"
                    value={formattedDates.startDate || ""}
                    inputProps={{
                        maxLength: 10
                    }}
                    autoComplete="off"
                    onKeyPress={event => maskDateInput(event)}
                    onChange={event => onTextInputDateChange("start", event)}
                    error={!isDateValid(formattedDates.startDate)}
                />
            </Box>
            <Box ml={2} mr={2}>
                -
            </Box>
            <Box width={118}>
                <TextField
                    id="endDate"
                    data-testid="inputEndDate"
                    label="To"
                    variant="outlined"
                    size="small"
                    inputProps={{
                        maxLength: 10
                    }}
                    autoComplete="off"
                    value={formattedDates.endDate || ""}
                    onKeyPress={event => maskDateInput(event)}
                    onChange={event => onTextInputDateChange("end", event)}
                    error={!isDateValid(formattedDates.endDate)}
                />
            </Box>
            {isCustomRange && (
                <Box className={classes.buttonGroup}>
                    <Button data-testid="cancel" variant="text" color="default" size="small" onClick={cancel}>
                        Cancel
                    </Button>

                    {displayClearBtn ? <Button data-testid="clear" variant="text" color="primary" size="small" onClick={clear}>
                        Clear
                    </Button> : null}
                    
                    <Button data-testid="applyClose" variant="contained" color="primary" size="small" onClick={applyClose}>
                        Apply
                    </Button>
                </Box>
            )}
        </Row>
    );
};