import React from "react";
import classes from "classnames";

/** Use this if you need to show an inline loading message rather than the whole-screen overlay one.
 * Note: If you set the height of its container, it should stretch vertically and horizontally to fill it. */
export const InlineLoading = ({ className = "", style = {}, message = "Loading", ...rest }) => (
  <div className={classes("flex flex-direction-row", className)} style={Object.assign({}, { minHeight: "70px" }, style)}>
    <div className="flex flex-1 align-items-center justify-content-center">
      <div className="text-center">
        <div className="bubbles">
          <span />
          <span id="bubble2" />
          <span id="bubble3" />
        </div>
        <div className="txt-muted-more margin-md-top txt-lg">
          <b>{message}</b>
        </div>
      </div>
    </div>
  </div>
);

export const InlineLoadingBubbles = ({ className = "", style = {}, ...rest }) => (
  <div className={classes("flex flex-direction-row", className)} style={Object.assign({}, { minHeight: "70px" }, style)}>
    <div className="flex flex-1 align-items-center justify-content-center">
      <div className="text-center">
        <div className="bubbles">
          <span />
          <span id="bubble2" />
          <span id="bubble3" />
        </div>
      </div>
    </div>
  </div>
);

export default ({ ...rest }) => (
  <div className="overlay-loading new">
    <InlineLoading {...rest} />
  </div>
);

/** Use this for small, localized loading/waiting indicators.  */
export const SmallLoading = ({ message = "", ...rest }) => (
  <span {...rest}>
    <i className="fa fa-fw fa-spin fa-spinner" />
    {message ? ` ${message}...` : null}
  </span>
);
