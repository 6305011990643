import { ChevronLeftIcon } from "@cr/icons/ChevronLeft";
import { ChevronRightIcon } from "@cr/icons/ChevronRight";
import { Column, Row } from "@cr/react-basics";
import { Popover } from "@material-ui/core";
import React from "react";
import "react-dates/initialize";
import DayPickerRangeController from "react-dates/lib/components/DayPickerRangeController";
import { CustomRangeButtons } from "./CustomRangeFooter";
import { CustomCalendarOptions } from "./CustomCalendarOptions";
import { DayPickerRangeControllerProps, PopoverProps as MuiPopoverProps } from "@cr/react-dates/dateRangePicker/props";
import { DateRange, DateRangeMode } from "@cr/react-dates/dateRangePicker/components/calendarOptions/DateRangeMode";
import { PopoverProps } from "@material-ui/core";

interface DateRangePickerCalendarProps extends DayPickerRangeControllerProps {
    anchorEl: HTMLAnchorElement;
    handlePopoverClose: (event) => void;
    focusedIn: string | null;
    setfocusedInput: (focusedInput: string | null) => void;
    isSmallScreen: boolean;
    isOutsideRangeCustom: (day) => boolean;
    displayAdvancedOptions?: boolean;
    displayPopupInputFields?: boolean;
    setIsCustomRange: (isCustomRange: boolean) => void;
    isCustomRange: boolean;
    onReset: () => void;
    tempRangeDates: DateRange;
    updateTempDateRange: (daterange: DateRange) => void;
    formattedDates: { startDate: string; endDate: string };
    onTextInputDateChange: (date: string, dateInput: React.ChangeEvent<HTMLInputElement>) => void;
    maskDateInput: (event) => void;
    customOptions: DateRangeMode[];
    PopoverProps?: CustomPopoverProps;
    displayClearBtn?: boolean;
}

export interface CustomPopoverProps  {
    className?: string;
    anchorOrigin?: PopoverProps["anchorOrigin"];
    transformOrigin?: PopoverProps["transformOrigin"];
}

export const CustomDateCalendar = (props: DateRangePickerCalendarProps) => {
    const {
        isOutsideRange,
        anchorEl,
        handlePopoverClose,
        PopoverProps,
        setfocusedInput,
        isSmallScreen,
        isOutsideRangeCustom,
        focusedIn,
        displayAdvancedOptions,
        displayPopupInputFields,
        onDatesChange,
        setIsCustomRange,
        isCustomRange,
        onReset,
        updateTempDateRange,
        tempRangeDates,
        formattedDates,
        onTextInputDateChange,
        maskDateInput,
        customOptions,
        displayClearBtn = false,
        ...rest
    } = props;

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            {...PopoverProps}
        >
            <Row>
                {displayAdvancedOptions ? (
                    <CustomCalendarOptions
                        onDatesChange={onDatesChange}
                        updateTempDateRange={updateTempDateRange}
                        setIsCustomRange={setIsCustomRange}
                        isCustomRange={isCustomRange}
                        dateRange={{
                            startDate: rest.startDate,
                            endDate: rest.endDate
                        }}
                        customOptions={customOptions}
                        handlePopoverClose={handlePopoverClose}
                    />
                ) : null}
                <Column>
                    {displayPopupInputFields === false ? null : (
                        <CustomRangeButtons
                            data-testid="customRangeButtons"
                            dateRange={{
                                startDate: tempRangeDates.startDate,
                                endDate: tempRangeDates.endDate
                            }}
                            handlePopoverClose={handlePopoverClose}
                            setIsCustomRange={setIsCustomRange}
                            onDatesChange={onDatesChange}
                            onReset={onReset}
                            formattedDates={{
                                startDate: formattedDates.startDate === 'Invalid date' ? '' : formattedDates.startDate,
                                endDate: formattedDates.endDate === 'Invalid date' ? '' : formattedDates.endDate
                            }}
                            onTextInputDateChange={onTextInputDateChange}
                            isCustomRange={isCustomRange}
                            maskDateInput={maskDateInput}
                            displayClearBtn={displayClearBtn}
                        />
                    )}

                    <DayPickerRangeController
                        numberOfMonths={2}
                        onDatesChange={!isCustomRange ? onDatesChange : updateTempDateRange}
                        {...rest}
                        startDate={!isCustomRange ? rest.startDate?.isValid() ? rest.startDate : null : tempRangeDates.startDate?.isValid() ? tempRangeDates.startDate : null}
                        endDate={!isCustomRange ? rest.endDate?.isValid() ? rest.endDate : null : tempRangeDates.endDate?.isValid() ? tempRangeDates.endDate : null}
                        focusedInput={focusedIn}
                        onFocusChange={focusedInput => {
                            setfocusedInput(focusedInput || "startDate");
                        }}
                        orientation={isSmallScreen ? "vertical" : "horizontal"}
                        isOutsideRange={isOutsideRange || isOutsideRangeCustom}
                        navPrev={
                            <ChevronLeftIcon
                                className="DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_button__horizontalDefault"
                                fontSize="small"
                            />
                        }
                        navNext={
                            <ChevronRightIcon
                                className="DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_button__horizontalDefault"
                                fontSize="small"
                            />
                        }
                        daySize={36}
                        initialVisibleMonth={() => rest.startDate || rest.endDate || moment()}
                    />
                </Column>
            </Row>
        </Popover>
    );
};